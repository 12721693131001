var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var mV,Mma,Nma,uV,Oma,Pma,Rma,qV,Sma,Qma;mV=function(a){return a+1};$CLJS.nV=function(a){if("string"===typeof a){var b=$CLJS.dh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.Fl(a));};
Mma=function(a){var b=new $CLJS.Da,c=$CLJS.mt;$CLJS.mt=new $CLJS.qc(b);try{var d=$CLJS.mt,e=$CLJS.Ta($CLJS.uu(d)),f=$CLJS.mt;$CLJS.mt=e?$CLJS.lu(d):d;try{d=$CLJS.wu;$CLJS.wu=!0;try{$CLJS.yu(a)}finally{$CLJS.wu=d}$CLJS.E.g(0,$CLJS.tt($CLJS.mt,$CLJS.vt))||$CLJS.Zb($CLJS.mt,"\n");$CLJS.st()}finally{$CLJS.mt=f}$CLJS.kh($CLJS.p.h(b))}finally{$CLJS.mt=c}};$CLJS.oV=function(a,b){return $CLJS.P.j($CLJS.ia,a,b)};
$CLJS.pV=function(a){var b=$CLJS.iu;$CLJS.iu=120;try{var c=new $CLJS.Da,d=$CLJS.Ma,e=$CLJS.Oa;$CLJS.Ma=!0;$CLJS.Oa=function(f){return c.append(f)};try{Mma(a)}finally{$CLJS.Oa=e,$CLJS.Ma=d}return $CLJS.p.h(c)}finally{$CLJS.iu=b}};
Nma=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.yU,$CLJS.Qd),c=$CLJS.J.j(a,$CLJS.vU,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Se($CLJS.N),e=$CLJS.Se($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null),C=$CLJS.q(d);return C.h?C.h(A):C.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.zh.v(e,$CLJS.fk,u,$CLJS.GC(mV,0)),u);if($CLJS.E.g(x,1))return $CLJS.zh.v(d,$CLJS.T,
new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null),t),t;t=function(){var A=t,C=$CLJS.p.h(x);return c.g?c.g(A,C):c.call(null,A,C)}()}}function k(m){var t=l,u=t.g;null==qV&&(qV=$CLJS.Se(0));var v=$CLJS.qh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.zh.g(qV,mV))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.rV=function(a){var b=$CLJS.vd(a);return b?(b=$CLJS.z(a)instanceof $CLJS.M)?(a=$CLJS.J.g(a,1),$CLJS.td(a)&&$CLJS.Dd(a,$CLJS.oD)):b:b};$CLJS.sV=function(a){return $CLJS.rV(a)?$CLJS.gD.h($CLJS.vD(a)):null};$CLJS.tV=function(a,b){return $CLJS.xD($CLJS.rV(a)?a:new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ej,new $CLJS.h(null,2,[$CLJS.oD,$CLJS.p.h($CLJS.yD()),$CLJS.ni,$CLJS.CD(a)],null),a],null),function(c){return $CLJS.Vj.l($CLJS.T.j(c,$CLJS.gD,b),$CLJS.U,$CLJS.H([$CLJS.cD]))})};
uV=function(a,b,c){var d=$CLJS.Dd(a,b)?$CLJS.fk.j(a,b,function(e){return $CLJS.vd(e)&&$CLJS.E.g($CLJS.z(e),$CLJS.gr)?$CLJS.yf($CLJS.Xe(1,e)):new $CLJS.Q(null,1,5,$CLJS.R,[e],null)}):a;return $CLJS.Dd(a,b)?$CLJS.tU(d,$CLJS.Be([b,c])):d};Oma=function(a){var b=$CLJS.Al(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aO,$CLJS.DP],null));b=vV.h?vV.h(b):vV.call(null,b);return $CLJS.jV($CLJS.T.l(uV($CLJS.Vj.l(a,$CLJS.aO,$CLJS.H([$CLJS.DP])),$CLJS.LQ,$CLJS.nK),$CLJS.fj,$CLJS.fK,$CLJS.H([$CLJS.kK,b])))};
Pma=function(a){return $CLJS.ek.g(Oma,a)};Rma=function(a){return $CLJS.n(a)?$CLJS.T.j($CLJS.fk.j($CLJS.hl(a)?new $CLJS.h(null,1,[$CLJS.wV,a],null):a,$CLJS.wV,function(b){return $CLJS.ek.g(function(c){return $CLJS.T.j($CLJS.uz(c,$CLJS.Vz),$CLJS.fj,$CLJS.BJ)},b)}),$CLJS.fj,Qma):null};
$CLJS.xV=function(a){if($CLJS.E.g($CLJS.fj.h(a),$CLJS.bV))return a;var b=$CLJS.Si.h(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "native":return $CLJS.uk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.fj,$CLJS.bV,$CLJS.kK,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.uk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.fj,$CLJS.WU],null),$CLJS.tU($CLJS.eP.h(a),new $CLJS.h(null,1,[$CLJS.vE,$CLJS.eP],null))]))],null)],null),$CLJS.Vj.l(a,$CLJS.Si,$CLJS.H([$CLJS.eP]))]));case "query":return $CLJS.uk.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.fj,$CLJS.bV,$CLJS.kK,vV($CLJS.vE.h(a))],null),$CLJS.Vj.l(a,$CLJS.Si,$CLJS.H([$CLJS.vE]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.yV=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.kK);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.Th($CLJS.YC("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[Sma,$CLJS.D(a)],null));return c};
$CLJS.zV=function(a,b){a=$CLJS.xV(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.kK);return $CLJS.J.g($CLJS.yf(c),$CLJS.yV(a,b))};$CLJS.AV=function(a){return"string"===typeof a&&(a=$CLJS.eh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.nV(a)):null};$CLJS.CV=function(){return $CLJS.ck.g(BV,Nma($CLJS.H([$CLJS.yU,$CLJS.Rz,$CLJS.vU,function(a,b){return BV.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};qV=null;
$CLJS.DV=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.EV=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.FV=new $CLJS.M(null,"join","join",-758861890);$CLJS.GV=new $CLJS.M(null,"stage","stage",1843544772);Sma=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.wV=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.HV=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.IV=new $CLJS.M(null,"table","table",-564943036);Qma=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.JV=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var Tma,Uma,Vma,Wma,Xma,vV,BV;Tma=$CLJS.Se($CLJS.N);Uma=$CLJS.Se($CLJS.N);Vma=$CLJS.Se($CLJS.N);Wma=$CLJS.Se($CLJS.N);Xma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.YA],null),$CLJS.Oi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));$CLJS.KV=new $CLJS.Ph($CLJS.qh.g("metabase.lib.util","custom-name-method"),$CLJS.OA,Xma,Tma,Uma,Vma,Wma);$CLJS.KV.m(null,$CLJS.Oh,function(a){return $CLJS.rV(a)?$CLJS.pz($CLJS.cD,$CLJS.gD)($CLJS.vD(a)):null});
vV=function vV(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.DP),d=$CLJS.J.g(a,$CLJS.NN);c=$CLJS.n(c)?vV.h?vV.h(c):vV.call(null,c):$CLJS.xf;d=Rma(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.QK(c,new $CLJS.Q(null,2,5,$CLJS.R,[e,$CLJS.iU],null),d):c;d=$CLJS.n($CLJS.eP.h(a))?$CLJS.WU:$CLJS.PU;a=$CLJS.uk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.fj,d],null),$CLJS.Vj.l(a,$CLJS.DP,$CLJS.H([$CLJS.NN]))]));a=$CLJS.y($CLJS.xM.h(a))?$CLJS.fk.j(a,$CLJS.xM,Pma):a;a=uV(a,$CLJS.DE,$CLJS.cV);return $CLJS.Zd.g(c,
a)};
$CLJS.LV=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.xV(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.kK);d=$CLJS.yV(c,d);e=$CLJS.P.M($CLJS.fk,$CLJS.yf(k),d,e,f);return $CLJS.T.j(c,$CLJS.kK,e)}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Ic(c);return b(d,e,f,c)};a.l=b;
return a}();
BV=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.hV.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.Yma=RegExp(" id$","i");