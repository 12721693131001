var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var QV,RV,TV,UV,Zma,WV,XV,PV,YV,$ma,ana,bna,cna,bW,cW,eW,fW,gW,hW,iW,jW,ena,fna,lW,gna,mW,hna,oW,ina,jna,kna,lna,mna,MV;$CLJS.NV=function(a,b){if("string"===typeof b)return MV(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.OV=function(a,b,c){var d=$CLJS.Bl(a,b);if($CLJS.n(d)){var e=$CLJS.T.j;d=$CLJS.Db(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.T,a,b,c)}return a};
QV=function(a){var b=PV;return $CLJS.yz(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};RV=function(a){if($CLJS.id(a))return a;throw $CLJS.Th("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.pi,a],null));};
$CLJS.SV=function(a,b,c){return $CLJS.td(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.R,Y=G;G=$CLJS.Zd.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.Q(null,2,5,S,[Y,G],null));A+=1}else return!0}()?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[v,function(){var A=$CLJS.Zd.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Ic(k)))}return null}},null,null)}(c)}()):$CLJS.sd(c)?$CLJS.ek.g($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Zd.g(b,$CLJS.z(c)):b),c):c};TV=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ee(a):b;return $CLJS.n(b)?[$CLJS.ee(a),"/",$CLJS.Xg(a)].join(""):$CLJS.Xg(a)};
UV=function(a,b){var c=$CLJS.sd(b)&&!$CLJS.uf(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.qd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
Zma=function(a,b){return $CLJS.df(function(c){var d=$CLJS.ff;a:try{if($CLJS.vd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.jk.j(c,0,1);if($CLJS.vd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Qe($CLJS.E,a)(f))){var k=$CLJS.jk.g(c,1);var l=new $CLJS.Q(null,1,5,$CLJS.R,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Ua,l)))},$CLJS.H([b]))};
WV=function(a,b){for(;;)switch(b=$CLJS.vk.h($CLJS.ff($CLJS.Ua,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),VV.h?VV.h(a):VV.call(null,a);default:if($CLJS.n($CLJS.Ne($CLJS.Qe(UV,a),b))){var c=a;b=Zma(a,b);a=c}else{c=$CLJS.cf.g(VV,b);if($CLJS.E.g(c,b))return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),b);b=c}}};XV=function(a){var b=$CLJS.sd(a);return b?(b=!$CLJS.uf(a))?$CLJS.pz($CLJS.ce,$CLJS.Xa)($CLJS.z(a)):b:b};
PV=function(a){return $CLJS.n($CLJS.pz($CLJS.ce,$CLJS.Xa)(a))?$CLJS.mh.h($CLJS.wz(TV(a).toLowerCase(),/_/,"-")):a};YV=function(a,b){var c=XV(b);return $CLJS.n(c)?(b=PV($CLJS.z(b)),$CLJS.qd(a)?(a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};$ma=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.Oh,$CLJS.Qd,$CLJS.Si,PV,$CLJS.KM,PV],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Be([a,b]);b=ZV.g?ZV.g(b,$V):ZV.call(null,b,$V);return d.call(c,b,a)})};
ana=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=PV(c);var d=$ma(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.Si.h(a),$CLJS.sE)&&$CLJS.Ta($CLJS.KM.h(a))?$CLJS.T.j(a,$CLJS.KM,$CLJS.sF):a};bna=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=TV(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.T.j(ana(b),$CLJS.U,c)],null)}),a)};
cna=function(a){a=ZV.h?ZV.h(a):ZV.call(null,a);return aW.h?aW.h(a):aW.call(null,a)};bW=function(a){return $CLJS.OV($CLJS.OV($CLJS.cb(function(b,c){return $CLJS.OV(b,c,$CLJS.mh)},a,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.UB,$CLJS.wga,$CLJS.zN,dna,$CLJS.Qy,$CLJS.ci],null)),$CLJS.XA,cna),$CLJS.MM,$CLJS.pU)};cW=function(a){return $CLJS.Bd(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,a,null],null):a};eW=function(a){return dW.h(cW(a))};
fW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return VV($CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.cf.g(dW,b)))};gW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[a,eW(b)],null),$CLJS.cf.g(dW,c))};hW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.Q(null,2,5,$CLJS.R,[b,eW(a)],null):new $CLJS.Q(null,1,5,$CLJS.R,[b],null)};
iW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,eW(a)],null)};jW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,dW.h(a)],null)};ena=function(a){return $CLJS.yf($CLJS.yl(aW,kW(a)))};fna=function(a){for(;;)if($CLJS.n(XV(a)))a=new $CLJS.Q(null,1,5,$CLJS.R,[a],null);else return $CLJS.Ce($CLJS.ek.g(cW,a))};
lW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.sE);return $CLJS.n(b)?$CLJS.fk.j(a,$CLJS.sE,dW):a};
gna=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.je(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.jc(e),k=$CLJS.D(f),l=$CLJS.me(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,lW(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.pe($CLJS.re(l),d($CLJS.kc(e))):$CLJS.pe($CLJS.re(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.be(new $CLJS.Q(null,
2,5,$CLJS.R,[l,lW(f)],null),d($CLJS.Ic(e)))}return null}},null,null)}(a)}())};mW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.BP);return $CLJS.n(b)?$CLJS.fk.j(a,$CLJS.BP,gna):a};hna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.eP);b=$CLJS.Ta(a)?nW.h?nW.h(b):nW.call(null,b):b;return $CLJS.n(a)?mW(b):b};oW=function(a){return $CLJS.qd(a)?$CLJS.y(a):null!=a};
ina=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.vE);var c=$CLJS.J.g(b,$CLJS.oM),d=$CLJS.J.g(b,$CLJS.NN),e=$CLJS.J.g(b,$CLJS.eP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.NN);var l=$CLJS.QK($CLJS.Vj.g(f,$CLJS.NN),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vE,$CLJS.NN],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.fk.j(l,$CLJS.vE,nW):l,t=$CLJS.n(c)?$CLJS.fk.j(m,$CLJS.oM,$CLJS.Qe($CLJS.ek,aW)):m,u=$CLJS.n(e)?$CLJS.fk.j(t,$CLJS.eP,mW):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.eP);return $CLJS.n(x)?$CLJS.T.j(aW($CLJS.Vj.g(v,
$CLJS.eP)),$CLJS.eP,x):aW(v)}catch(A){if(A instanceof Error)throw m=A,$CLJS.Sh($CLJS.YC("Error canonicalizing query: {0}",$CLJS.H([$CLJS.nU(m)])),new $CLJS.h(null,1,[$CLJS.vE,a],null),m);throw A;}};
jna=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.vE),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.OE);c=$CLJS.J.g(c,$CLJS.HD);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.Wf.j($CLJS.Sg,$CLJS.iB,$CLJS.y($CLJS.ff($CLJS.Ua,function k(e,f){try{if($CLJS.vd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.de(l,$CLJS.$E)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[f,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,m,$CLJS.Vj.g(t,$CLJS.fF)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.uU(k,e,f);throw l;}throw u;}}($CLJS.xf,b)))),$CLJS.pW.j(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vE,$CLJS.HD],null),$CLJS.ck.g($CLJS.yf,$CLJS.Qe($CLJS.dk,b)))):a};
kna=function(a){try{return jna(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Sh($CLJS.zD("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.vE,a],null),b);}throw c;}};
lna=function(a,b){var c=$CLJS.Wf.g($CLJS.gd(a),function(){return function f(e){return new $CLJS.je(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.Zd.g(b,G);C=qW.g?qW.g(C,K):qW.call(null,C,K);null!=C&&t.add(new $CLJS.Q(null,2,5,$CLJS.R,[G,C],null));A+=1}else return!0}()?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.Zd.g(b,v);return qW.g?qW.g(A,C):qW.call(null,A,C)}();if(null!=u)return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null),f($CLJS.Ic(k)));k=$CLJS.Ic(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};mna=function(a,b){a=$CLJS.ek.g(function(c){var d=$CLJS.Zd.g(b,rW);return qW.g?qW.g(c,d):qW.call(null,c,d)},a);return $CLJS.n($CLJS.Ne($CLJS.Ua,a))?a:null};
MV=function MV(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.yf(d);return $CLJS.be(f,new $CLJS.je(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),MV.g?MV.g(a,k):MV.call(null,a,k)):null},null,null))};
$CLJS.pW=function pW(a){switch(arguments.length){case 3:return pW.j(arguments[0],arguments[1],arguments[2]);case 4:return pW.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return pW.M(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return pW.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return pW.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.pW.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.T.j(a,b,$CLJS.pW.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.T.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.T,a,b,c)}return a};
$CLJS.pW.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.T.j(a,b,$CLJS.pW.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.T.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.T,a,b,c)}return a};$CLJS.pW.M=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.T.j(a,b,$CLJS.pW.M($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.T.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.T,a,b,c)}return a};
$CLJS.pW.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.T.j(a,b,$CLJS.pW.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.T.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.T,a,b,c)}return a};$CLJS.pW.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.T.j(a,b,$CLJS.P.l($CLJS.pW,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.T.j(a,b,$CLJS.P.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.pW.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.pW.A=6;
var VV=function VV(a){for(;;){if($CLJS.td(a))return $CLJS.as(a,VV);if($CLJS.zd(a))a=$CLJS.yf(a);else if($CLJS.vd(a)){if($CLJS.Ta($CLJS.Ne($CLJS.Ua,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.S:null;switch(v){case "not":if($CLJS.hl(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.S:null;switch(A){case "not":a=$CLJS.ed(m);continue;case "and":return WV($CLJS.ar,$CLJS.cf.g(function(){return function(C){return new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.cr,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Ic(m)));case "or":return WV($CLJS.gr,$CLJS.cf.g(function(){return function(C){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cr,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Ic(m)));default:return a}}else return a;case "and":return WV($CLJS.gr,t);case "or":return WV($CLJS.ar,t);default:return $CLJS.ek.g(VV,a)}}else return a}},nna=new $CLJS.M(null,"value_field","value_field",-980977878),sW=new $CLJS.M(null,"ascending","ascending",-988350486),
tW=new $CLJS.M(null,"named","named",-422393479),uW=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),vW=new $CLJS.M(null,"descending","descending",-24766135),wW=new $CLJS.M(null,"datetime-field","datetime-field",21731696),ona=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),xW=new $CLJS.M(null,"field-literal","field-literal",-1295883554),yW=new $CLJS.M(null,"viz-settings","viz-settings",256055379),pna=new $CLJS.M(null,"rows","rows",850049680),qna=
new $CLJS.M(null,"special-fn","special-fn",1290649344),rW=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),$V=new $CLJS.M(null,"ignore-path","ignore-path",944069061),rna=new $CLJS.M(null,"label_field","label_field",-1573182765),sna=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),tna=new $CLJS.M(null,"joined-field","joined-field",-2048778268),dna=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var zW,una=$CLJS.Se($CLJS.N),vna=$CLJS.Se($CLJS.N),wna=$CLJS.Se($CLJS.N),xna=$CLJS.Se($CLJS.N),yna=$CLJS.J.j($CLJS.N,$CLJS.Oi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));zW=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.ck.g(PV,$CLJS.z),yna,una,vna,wna,xna);zW.m(null,$CLJS.PE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PE,b],null);return null!=a?$CLJS.Zd.g(b,a):b});
zW.m(null,$CLJS.gA,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gA,a instanceof $CLJS.M?TV(a):a],null)});zW.m(null,$CLJS.wU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.Zd.g(zW.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wU,b,c],null)),a):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wU,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V),PV(c)],null)});
zW.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=ZV.g?ZV.g(a,$V):ZV.call(null,a,$V);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,b,function(){var d=$CLJS.n($CLJS.jA.h(c))?$CLJS.fk.j(c,$CLJS.jA,$CLJS.mh):c;d=$CLJS.n($CLJS.fF.h(c))?$CLJS.fk.j(d,$CLJS.fF,$CLJS.mh):d;return $CLJS.n($CLJS.pQ.h(c))?$CLJS.fk.j(d,$CLJS.pQ,function(e){return $CLJS.n($CLJS.sD.h(e))?$CLJS.fk.j(e,$CLJS.sD,$CLJS.mh):e}):d}()],null)});
zW.m(null,xW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[xW,b instanceof $CLJS.M?TV(b):b,$CLJS.mh.h(a)],null)});zW.m(null,wW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[wW,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V),$CLJS.Rr,PV(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[wW,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V),PV(c)],null)});
zW.m(null,$CLJS.VA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.Zd.g(zW.h(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.VA,b,c,d],null)),ZV.g?ZV.g(a,$V):ZV.call(null,a,$V)):new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.VA,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V),$CLJS.Bd(c)?c:PV(c),PV(d)],null)});
zW.m(null,$CLJS.RA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RA,b,PV(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RA,$CLJS.Iu],null)});zW.m(null,$CLJS.QA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QA,b,PV(a)],null)});
zW.m(null,$CLJS.TA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.TA,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V),c,PV(a)],null)});zW.m(null,$CLJS.WA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.WA,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V),c,PV(a)],null)});
zW.m(null,$CLJS.SA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SA,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V),PV(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SA,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V)],null)});
zW.m(null,$CLJS.UA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.UA,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V),PV(c),PV(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UA,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V),PV(c)],null)});
zW.m(null,$CLJS.PA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.PA,ZV.g?ZV.g(b,$V):ZV.call(null,b,$V),ZV.g?ZV.g(c,$V):ZV.call(null,c,$V),PV(a)],null)});zW.m(null,$CLJS.ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ej,b,a],null)});
zW.m(null,$CLJS.Oh,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[PV(a)],null),$CLJS.cf.h(function(c){return ZV.g?ZV.g(c,$V):ZV.call(null,c,$V)}),b)});
var AW=function AW(a){if($CLJS.n($CLJS.pz($CLJS.ce,$CLJS.Xa)(a))){var c=PV(a);var d=new $CLJS.Rg(null,new $CLJS.h(null,18,[$CLJS.en,null,$CLJS.Iq,null,$CLJS.oF,null,$CLJS.iF,null,$CLJS.Tr,null,$CLJS.aF,null,$CLJS.tF,null,$CLJS.uF,null,$CLJS.EE,null,$CLJS.LE,null,$CLJS.Hq,null,$CLJS.FE,null,$CLJS.mF,null,$CLJS.gF,null,$CLJS.Cj,null,$CLJS.Jw,null,$CLJS.UE,null,$CLJS.lF,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(XV(a))?(a=$CLJS.z(a),AW.h?AW.h(a):AW.call(null,
a)):null},zna=new $CLJS.h(null,8,[$CLJS.Si,PV,$CLJS.eP,function(a){a=QV(a);return $CLJS.y($CLJS.BP.h(a))?$CLJS.fk.j(a,$CLJS.BP,bna):a},$CLJS.vE,new $CLJS.h(null,6,[$CLJS.PE,function BW(a){if($CLJS.n($CLJS.pz($CLJS.ce,$CLJS.Xa)(a)))return PV(a);if($CLJS.n(YV(tW,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[tW,BW.h?BW.h(a):BW.call(null,a)],null),c)}return $CLJS.n($CLJS.n(XV(a))?AW($CLJS.ed(a)):null)?$CLJS.ek.g(BW,a):ZV.g?ZV.g(a,
$V):ZV.call(null,a,$V)},$CLJS.VO,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.je(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.jc(e),k=$CLJS.D(f),l=$CLJS.me(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[TV(u),ZV.g?ZV.g(t,$V):ZV.call(null,t,$V)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.pe($CLJS.re(l),d($CLJS.kc(e))):$CLJS.pe($CLJS.re(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[TV(l),ZV.g?ZV.g(f,$V):ZV.call(null,f,$V)],null),d($CLJS.Ic(e)))}return null}},null,null)}(a)}())},$CLJS.JQ,function(a){return $CLJS.yf(function(){return function d(c){return new $CLJS.je(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.jc(e),k=$CLJS.D(f),l=$CLJS.me(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m);t=$CLJS.n(XV(t))?zW.h(t):$CLJS.$d(zW.h($CLJS.$d(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.pe($CLJS.re(l),d($CLJS.kc(e))):$CLJS.pe($CLJS.re(l),null)}l=$CLJS.z(e);return $CLJS.be($CLJS.n(XV(l))?zW.h(l):$CLJS.$d(zW.h($CLJS.$d(l))),d($CLJS.Ic(e)))}return null}},null,null)}(a)}())},$CLJS.DP,function(a){a=QV(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.eP);if($CLJS.n(b))return a=$CLJS.tU(a,new $CLJS.h(null,1,[$CLJS.eP,$CLJS.vE],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.eP],null),a=ZV.g?ZV.g(a,b):ZV.call(null,a,b),$CLJS.tU(a,new $CLJS.h(null,1,[$CLJS.vE,$CLJS.eP],
null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.vE],null);return ZV.g?ZV.g(a,b):ZV.call(null,a,b)},$CLJS.NN,new $CLJS.h(null,1,[rW,bW],null),$CLJS.xM,new $CLJS.h(null,1,[rW,function(a){a=ZV.g?ZV.g(a,$CLJS.vE):ZV.call(null,a,$CLJS.vE);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.sD),d=$CLJS.J.g(b,$CLJS.HD);a=$CLJS.J.g(b,$CLJS.LD);b=$CLJS.n(c)?$CLJS.fk.j(b,$CLJS.sD,PV):b;d=$CLJS.n($CLJS.pz($CLJS.ce,$CLJS.Xa)(d))?$CLJS.fk.j(b,$CLJS.HD,PV):b;return $CLJS.n(a)?$CLJS.fk.j(d,$CLJS.LD,TV):d}],null)],null),$CLJS.yx,
new $CLJS.h(null,1,[$CLJS.iN,$CLJS.Qd],null),$CLJS.oM,new $CLJS.h(null,1,[rW,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Si),d=$CLJS.J.g(b,$CLJS.fI),e=$CLJS.J.g(b,$CLJS.Gi);a=$CLJS.J.g(b,uW);b=$CLJS.n(e)?$CLJS.fk.j(b,$CLJS.Gi,TV):b;c=$CLJS.n(c)?$CLJS.fk.j(b,$CLJS.Si,PV):b;d=$CLJS.n(d)?$CLJS.fk.j(c,$CLJS.fI,function(f){return ZV.g?ZV.g(f,$V):ZV.call(null,f,$V)}):c;d=$CLJS.n(a)?$CLJS.pW.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[uW,rna],null),function(f){return ZV.g?ZV.g(f,$V):ZV.call(null,f,$V)}):d;return $CLJS.n(a)?
$CLJS.pW.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[uW,nna],null),function(f){return ZV.g?ZV.g(f,$V):ZV.call(null,f,$V)}):d}],null),$CLJS.jM,function(a){return null==a?null:PV(a)},$CLJS.NN,new $CLJS.h(null,1,[rW,bW],null),yW,PV],null),ZV=function ZV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return ZV.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
ZV.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.Q(null,1,5,$CLJS.R,[b],null):$CLJS.yf(b);b=$CLJS.y(c)?$CLJS.rz(zna,c):null;try{return $CLJS.id(b)?b.h?b.h(a):b.call(null,a):$CLJS.ud(a)?a:$CLJS.td(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.je(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.jc(l),t=$CLJS.D(m),u=$CLJS.me(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=PV(A);A=new $CLJS.Q(null,
2,5,$CLJS.R,[A,ZV.l(x,$CLJS.H([$CLJS.Zd.g($CLJS.yf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.pe($CLJS.re(u),k($CLJS.kc(l))):$CLJS.pe($CLJS.re(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=PV(u);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[u,ZV.l(m,$CLJS.H([$CLJS.Zd.g($CLJS.yf(c),u)]))],null),k($CLJS.Ic(l)))}return null}},null,null)}(a)}()):$CLJS.n(XV(a))?zW.h(a):$CLJS.sd(a)?$CLJS.ek.g(function(e){return ZV.l(e,$CLJS.H([$CLJS.Zd.g($CLJS.yf(c),rW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Sh($CLJS.YC("Error normalizing form: {0}",$CLJS.H([$CLJS.nU(d)])),new $CLJS.h(null,3,[$CLJS.Ci,a,$CLJS.Mk,c,qna,b],null),d);}throw e;}};ZV.A=1;ZV.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var dW=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Oi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(XV(f))?$CLJS.z(f):null},e,a,b,c,d)}();dW.m(null,$CLJS.Oh,function(a){return a});
dW.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(YV($CLJS.$E,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return dW.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,c,$CLJS.Ce($CLJS.uk.l($CLJS.H([b,a])))],null))}return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,b,$CLJS.Ce(a)],null)});
dW.m(null,$CLJS.PE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.pd(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PE,b],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PE,b,a],null)});dW.m(null,$CLJS.MJ,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(XV(a))?dW.h(a):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,a,null],null)});
dW.m(null,xW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,b,new $CLJS.h(null,1,[$CLJS.jA,a],null)],null)});dW.m(null,sna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=eW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=eW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,a,$CLJS.T.j(c,$CLJS.yM,b)],null)});
dW.m(null,tna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=eW(a);return $CLJS.P.v($CLJS.BU,a,$CLJS.T,$CLJS.H([$CLJS.pO,b]))});
dW.m(null,wW,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=eW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.jA);$CLJS.Ta(d)||$CLJS.FR.g(d,b)?a=$CLJS.P.v($CLJS.BU,a,$CLJS.T,$CLJS.H([$CLJS.fF,b])):(c=$CLJS.Cz($CLJS.wx),$CLJS.n($CLJS.Bz("metabase.mbql.util",c))&&(b=$CLJS.YC("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.xh.l($CLJS.H([a]))])),b instanceof
Error?$CLJS.Az("metabase.mbql.util",c,$CLJS.pv(),b):$CLJS.Az("metabase.mbql.util",c,$CLJS.pv.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),dW.h(new $CLJS.Q(null,3,5,$CLJS.R,[wW,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
dW.m(null,$CLJS.wU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=eW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,b,$CLJS.T.j(e,$CLJS.pQ,$CLJS.uk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.sD,c],null),$CLJS.n(d)?$CLJS.Be([c,d]):null,a])))],null)});
for(var CW=$CLJS.y(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,$CLJS.ar,$CLJS.cr],null)),DW=null,EW=0,FW=0;;)if(FW<EW){var GW=DW.X(null,FW);dW.m(null,GW,function(){return function(a){return fW(a)}}(CW,DW,EW,FW,GW));FW+=1}else{var HW=$CLJS.y(CW);if(HW){var IW=HW;if($CLJS.wd(IW)){var JW=$CLJS.jc(IW),Ana=$CLJS.kc(IW),Bna=JW,Cna=$CLJS.D(JW);CW=Ana;DW=Bna;EW=Cna}else{var KW=$CLJS.z(IW);dW.m(null,KW,function(){return function(a){return fW(a)}}(CW,DW,EW,FW,KW,IW,HW));CW=$CLJS.B(IW);DW=null;EW=0}FW=0}else break}
dW.m(null,$CLJS.HE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HE,eW(a),eW(b)],null),c)});dW.m(null,$CLJS.VA,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=eW(a);a=$CLJS.n(UV($CLJS.$E,a))?$CLJS.BU.l(c,$CLJS.Vj,$CLJS.H([$CLJS.fF])):c;return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VA,a],null),b)});
for(var LW=$CLJS.y(new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.RE,$CLJS.kF,$CLJS.ZE,$CLJS.SE,$CLJS.Fq,$CLJS.qF,$CLJS.Cq,$CLJS.Eq,$CLJS.yq,$CLJS.Aq,$CLJS.CE,$CLJS.JE,$CLJS.AE,$CLJS.ME,$CLJS.BE],null)),MW=null,NW=0,OW=0;;)if(OW<NW){var PW=MW.X(null,OW);dW.m(null,PW,function(){return function(a){return gW(a)}}(LW,MW,NW,OW,PW));OW+=1}else{var QW=$CLJS.y(LW);if(QW){var RW=QW;if($CLJS.wd(RW)){var SW=$CLJS.jc(RW),Dna=$CLJS.kc(RW),Ena=SW,Fna=$CLJS.D(SW);LW=Dna;MW=Ena;NW=Fna}else{var TW=$CLJS.z(RW);dW.m(null,TW,
function(){return function(a){return gW(a)}}(LW,MW,NW,OW,TW,RW,QW));LW=$CLJS.B(RW);MW=null;NW=0}OW=0}else break}dW.m(null,pna,function(){return null});dW.m(null,$CLJS.oL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oL,dW.h(b),a],null)});
dW.m(null,tW,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=dW.h;var e=$CLJS.R;b=dW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,ona)?new $CLJS.h(null,1,[$CLJS.U,a],null):new $CLJS.h(null,1,[$CLJS.cD,a],null);return c.call(dW,new $CLJS.Q(null,3,5,e,[$CLJS.oL,b,a],null))});
for(var UW=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jw,$CLJS.aF],null)),VW=null,WW=0,XW=0;;)if(XW<WW){var YW=VW.X(null,XW);dW.m(null,YW,function(){return function(a){return hW(a)}}(UW,VW,WW,XW,YW));XW+=1}else{var ZW=$CLJS.y(UW);if(ZW){var $W=ZW;if($CLJS.wd($W)){var aX=$CLJS.jc($W),Gna=$CLJS.kc($W),Hna=aX,Ina=$CLJS.D(aX);UW=Gna;VW=Hna;WW=Ina}else{var bX=$CLJS.z($W);dW.m(null,bX,function(){return function(a){return hW(a)}}(UW,VW,WW,XW,bX,$W,ZW));UW=$CLJS.B($W);VW=null;WW=0}XW=0}else break}
for(var cX=$CLJS.y(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.UE,$CLJS.vF,$CLJS.EE,$CLJS.oF,$CLJS.lF,$CLJS.en,$CLJS.Cj,$CLJS.mF,$CLJS.FE],null)),dX=null,eX=0,fX=0;;)if(fX<eX){var gX=dX.X(null,fX);dW.m(null,gX,function(){return function(a){return iW(a)}}(cX,dX,eX,fX,gX));fX+=1}else{var hX=$CLJS.y(cX);if(hX){var iX=hX;if($CLJS.wd(iX)){var jX=$CLJS.jc(iX),Jna=$CLJS.kc(iX),Kna=jX,Lna=$CLJS.D(jX);cX=Jna;dX=Kna;eX=Lna}else{var kX=$CLJS.z(iX);dW.m(null,kX,function(){return function(a){return iW(a)}}(cX,dX,eX,fX,
kX,iX,hX));cX=$CLJS.B(iX);dX=null;eX=0}fX=0}else break}dW.m(null,$CLJS.LE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LE,eW(b),a],null)});
for(var lX=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gF,$CLJS.iF],null)),mX=null,nX=0,oX=0;;)if(oX<nX){var pX=mX.X(null,oX);dW.m(null,pX,function(){return function(a){return jW(a)}}(lX,mX,nX,oX,pX));oX+=1}else{var qX=$CLJS.y(lX);if(qX){var rX=qX;if($CLJS.wd(rX)){var sX=$CLJS.jc(rX),Mna=$CLJS.kc(rX),Nna=sX,Ona=$CLJS.D(sX);lX=Mna;mX=Nna;nX=Ona}else{var tX=$CLJS.z(rX);dW.m(null,tX,function(){return function(a){return jW(a)}}(lX,mX,nX,oX,tX,rX,qX));lX=$CLJS.B(rX);mX=null;nX=0}oX=0}else break}
dW.m(null,$CLJS.tF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tF,dW.h(b),dW.h(a)],null)});
dW.m(null,$CLJS.IE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.Zd.g(dW.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IE,b],null)),ZV.l(a,$CLJS.H([$V]))):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IE,$CLJS.yf(function(){return function e(d){return new $CLJS.je(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.wd(f)){var k=$CLJS.jc(f),l=$CLJS.D(k),m=$CLJS.me(l);a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.Q(null,
2,5,$CLJS.R,[dW.h(v),dW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.pe($CLJS.re(m),e($CLJS.kc(f))):$CLJS.pe($CLJS.re(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[dW.h(m),dW.h(k)],null),e($CLJS.Ic(f)))}return null}},null,null)}(b)}())],null)});
dW.m(null,$CLJS.rE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rE,dW.h(a),$CLJS.E.g(0,b)?1:dW.h(b)],null),$CLJS.cf.g(dW,c))});
var aW=function aW(a){if($CLJS.ud(a))return $CLJS.Pd(function(d,e,f){return $CLJS.T.j(d,e,aW.h?aW.h(f):aW.call(null,f))},a,a);if($CLJS.td(a))return $CLJS.as(a,aW);if($CLJS.n(XV(a))){var c=function(){try{return dW.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.Cz($CLJS.ux);if($CLJS.n($CLJS.Bz("metabase.mbql.normalize",e))){var f=$CLJS.zD("Invalid clause:");f instanceof Error?$CLJS.Az("metabase.mbql.normalize",e,$CLJS.pv.l($CLJS.H([a])),f):$CLJS.Az("metabase.mbql.normalize",e,$CLJS.pv.l($CLJS.H([f,
a])),null)}throw $CLJS.Sh($CLJS.YC("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.nU(d)])),new $CLJS.h(null,1,[$CLJS.UQ,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.Wf.j($CLJS.Zd.g($CLJS.gd(c),$CLJS.z(c)),$CLJS.cf.h(aW),$CLJS.Ic(c)):c}return $CLJS.zd(a)?$CLJS.ek.g(aW,a):$CLJS.qd(a)?$CLJS.Wf.j($CLJS.gd(a),$CLJS.cf.h(aW),a):a},kW=function kW(a){return function f(d,e){try{if($CLJS.n(function(){var x=RV($CLJS.zd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=RV($CLJS.ce);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.vd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.jk.j(e,0,2);if($CLJS.vd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.WC($CLJS.ce,$CLJS.Oe(new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.Iq,null,$CLJS.Tr,null,$CLJS.uF,null,$CLJS.Hq,null,
tW,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(AW(m)))return $CLJS.Wf.j($CLJS.xf,$CLJS.XC(kW),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.vd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.jk.j(e,0,1);if($CLJS.vd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;
}else throw d;else throw x;}}($CLJS.xf,a)},uX=function uX(a){return function f(d,e){try{var k=RV($CLJS.zd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.vd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.de(m,$CLJS.XE)){var t=$CLJS.F(e,0);return f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XE,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.de(m,
$CLJS.QE))return t=$CLJS.F(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QE,t],null));throw $CLJS.Z;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.de(m,sW))return t=$CLJS.F(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XE,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.de(m,vW))return t=$CLJS.F(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QE,t],null));throw $CLJS.Z;}catch(Y){if(Y instanceof Error)if(m=Y,m===
$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.de(v,sW))return t=$CLJS.F(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XE,t],null));throw $CLJS.Z;}catch(ba){if(ba instanceof Error)if(m=ba,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.de(v,vW))return t=$CLJS.F(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QE,t],null));throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(m=ha,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.de(v,$CLJS.XE))return t=$CLJS.F(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XE,eW(t)],null);throw $CLJS.Z;
}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.de(v,$CLJS.QE))return t=$CLJS.F(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QE,eW(t)],null);throw $CLJS.Z;}catch(Ra){if(Ra instanceof Error){t=Ra;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Ra;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw ba;}else throw m;else throw Y;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof
Error)if(l=G,l===$CLJS.Z)try{if($CLJS.vd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.jk.j(e,0,0);if($CLJS.vd(x)&&0===$CLJS.D(x))try{var A=$CLJS.jk.g(e,0);if($CLJS.E.g(A,a))return $CLJS.yf($CLJS.vk.h($CLJS.cf.g(uX,a)));throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)return $CLJS.SV(f,d,e);
throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.xf,a)},nW=$CLJS.ck.g(aW,function(a){var b=oW($CLJS.PE.h(a))?$CLJS.fk.j(a,$CLJS.PE,ena):a;b=oW($CLJS.OE.h(a))?$CLJS.fk.j(b,$CLJS.OE,fna):b;b=oW($CLJS.HD.h(a))?$CLJS.fk.j(b,$CLJS.HD,$CLJS.Qe($CLJS.ek,cW)):b;b=oW($CLJS.JQ.h(a))?$CLJS.fk.j(b,$CLJS.JQ,uX):b;return oW($CLJS.DP.h(a))?$CLJS.fk.j(b,$CLJS.DP,hna):b}),Pna=new $CLJS.h(null,3,[$CLJS.eP,$CLJS.Qd,$CLJS.vE,new $CLJS.h(null,2,[$CLJS.DP,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,
$CLJS.eP);if($CLJS.n(b))return a=$CLJS.tU(a,new $CLJS.h(null,1,[$CLJS.eP,$CLJS.vE],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.eP],null),a=qW.g?qW.g(a,b):qW.call(null,a,b),$CLJS.tU(a,new $CLJS.h(null,1,[$CLJS.vE,$CLJS.eP],null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.vE],null);return qW.g?qW.g(a,b):qW.call(null,a,b)},$CLJS.xM,new $CLJS.h(null,1,[rW,function(a){var b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.vE],null);return qW.g?qW.g(a,b):qW.call(null,a,b)}],null)],null),yW,$CLJS.Qd],null),qW=function qW(a){switch(arguments.length){case 1:return qW.h(arguments[0]);
case 2:return qW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};qW.h=function(a){return qW.g(a,$CLJS.xf)};qW.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.rz(Pna,b):null;return $CLJS.id(c)?c.h?c.h(a):c.call(null,a):$CLJS.ud(a)?a:$CLJS.td(a)?lna(a,b):$CLJS.sd(a)?mna(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Sh("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Ci,a,$CLJS.Mk,b],null),d);throw d;}};qW.A=2;
$CLJS.vX=function(){var a=$CLJS.ck.l(qW,kna,ina,$CLJS.H([ZV]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Sh($CLJS.YC("Error normalizing query: {0}",$CLJS.H([$CLJS.nU(c)])),new $CLJS.h(null,1,[$CLJS.vE,b],null),c);}throw d;}}}();$CLJS.wX=function wX(a,b){if($CLJS.Ta($CLJS.y(a)))a=(0,$CLJS.vX)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Zr(a);b=$CLJS.Be([$CLJS.fd(a),b]);f=wX.g?wX.g(f,b):wX.call(null,f,b);a=e.call(d,f,$CLJS.fd(a))}return a};