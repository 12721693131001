var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var R4,Csa,Dsa,Esa,T4,V4,W4,X4,Fsa,Gsa,$4,Y4,Z4,c5,d5,Hsa,Isa,e5,Jsa,Ksa,Lsa,Msa,Nsa,Osa,Psa,g5,Qsa,Rsa,Ssa,h5,Tsa,Usa,i5,Vsa,Wsa,Xsa,Ysa;R4=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=R4($CLJS.J.g(a,b),c),$CLJS.pd(c)?$CLJS.Vj.g(a,b):$CLJS.T.j(a,b,c)):$CLJS.Vj.g(a,b)}return a};
Csa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.z(u);u=$CLJS.B(u);var v=$CLJS.Bl(f,k);return $CLJS.n(v)?u?$CLJS.T.j(f,k,t($CLJS.Db(v),u,l,m)):$CLJS.T.j(f,k,$CLJS.P.j(l,$CLJS.Db(v),m)):f}(a,b,c,d)};Dsa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Csa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.S4=function(a){return $CLJS.oD.h($CLJS.vD(a))};
Esa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.VO,$CLJS.z(b))?$CLJS.tV(d,function(){var f=$CLJS.KV.h(d);return $CLJS.n(f)?f:$CLJS.sV(c)}()):d;return Dsa(a,b,function(f){return $CLJS.yf(function(){return function m(l){return new $CLJS.je(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.jc(t),v=$CLJS.D(u),x=$CLJS.me(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A);C=$CLJS.E.g($CLJS.S4(C),$CLJS.S4(c))?e:C;x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.pe($CLJS.re(x),m($CLJS.kc(t))):
$CLJS.pe($CLJS.re(x),null)}x=$CLJS.z(t);return $CLJS.be($CLJS.E.g($CLJS.S4(x),$CLJS.S4(c))?e:x,m($CLJS.Ic(t)))}return null}},null,null)}(f)}())})};
T4=function(a,b,c,d){var e=$CLJS.rz(a,b);if($CLJS.n(e)){var f=$CLJS.S4(c),k=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.z(b),$CLJS.fd(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.Wf.j($CLJS.xf,$CLJS.dk.h($CLJS.ck.g($CLJS.Tg([f]),$CLJS.S4)),e);e=$CLJS.E.g(b,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.HD],null))&&$CLJS.Me(function(l){return $CLJS.t1(l,$CLJS.gA)},e)?null:e;if($CLJS.y(e))return $CLJS.QK(a,b,e);if($CLJS.E.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xM,$CLJS.nK],null),new $CLJS.Q(null,2,5,$CLJS.R,
[c,k],null)))throw $CLJS.Th($CLJS.zD("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.ux,$CLJS.EV,$CLJS.nK,$CLJS.rz(a,b),$CLJS.FV,$CLJS.rz(a,$CLJS.nd(b)),$CLJS.yK,d,$CLJS.GV,a],null));return $CLJS.E.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xM,$CLJS.HD],null),new $CLJS.Q(null,2,5,$CLJS.R,[c,k],null))?$CLJS.pW.v(a,$CLJS.nd(b),$CLJS.Vj,k):R4(a,b)}return a};
$CLJS.U4=function(a,b){a=$CLJS.LV.l(a,b,$CLJS.Vj,$CLJS.H([$CLJS.HD]));return $CLJS.Wf.j($CLJS.xf,$CLJS.dk.h($CLJS.ck.g(new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.zY,null,$CLJS.yY,null],null),null),$CLJS.DJ)),$CLJS.H_.j(a,b,$CLJS.zV(a,b)))};
V4=function(a,b,c,d){var e=$CLJS.C1.g($CLJS.D(c),$CLJS.D(d));return $CLJS.n(e)?(c=$CLJS.iG(function(f){return $CLJS.G1.v(a,b,f,d)},c),(e=!$CLJS.Dd(c,null))?(e=$CLJS.C1.g($CLJS.D(c),$CLJS.D(d)),$CLJS.n(e)?$CLJS.Me(function(f){return $CLJS.C1.g($CLJS.D(f),1)},$CLJS.Pg(c)):e):e):e};
W4=function(a,b){var c=$CLJS.p3.g(a,b),d=$CLJS.bh(0,$CLJS.D(c));return $CLJS.bf.l(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.JQ],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.OE],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.cV],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.HD],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.PE],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.VO],null)],null),function(){return function k(f){return new $CLJS.je(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.wd(l)){var m=
$CLJS.jc(l),t=$CLJS.D(m),u=$CLJS.me(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.F(c,x);$CLJS.HD.h(A)instanceof $CLJS.M||u.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xM,x,$CLJS.HD],null));v+=1}else{m=!0;break a}return m?$CLJS.pe($CLJS.re(u),k($CLJS.kc(l))):$CLJS.pe($CLJS.re(u),null)}u=$CLJS.z(l);m=$CLJS.F(c,u);if($CLJS.HD.h(m)instanceof $CLJS.M)l=$CLJS.Ic(l);else return $CLJS.be(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xM,u,$CLJS.HD],null),k($CLJS.Ic(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.je(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.jc(l),t=$CLJS.D(m),u=$CLJS.me(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);u.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xM,x,$CLJS.nK],null));v+=1}else{m=!0;break a}return m?$CLJS.pe($CLJS.re(u),k($CLJS.kc(l))):$CLJS.pe($CLJS.re(u),null)}u=$CLJS.z(l);return $CLJS.be(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xM,u,$CLJS.nK],null),k($CLJS.Ic(l)))}return null}},null,null)}(d)}()]))};
X4=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.fF),k=$CLJS.J.g(e,$CLJS.pQ),l=$CLJS.I(c,2,null);return $CLJS.z($CLJS.jG(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.z(m),d)&&$CLJS.E.g($CLJS.fF.h($CLJS.ed(m)),f)&&$CLJS.E.g($CLJS.pQ.h($CLJS.ed(m)),k)&&$CLJS.E.g($CLJS.fd(m),l)},$CLJS.zl($CLJS.tr,$CLJS.JQ.h($CLJS.zV(a,b)))))};
Fsa=function(a,b,c,d){c=X4(a,b,c);return $CLJS.n(c)?$CLJS.LV.l(a,b,$CLJS.pW,$CLJS.H([new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.JQ,c,2,1],null),$CLJS.ck.g(function(e){return $CLJS.G0($CLJS.Wk,e)},$CLJS.uk),d])):a};Gsa=function(a,b,c){c=X4(a,b,c);return $CLJS.n(c)?$CLJS.LV.l(a,b,T4,$CLJS.H([new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.JQ],null),$CLJS.rz($CLJS.zV(a,b),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JQ,c],null)),b])):a};
$4=function(a,b,c,d,e,f){var k=$CLJS.LV.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.S4(e);return $CLJS.bk.g(a,k)?$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ua,function(m,t){try{if($CLJS.vd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.de(u,$CLJS.VO))return new $CLJS.Q(null,1,5,$CLJS.R,[function(){var K=$CLJS.sV(e);K=Y4.W?Y4.W(k,b,c,$CLJS.gA,$CLJS.N,K):Y4.call(null,k,b,c,$CLJS.gA,$CLJS.N,K);return Z4.v?Z4.v(K,b,c,l):Z4.call(null,K,b,c,l)}()],null);throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{u=
$CLJS.F(t,0);if($CLJS.de(u,$CLJS.PE))return new $CLJS.Q(null,1,5,$CLJS.R,[function(){var S=Y4.W?Y4.W(k,b,c,$CLJS.PE,$CLJS.N,l):Y4.call(null,k,b,c,$CLJS.PE,$CLJS.N,l);return Z4.v?Z4.v(S,b,c,l):Z4.call(null,S,b,c,l)}()],null);throw $CLJS.Z;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{if($CLJS.vd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.de(x,$CLJS.OE))return new $CLJS.Q(null,
1,5,$CLJS.R,[Z4.v?Z4.v(k,b,c,l):Z4.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{x=$CLJS.F(t,0);if($CLJS.de(x,$CLJS.HD))return new $CLJS.Q(null,1,5,$CLJS.R,[Z4.v?Z4.v(k,b,c,l):Z4.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){var A=Y;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw Y;}else throw v;else throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{if($CLJS.vd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.de(C,$CLJS.xM))try{var G=$CLJS.F(t,2);if($CLJS.de(G,$CLJS.HD))return new $CLJS.Q(null,1,5,$CLJS.R,[Z4.v?Z4.v(k,b,c,l):Z4.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){A=Y;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){A=Y;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){A=Y;if(A===$CLJS.Z)return new $CLJS.Q(null,1,5,$CLJS.R,[k],null);throw A;}throw Y;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.xf,d)))):k};
Y4=function(a,b,c,d,e,f){var k=$CLJS.zV(a,b),l=$CLJS.df(function(t){var u=$CLJS.rz(k,t);return $CLJS.n(u)?$CLJS.yl(function(v){return $CLJS.z($CLJS.y($CLJS.ff($CLJS.Ua,function G(A,C){try{if($CLJS.vd(C)&&3===$CLJS.D(C))try{var K=$CLJS.F(C,0);if($CLJS.E.g(K,d))try{var S=$CLJS.F(C,1);if($CLJS.pd(e)||$CLJS.F0($CLJS.Wg(e),$CLJS.Wg(S)))try{var Y=$CLJS.F(C,2);if($CLJS.E.g(Y,f))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[t,v],null)],null);throw $CLJS.Z;}catch(ha){if(ha instanceof Error){var ba=
ha;if(ba===$CLJS.Z)throw $CLJS.Z;throw ba;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){ba=ha;if(ba===$CLJS.Z)throw $CLJS.Z;throw ba;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){ba=ha;if(ba===$CLJS.Z)throw $CLJS.Z;throw ba;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){ba=ha;if(ba===$CLJS.Z)return $CLJS.uU(G,A,C);throw ba;}throw ha;}}($CLJS.xf,v))))},u):null},$CLJS.H([W4(a,b)])),m=$CLJS.Ve($CLJS.bc($CLJS.xf));a=$CLJS.cb(function(t,u){var v=
$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);return $4(t,b,c,v,u,function(x,A,C){try{return T4(x,A,C,b)}catch(K){if(K instanceof Error){A=K;var G=$CLJS.O($CLJS.Dl(A));C=$CLJS.J.g(G,$CLJS.ux);G=$CLJS.J.g(G,$CLJS.FV);if($CLJS.E.g(C,$CLJS.EV))return m.nd(null,$CLJS.ue(m.Lb(null),G)),x;throw A;}throw K;}})},a,l);return $CLJS.cb(function(t,u){return $CLJS.a5.j?$CLJS.a5.j(t,b,u):$CLJS.a5.call(null,t,b,u)},a,$CLJS.ec($CLJS.q(m)))};
Z4=function(a,b,c,d){b=$CLJS.S1(c,b);if($CLJS.n(b)){var e=$CLJS.zV(c,b);c=$CLJS.Ne(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.DJ),l=$CLJS.J.g(f,$CLJS.o0);return $CLJS.E.g($CLJS.oY,k)&&$CLJS.E.g(d,l)?$CLJS.CY.h(f):null},$CLJS.J_.j(c,b,e));return $CLJS.n(c)?Y4(a,b,a,$CLJS.$E,$CLJS.N,c):a}return a};
c5=function(a,b,c,d,e){var f=$CLJS.lV.h(c),k=$CLJS.zV(a,b);c=$CLJS.jG(function(t){var u=$CLJS.rz(k,t);if($CLJS.n(u)){var v=$CLJS.S4(f);return $CLJS.n($CLJS.Ne($CLJS.ck.j($CLJS.Tg([v]),$CLJS.oD,$CLJS.ed),u))?t:null}return null},W4(a,b));var l=(d=$CLJS.E.g($CLJS.xF,d))?$CLJS.lV.h(e):null;e=d?function(t,u,v){return Esa(t,u,v,l)}:function(t,u,v){return T4(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.OE],null),c);a=d&&m&&$CLJS.E.g($CLJS.z(f),$CLJS.z(l))&&$CLJS.E.g($CLJS.fd(f),$CLJS.fd(l))?
Fsa(a,b,f,$CLJS.Al($CLJS.ed(l),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pQ,$CLJS.fF],null))):m?Gsa(a,b,f):a;return $CLJS.n(c)?(a=$4(a,b,a,c,f,e),$CLJS.b5.h?$CLJS.b5.h(a):$CLJS.b5.call(null,a)):a};d5=function(a){return $CLJS.wD(a,$CLJS.T,$CLJS.H([$CLJS.oD,$CLJS.p.h($CLJS.yD())]))};
Hsa=function(a,b,c){return function(d){return function l(f,k){try{if($CLJS.vd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.de(m,$CLJS.gA))try{var t=$CLJS.F(k,2);if($CLJS.E.g(t,b))return d5(c);throw $CLJS.Z;}catch(v){if(v instanceof Error){var u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)return $CLJS.SV(l,f,k);throw u;
}throw v;}}($CLJS.xf,d)}(a)};Isa=function(a,b,c){var d=function(){var l=$CLJS.sV(c);return $CLJS.n(l)?l:$CLJS.U.h($CLJS.vD(c))}(),e=d5($CLJS.tV(c,d));a=$CLJS.fk.j(a,$CLJS.VO,function(l){return $CLJS.ek.g(function(m){return $CLJS.E.g(m,b)?e:m},l)});var f=$CLJS.sV(b),k=$CLJS.ni.h($CLJS.vD(c));return Hsa(a,f,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gA,new $CLJS.h(null,1,[$CLJS.ni,k],null),d],null))};
e5=function(a,b,c){return $CLJS.oU(function(d){return $CLJS.E.g(d,$CLJS.S4(b))?$CLJS.S4(c):d},$CLJS.n($CLJS.sV(b))?Isa(a,b,c):$CLJS.oU(function(d){return $CLJS.E.g(d,b)?c:d},a))};Jsa=function(a,b,c){a=$CLJS.H_.j(a,b,$CLJS.zV(a,b));b=$CLJS.H_.j(c,b,$CLJS.zV(c,b));return $CLJS.ff(function(d){return $CLJS.bk.g($CLJS.z(d),$CLJS.ed(d))},$CLJS.cf.j($CLJS.tr,a,b))};
Ksa=function(a,b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.CY.h(d),f=$CLJS.kV($CLJS.T.j(c,$CLJS.DJ,$CLJS.oY));return $CLJS.cf.g(function(k){return new $CLJS.Q(null,2,5,$CLJS.R,[k,d5(f)],null)},$CLJS.y($CLJS.ff($CLJS.Ua,function t(l,m){try{if($CLJS.vd(m)&&3===$CLJS.D(m))try{var u=$CLJS.F(m,0);if($CLJS.de(u,$CLJS.$E))try{var v=$CLJS.F(m,2);if($CLJS.E.g(v,e))return new $CLJS.Q(null,1,5,$CLJS.R,[m],null);throw $CLJS.Z;}catch(A){if(A instanceof Error){var x=A;if(x===$CLJS.Z)throw $CLJS.Z;
throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)throw $CLJS.Z;throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)return $CLJS.uU(t,l,m);throw x;}throw A;}}($CLJS.xf,$CLJS.zV(a,b)))))};Lsa=function(a,b,c){var d=$CLJS.ni.h($CLJS.vD(c));$CLJS.n(d)||(d=$CLJS.y0.h?$CLJS.y0.h(c):$CLJS.y0.call(null,c),d=$CLJS.Ta(d)||!$CLJS.rV(c));if($CLJS.n(d))return c;a=$CLJS.D_.j(a,b,c);return $CLJS.wD(c,$CLJS.T,$CLJS.H([$CLJS.ni,a]))};
Msa=function(a,b){a=$CLJS.sV(a);return $CLJS.n($CLJS.n(a)?$CLJS.Ta($CLJS.sV(b))&&$CLJS.Ta($CLJS.U.h($CLJS.vD(b))):a)?$CLJS.tV(b,a):b};
Nsa=function(a,b,c,d){d=Lsa(a,b,Msa(c,d));c=$CLJS.LV.l(a,b,e5,$CLJS.H([c,d]));for(var e=b;;){var f=$CLJS.S1(c,e);if($CLJS.n(f)){b=f;var k=$CLJS.df(function(l,m,t){return function(u){return Ksa(l,t,u)}}(c,e,b,f,a,d),$CLJS.H([Jsa(a,e,c)]));c=$CLJS.cb(function(l,m,t,u){return function(v,x){var A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);return $CLJS.LV.l(v,u,e5,$CLJS.H([A,x]))}}(c,e,k,b,f,a,d),c,k);e=b}else return c}};
Osa=function(a,b){b=$CLJS.Kk.h(b);if($CLJS.E.g($CLJS.z(b),$CLJS.kK)){var c=$CLJS.ed(b),d=$CLJS.B($CLJS.B(b)),e=function(){var f=$CLJS.B(d);return f?$CLJS.Ne(function(k){return $CLJS.Me($CLJS.gl,$CLJS.cf.j($CLJS.E,k,d))?k:null},W4(a,c)):f}();return $CLJS.n(e)?$CLJS.jk.j(b,0,$CLJS.D(e)+2+1):null}return null};
Psa=function(a,b,c,d){for(var e=Nsa(a,b,c,d);;){var f=$CLJS.yX($CLJS.EK,e),k=$CLJS.vk.h($CLJS.yl(function(l){return function(m){return Osa(l,m)}}(e,f),$CLJS.Br.h(f)));if($CLJS.y(k))e=$CLJS.cb(function(){return function(l,m){try{return $CLJS.f5.j(l,$CLJS.ed(m),$CLJS.rz(l,m))}catch(x){if(x instanceof Error){var t=x,u=$CLJS.O($CLJS.Dl(t)),v=$CLJS.J.g(u,$CLJS.ux);u=$CLJS.J.g(u,$CLJS.FV);if($CLJS.E.g(v,$CLJS.EV))return m=$CLJS.ed(m),$CLJS.a5.j?$CLJS.a5.j(l,m,u):$CLJS.a5.call(null,l,m,u);throw t;}throw x;
}}}(e,f,k),e,k);else return $CLJS.n(f)?c5(a,b,c,$CLJS.xF,d):e}};g5=function(a,b){return $CLJS.t1(a,$CLJS.$E)&&$CLJS.E.g($CLJS.V0(a),b)};Qsa=function(a,b,c){return function k(e,f){try{if(g5(f,b))return $CLJS.V2(f,c);throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Z)return $CLJS.SV(k,e,f);throw l;}throw m;}}($CLJS.xf,a)};
Rsa=function(a,b,c){var d=$CLJS.xM.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.rz(d,new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.LD],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.bk.g(e,c):b)){var f=$CLJS.CV();$CLJS.Q2(f,$CLJS.cf.g($CLJS.LD,d));c=f(c);return Qsa($CLJS.QK(a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xM,b,$CLJS.LD],null),c),e,c)}return a};
Ssa=function(a,b,c){if($CLJS.Bd(c))return c;var d=function(){var e=$CLJS.Tg([c]);return"string"===typeof c?$CLJS.ck.g(e,$CLJS.LD):e}();return $CLJS.Ne(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.zl($CLJS.tr,$CLJS.xM.h($CLJS.zV(a,b))))};
h5=function(a,b,c,d){b=$CLJS.Vs.g($CLJS.Wg($CLJS.J_.j(b,c,$CLJS.zV(b,c))),$CLJS.Wg($CLJS.J_.j(a,c,$CLJS.zV(a,c))));return $CLJS.cb(function(e,f){return $CLJS.P.M(Y4,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Tsa=function(a,b,c){a=h5(a,b,c,function(d){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,new $CLJS.h(null,1,[$CLJS.pO,$CLJS.TY.h(d)],null),$CLJS.Gi.h(d)],null)});c=$CLJS.S1(a,c);return $CLJS.n(c)?h5(a,b,c,function(d){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,$CLJS.N,$CLJS.CY.h(d)],null)}):a};
Usa=function(a,b,c){return $CLJS.Bd(c)?$CLJS.rz($CLJS.zV(a,b),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xM,c,$CLJS.LD],null)):$CLJS.td(c)?$CLJS.LD.h(c):c};
i5=function(a,b,c,d){var e=Usa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.LV)(a,b,function(k){var l=$CLJS.xM.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.uD(k,$CLJS.xM,l)});return(0,$CLJS.LV)(f,b,function(k){return $CLJS.OV(k,$CLJS.xM,function(l){return $CLJS.ek.g(function(m){return $CLJS.c3(f,b,m)},l)})})}(),c=Tsa(c,a,b),$CLJS.b5.h?$CLJS.b5.h(c):$CLJS.b5.call(null,c)):a};
Vsa=function(a,b){return null!=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ua,function f(d,e){try{if(g5(e,b))return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.Z;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Z)return $CLJS.uU(f,d,e);throw k;}throw l;}}($CLJS.xf,a))))};Wsa=function(a,b){var c=$CLJS.HD.h($CLJS.zV(a,b));if($CLJS.n(c)){var d=$CLJS.Ta($CLJS.Ne($CLJS.ck.g($CLJS.yM,$CLJS.vD),c));return d?V4(a,b,c,$CLJS.U4(a,b)):d}return c};
Xsa=function(a,b){var c=$CLJS.zV(a,b),d=$CLJS.n(Wsa(a,b))?$CLJS.LV.l(a,b,$CLJS.Vj,$CLJS.H([$CLJS.HD])):a;return $CLJS.n($CLJS.xM.h(c))?$CLJS.LV.l(d,b,$CLJS.fk,$CLJS.H([$CLJS.xM,$CLJS.Qe($CLJS.ek,function(e){var f=$CLJS.HD.h(e);var k=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ox,null,$CLJS.Vy,null],null),null);f=k.h?k.h(f):k.call(null,f);return $CLJS.n(f)?e:$CLJS.n(V4(a,b,$CLJS.HD.h(e),$CLJS.H_.j(a,b,$CLJS.T.j(e,$CLJS.HD,$CLJS.ox))))?$CLJS.T.j(e,$CLJS.HD,$CLJS.ox):e})])):d};
$CLJS.b5=function(a){return $CLJS.cb(Xsa,a,$CLJS.bh(0,$CLJS.D($CLJS.kK.h(a))))};Ysa=new $CLJS.M(null,"remove","remove",-131428414);var j5;$CLJS.f5=function(){function a(d,e,f){return $CLJS.td(f)&&$CLJS.E.g($CLJS.fj.h(f),$CLJS.fK)?$CLJS.a5.j?$CLJS.a5.j(d,e,f):$CLJS.a5.call(null,d,e,f):c5(d,e,f,Ysa,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();j5=$CLJS.cE.h($CLJS.ND);
$CLJS.l5=function(){function a(d,e,f,k){if($CLJS.td(f)&&$CLJS.E.g($CLJS.fj.h(f),$CLJS.fK))d=$CLJS.k5.v?$CLJS.k5.v(d,e,f,k):$CLJS.k5.call(null,d,e,f,k);else{var l=j5.h?j5.h(f):j5.call(null,f);l=$CLJS.n(l)?j5.h?j5.h(k):j5.call(null,k):l;d=$CLJS.n(l)?Psa(d,e,f,k):c5(d,e,f,$CLJS.xF,k)}return d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+
arguments.length);};c.j=b;c.v=a;return c}();$CLJS.Zsa=function(){function a(d,e,f,k){f=Ssa(d,e,f);return $CLJS.n(f)?$CLJS.LV.l(d,e,Rsa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.a5=function(){function a(d,e,f){try{return i5(d,e,f,function(u,v){return $CLJS.Ce($CLJS.j0(function(x){return!($CLJS.E.g($CLJS.LD.h(x),v)||Vsa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.Dl(k)),m=$CLJS.J.g(l,$CLJS.yK),t=$CLJS.J.g(l,$CLJS.ux);l=$CLJS.J.g(l,$CLJS.FV);if($CLJS.E.g(t,$CLJS.EV))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.k5=function(){function a(d,e,f,k){return null==k?$CLJS.a5.j(d,e,f):i5(d,e,f,function(l,m){return $CLJS.ek.g(function(t){return $CLJS.E.g($CLJS.LD.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();