var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var I0,J0,Spa,Tpa;$CLJS.F0=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Me(function(c){return $CLJS.Dd(b,c)},a)};$CLJS.G0=function(a,b){return $CLJS.zz($CLJS.Oe(a),b)};$CLJS.H0=function(a){var b;a:for(b=a;;)if($CLJS.td(b))b=$CLJS.Gi.h(b);else{b=$CLJS.Bd(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.YC("Not something with an ID: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))])));};
I0=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=I0[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=I0._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("MetadataProvider.database",a);}return a};J0=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=J0[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=J0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.field",a);}return a};$CLJS.K0=function(a){return I0($CLJS.QZ(a))};
$CLJS.L0=function(a,b){return J0($CLJS.QZ(a),b)};$CLJS.M0=function(a){var b=$CLJS.zV(a,0),c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.aO),e=$CLJS.J.g(c,$CLJS.IU);return $CLJS.Ad(function(){var f=$CLJS.K0(a);$CLJS.n(f)?(f=$CLJS.O(f),f=$CLJS.J.g(f,$CLJS.Gi),f=$CLJS.E.g($CLJS.QO.h(a),f)):f=null;if($CLJS.n(f)){f=$CLJS.n(d)?$CLJS.RZ(a,d):d;if($CLJS.n(f))return f;f=$CLJS.n(e)?$CLJS.TZ(a,e):e;return $CLJS.n(f)?f:$CLJS.E.g($CLJS.fj.h(c),$CLJS.WU)&&$CLJS.E.g($CLJS.RY,$CLJS.SY.h($CLJS.K0(a)))}return f}())};
Spa=new $CLJS.M(null,"is-native","is-native",-1797717934);Tpa=new $CLJS.M(null,"is-editable","is-editable",269018020);$CLJS.C0.m(null,$CLJS.bV,function(a){return $CLJS.B0(a,new $CLJS.h(null,2,[$CLJS.Si,$CLJS.mh,$CLJS.kK,$CLJS.Qe($CLJS.ek,$CLJS.C0)],null))});$CLJS.E_.m(null,$CLJS.bV,function(){throw $CLJS.Th("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.G_.m(null,$CLJS.bV,function(a,b,c,d){return $CLJS.H_.v(a,b,$CLJS.zV(c,b),d)});$CLJS.y_.m(null,$CLJS.bV,function(a,b,c,d){return $CLJS.XZ.v(a,b,$CLJS.zV(c,b),d)});
$CLJS.F_.m(null,$CLJS.bV,function(a,b,c){a=$CLJS.zV(c,0);a=$CLJS.E.g($CLJS.fj.h(a),$CLJS.WU);return new $CLJS.h(null,2,[Spa,a,Tpa,$CLJS.M0(c)],null)});$CLJS.N0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Oi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.fj.h($CLJS.zV(f,0))},e,a,b,c,d)}();$CLJS.N0.m(null,$CLJS.Oh,function(){return!0});
$CLJS.O0=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.fj,$CLJS.bV,$CLJS.ZC,$CLJS.QZ(e),$CLJS.QO,d,$CLJS.kK,f],null)}function b(d,e){var f=$CLJS.Gi.h($CLJS.K0(d));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.P0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.YA],null),$CLJS.Oi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.OA(k)},e,a,b,c,d)}();$CLJS.P0.m(null,$CLJS.fA,function(a,b){b=$CLJS.PX.h(b);return $CLJS.O0.g(a,$CLJS.kK.h(b))});
$CLJS.P0.m(null,$CLJS.bV,function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.LX);var d=$CLJS.QZ(a),e=$CLJS.Vj.g($CLJS.T.j(c,$CLJS.ZC,d),$CLJS.LX);a=$CLJS.kK.h(e);return $CLJS.n(b)?$CLJS.T.j(e,$CLJS.kK,$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.vd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.de(v,$CLJS.$E))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.Oe($CLJS.pz($CLJS.jA,$CLJS.ni))(x)))try{var A=$CLJS.F(t,2);if($CLJS.n($CLJS.WC($CLJS.Xk,
$CLJS.pl)(A))){var C=$CLJS.F(t,2),G=$CLJS.F(t,1),K=$CLJS.Al($CLJS.L0(d,C),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jA,$CLJS.ni],null));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,$CLJS.uk.l($CLJS.H([K,G])),C],null)}throw $CLJS.Z;}catch(ha){if(ha instanceof Error){var S=ha;if(S===$CLJS.Z)throw $CLJS.Z;throw S;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Z)throw $CLJS.Z;throw S;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(S=ha,S===$CLJS.Z)try{if(v=
$CLJS.F(t,0),$CLJS.de(v,$CLJS.gA))try{x=$CLJS.F(t,1);if($CLJS.n($CLJS.Oe($CLJS.pz($CLJS.jA,$CLJS.ni))(x))){G=$CLJS.F(t,1);var Y=$CLJS.F(t,2);K=function(){try{return $CLJS.G0(function(ra){return $CLJS.E.g($CLJS.cj,ra)},$CLJS.Al($CLJS.ed($CLJS.Kpa.j(e,k,Y)),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jA,$CLJS.ni],null)))}catch(ra){return null}}();return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gA,$CLJS.uk.l($CLJS.H([K,G])),Y],null)}throw $CLJS.Z;}catch(ra){if(ra instanceof Error){var ba=ra;if(ba===$CLJS.Z)throw $CLJS.Z;
throw ba;}throw ra;}else throw $CLJS.Z;}catch(ra){if(ra instanceof Error){ba=ra;if(ba===$CLJS.Z)throw $CLJS.Z;throw ba;}throw ra;}else throw S;else throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Z)return $CLJS.SV(u,m,t);throw S;}throw ha;}}($CLJS.xf,f)}),$CLJS.zl($CLJS.tr,a))):e});$CLJS.P0.m(null,$CLJS.JJ,function(a,b){return $CLJS.O0.g(a,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.fj,$CLJS.PU,$CLJS.aO,$CLJS.H0(b)],null)],null))});
$CLJS.P0.m(null,$CLJS.bK,function(a,b){return $CLJS.O0.g(a,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.fj,$CLJS.PU,$CLJS.IU,$CLJS.H0(b)],null)],null))});