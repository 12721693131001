var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var N6,O6,P6,lta,mta,nta,S6,T6,V6,W6,ota,pta,Y6,Z6,qta,rta;N6=function(a){var b=new $CLJS.h(null,3,[$CLJS.oD,$CLJS.p.h($CLJS.yD()),$CLJS.jA,$CLJS.jA.h(a),$CLJS.ni,$CLJS.pz($CLJS.ni,$CLJS.jA)(a)],null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gA,b,$CLJS.pz($CLJS.gD,$CLJS.U)(a)],null)};O6=function(a){return $CLJS.T.j(a,$CLJS.fj,$CLJS.e4)};P6=function(a,b){return $CLJS.E.g($CLJS.bD.h(a),$CLJS.Al(b,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sD,$CLJS.aD,$CLJS.rD],null)))};
lta=function(a){return $CLJS.B0(a,new $CLJS.h(null,1,[$CLJS.sD,$CLJS.mh],null))};
mta=function(a,b){var c=$CLJS.Ne(function(e){return $CLJS.jG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Cz($CLJS.wx);if($CLJS.n($CLJS.Bz("metabase.lib.field",c))){var d=$CLJS.YC("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.ek.g($CLJS.CY,b)]))]));d instanceof Error?$CLJS.Az("metabase.lib.field",c,$CLJS.pv(),d):$CLJS.Az("metabase.lib.field",
c,$CLJS.pv.l($CLJS.H([d])),null)}return null};
nta=function(a,b,c){if($CLJS.n(Q6))return null;var d=Q6;Q6=!0;try{var e=$CLJS.u1(a,b),f=$CLJS.n(e)?$CLJS.zV(a,e):$CLJS.zV(a,b),k=function(){var m=$CLJS.R6.h(f);if($CLJS.n(m))return m;m=$CLJS.rz(f,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iU,$CLJS.wV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.IU.h(f);if($CLJS.n(u))return u;u=$CLJS.aO.h(f);if($CLJS.n(u))return u;u=$CLJS.VO.h(f);return $CLJS.n(u)?u:$CLJS.HD.h(f)}())?$CLJS.J_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Cz($CLJS.wx);if($CLJS.n($CLJS.Bz("metabase.lib.field",
m))){var t=$CLJS.YC("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Az("metabase.lib.field",m,$CLJS.pv(),t):$CLJS.Az("metabase.lib.field",m,$CLJS.pv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?mta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.T.j($CLJS.T.j($CLJS.V2($CLJS.Vj.l(l,$CLJS.Gi,$CLJS.H([$CLJS.TJ,$CLJS.JV,$CLJS.DV])),null),$CLJS.U,function(){var m=$CLJS.CY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.DJ,$CLJS.oY):l:null}finally{Q6=d}};
S6=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.pO);var f=$CLJS.I(c,2,null);c=$CLJS.uk.l($CLJS.H([function(){var k=$CLJS.jA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.jA,k],null):null}(),function(){var k=$CLJS.pz($CLJS.ni,$CLJS.jA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.ni,k],null):null}(),function(){var k=$CLJS.pQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.JV,k],null):null}(),function(){var k=$CLJS.fF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.DV,k],null):null}(),$CLJS.Bd(f)?function(){var k=$CLJS.A1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.fj,$CLJS.BJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.fj,$CLJS.BJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=nta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.fj,$CLJS.BJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.V2(c,d):c};
T6=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.DV);return $CLJS.n($CLJS.n(b)?$CLJS.Dd($CLJS.gG,b):b)?$CLJS.Zi:$CLJS.pz($CLJS.ni,$CLJS.jA)(a)};
$CLJS.U6=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.oD),l=$CLJS.J.g(f,$CLJS.jA),m=$CLJS.J.g(f,$CLJS.pQ),t=$CLJS.J.g(f,$CLJS.ni);e=$CLJS.J.g(f,$CLJS.pO);var u=$CLJS.J.g(f,$CLJS.yM),v=$CLJS.J.g(f,$CLJS.fF),x=$CLJS.uk.l;k=new $CLJS.h(null,2,[$CLJS.fj,$CLJS.BJ,$CLJS.o0,k],null);f=$CLJS.cD.h(f);a=$CLJS.n(f)?f:$CLJS.XZ.j(a,b,d);c=x.call($CLJS.uk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.cD,a],null)]));c=$CLJS.n(t)?$CLJS.T.j(c,$CLJS.ni,t):c;l=$CLJS.n(l)?$CLJS.T.j(c,
$CLJS.jA,l):c;v=$CLJS.n(v)?$CLJS.T.j(l,$CLJS.DV,v):l;m=$CLJS.n(m)?$CLJS.T.j(v,$CLJS.JV,m):v;u=$CLJS.n(u)?$CLJS.T.j(m,$CLJS.SJ,u):m;return $CLJS.n(e)?$CLJS.V2(u,e):u};V6=function(a,b,c){return $CLJS.n($CLJS.Ne(function(d){return $CLJS.E.g($CLJS.ci.h(d),c)},a))?$CLJS.ek.g(function(d){var e=$CLJS.Dd(d,b)?$CLJS.Vj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ci.h(d),c)?$CLJS.T.j(e,b,!0):e},a):a};
W6=function(a){var b=$CLJS.n($CLJS.b1.h(a))?null:function(){var e=$CLJS.DJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.EY,null,$CLJS.LY,null,$CLJS.oY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.uk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.oD,$CLJS.p.h($CLJS.yD()),$CLJS.jA,$CLJS.jA.h(a),$CLJS.ni,T6(a)],null),function(){var e=function(){var f=$CLJS.Ta(b);return f?(f=$CLJS.Ta($CLJS.SJ.h(a)))?(f=$CLJS.bk.g($CLJS.yY,$CLJS.DJ.h(a)))?$CLJS.PY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.pO,e],null):null}(),function(){var e=$CLJS.V0(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.pO,e],null):null}(),function(){var e=$CLJS.DV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.fF,e],null):null}(),function(){var e=$CLJS.HV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.HV,e],null):null}(),function(){var e=$CLJS.JV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.pQ,e],null):null}(),function(){var e=$CLJS.SJ.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.yM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.pz($CLJS.CY,$CLJS.U):$CLJS.pz($CLJS.Gi,$CLJS.U))(a);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,c,d],null)};ota=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.DJ.h(c),$CLJS.wY)},$CLJS.J_.v(a,b,$CLJS.zV(a,b),new $CLJS.h(null,3,[$CLJS.r_,!1,$CLJS.x_,!0,$CLJS.m_,!1],null)))};pta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);$CLJS.X6=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);
Y6=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);Z6=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.R6=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);qta=new $CLJS.M(null,"earliest","earliest",-1928154382);rta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.C0.m(null,$CLJS.$E,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mh.h(b),$CLJS.B0(c,new $CLJS.h(null,2,[$CLJS.fF,$CLJS.mh,$CLJS.pQ,lta],null)),a],null)});var Q6=!1,$6=function $6(a,b){var d=$CLJS.L0(a,Z6.h(b));a=$CLJS.n(Z6.h(d))?$6.g?$6.g(a,d):$6.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U);return $CLJS.fk.j(b,$CLJS.U,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.C_.m(null,$CLJS.BJ,function(a,b,c){return T6(c)});$CLJS.C_.m(null,$CLJS.$E,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.fF);$CLJS.I(c,2,null);c=S6(a,b,c);c=$CLJS.n(d)?$CLJS.T.j(c,$CLJS.DV,d):c;return $CLJS.D_.j(a,b,c)});$CLJS.E_.m(null,$CLJS.BJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.T.j(a,$CLJS.U,b)});$CLJS.E_.m(null,$CLJS.$E,function(a,b,c){var d=S6(a,b,c);b=$CLJS.U6(a,b,d,c);return $CLJS.n(Z6.h(b))?$6(a,b):b});
$CLJS.y_.m(null,$CLJS.BJ,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.cD),f=$CLJS.J.g(c,$CLJS.U),k=$CLJS.J.g(c,$CLJS.ci),l=$CLJS.J.g(c,$CLJS.JV),m=$CLJS.J.g(c,$CLJS.PY),t=$CLJS.J.g(c,$CLJS.SJ),u=$CLJS.J.g(c,$CLJS.TJ);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.T0.g($CLJS.S0,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.YZ)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.L0(a,t),$CLJS.n(t)?a=$CLJS.R2($CLJS.cD.h($CLJS.$Z.j(a,b,t))):(u=$CLJS.U0(a,u),a=$CLJS.XZ.v(a,b,u,d))):a=null,m=$CLJS.n(a)?a:$CLJS.n(m)?m:$CLJS.V0(c)):
m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.Tz($CLJS.wz($CLJS.Xg(k),"-"," ")),$CLJS.ia.j?$CLJS.ia.j("%s: %s",m,c):$CLJS.ia.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.L4(l,c),$CLJS.ia.j?$CLJS.ia.j("%s: %s",m,c):$CLJS.ia.call(null,"%s: %s",m,c)):m});
$CLJS.y_.m(null,$CLJS.$E,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.pQ);var k=$CLJS.J.g(f,$CLJS.pO),l=$CLJS.J.g(f,$CLJS.fF);f=$CLJS.J.g(f,$CLJS.yM);$CLJS.I(c,2,null);c=S6(a,b,c);c=$CLJS.n(k)?$CLJS.T.j(c,$CLJS.PY,k):c;l=$CLJS.n(l)?$CLJS.T.j(c,$CLJS.ci,l):c;e=$CLJS.n(e)?$CLJS.T.j(l,$CLJS.JV,e):l;e=$CLJS.n(f)?$CLJS.T.j(e,$CLJS.SJ,f):e;return $CLJS.n(e)?$CLJS.XZ.v(a,b,e,d):$CLJS.zD("[Unknown Field]")});
$CLJS.z_.m(null,$CLJS.BJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.z_.m(null,$CLJS.$E,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=S6(a,b,c);return $CLJS.n(c)?$CLJS.A_.j(a,b,c):"unknown_field"});
$CLJS.F_.m(null,$CLJS.BJ,function(a,b,c){return $CLJS.uk.l($CLJS.H([function(){var d=$CLJS.cH($CLJS.F_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.DJ.h(c),$CLJS.EY)?function(){var d=$CLJS.OY.h(c);return $CLJS.n(d)?(d=$CLJS.TZ(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.IV,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.cD,$CLJS.U.h(d)],null)],null):null):null}():null]))});$CLJS.f0.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.fF.h(b)});
$CLJS.f0.m(null,$CLJS.BJ,function(a){return $CLJS.DV.h(a)});
$CLJS.e0.m(null,$CLJS.$E,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Dd($CLJS.gG,b),e=$CLJS.hB($CLJS.HV,$CLJS.ni,$CLJS.jA)(c);c=$CLJS.T.l(c,$CLJS.fF,b,$CLJS.H([$CLJS.ni,d?$CLJS.Zi:e,$CLJS.HV,e]));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,c,a],null)}b=$CLJS.HV.h(c);c=$CLJS.n(b)?$CLJS.Vj.g($CLJS.T.j(c,$CLJS.ni,b),$CLJS.HV):c;c=$CLJS.Vj.g(c,$CLJS.fF);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,c,a],null)});
$CLJS.e0.m(null,$CLJS.BJ,function(a,b){return $CLJS.n(b)?$CLJS.T.l(a,$CLJS.DV,b,$CLJS.H([$CLJS.HV,$CLJS.hB($CLJS.HV,$CLJS.ni,$CLJS.jA)(a)])):$CLJS.Vj.l(a,$CLJS.DV,$CLJS.H([$CLJS.HV]))});$CLJS.h0.m(null,$CLJS.$E,function(a,b,c){return $CLJS.i0.j(a,b,S6(a,b,c))});
$CLJS.h0.m(null,$CLJS.BJ,function(a,b,c){if($CLJS.bk.g($CLJS.DJ.h(c),$CLJS.wY)){a=$CLJS.pz($CLJS.ni,$CLJS.jA)(c);b=null==c?null:$CLJS.MM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Dk.h($CLJS.Si.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,qta),l=$CLJS.J.g(f,rta),m=$CLJS.npa.l($CLJS.H([$CLJS.c0.h(k),$CLJS.c0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.zq.g?$CLJS.zq.g(1,m):$CLJS.zq.call(null,1,m))?$CLJS.Ck:$CLJS.n($CLJS.zq.g?$CLJS.zq.g(31,m):$CLJS.zq.call(null,31,m))?$CLJS.Ik:$CLJS.n($CLJS.zq.g?
$CLJS.zq.g(365,m):$CLJS.zq.call(null,365,m))?$CLJS.Ui:$CLJS.$i}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.tz(a,$CLJS.Dk)?$CLJS.ypa:$CLJS.tz(a,$CLJS.Ak)?$CLJS.xpa:$CLJS.tz(a,$CLJS.Jk)?$CLJS.wpa:$CLJS.xf;d=$CLJS.n(d)?V6(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.DV.h(c))?V6(d,$CLJS.e_,$CLJS.DV.h(c)):d}return $CLJS.xf});
$CLJS.I4.m(null,$CLJS.$E,function(a){var b=null==a?null:$CLJS.vD(a);b=null==b?null:$CLJS.pQ.h(b);return null==b?null:$CLJS.T.l(b,$CLJS.fj,$CLJS.N4,$CLJS.H([$CLJS.M4,function(c,d){return S6(c,d,a)}]))});$CLJS.I4.m(null,$CLJS.BJ,function(a){var b=null==a?null:$CLJS.JV.h(a);return null==b?null:$CLJS.T.l(b,$CLJS.fj,$CLJS.N4,$CLJS.H([$CLJS.M4,$CLJS.Pe(a)]))});$CLJS.O4.m(null,$CLJS.$E,function(a,b){return $CLJS.wD(a,$CLJS.uD,$CLJS.H([$CLJS.pQ,b]))});
$CLJS.O4.m(null,$CLJS.BJ,function(a,b){return $CLJS.uD(a,$CLJS.JV,b)});$CLJS.P4.m(null,$CLJS.$E,function(a,b,c){return $CLJS.Q4.j(a,b,S6(a,b,c))});
$CLJS.P4.m(null,$CLJS.BJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.ni);var d=$CLJS.J.g(b,$CLJS.MM),e=$CLJS.J.g(b,$CLJS.tj);if($CLJS.bk.g($CLJS.DJ.h(b),$CLJS.wY)){var f=function(){var m=null==a?null:$CLJS.K0(a);m=null==m?null:$CLJS.Wy.h(m);return null==m?null:$CLJS.Dd(m,$CLJS.pQ)}(),k=$CLJS.rz(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,$CLJS.pj],null)),l=$CLJS.J4(b);return function u(t){return new $CLJS.je(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.wd(v)){var x=$CLJS.jc(v),A=$CLJS.D(x),
C=$CLJS.me(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),Y=C,ba=S;S=P6(S,l)?$CLJS.T.j(ba,$CLJS.e_,!0):ba;Y.add(S);K+=1}else return!0}()?$CLJS.pe($CLJS.re(C),u($CLJS.kc(v))):$CLJS.pe($CLJS.re(C),null)}var G=$CLJS.z(v);return $CLJS.be(function(){var K=G;return P6(G,l)?$CLJS.T.j(K,$CLJS.e_,!0):K}(),u($CLJS.Ic(v)))}return null}},null,null)}($CLJS.Ta(function(){if($CLJS.n(f)){var t=$CLJS.en.h(k);return $CLJS.n(t)?$CLJS.Cj.h(k):t}return f}())?null:$CLJS.tz(e,$CLJS.aj)?$CLJS.ek.g(O6,new $CLJS.Q(null,
5,5,$CLJS.R,[$CLJS.K4(),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.zD("Bin every 0.1 degrees"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.sD,$CLJS.rD,$CLJS.rD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.zD("Bin every 1 degree"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.sD,$CLJS.rD,$CLJS.rD,1],null)],null),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.zD("Bin every 10 degrees"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.sD,$CLJS.rD,$CLJS.rD,10],null)],null),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.zD("Bin every 20 degrees"),$CLJS.bD,new $CLJS.h(null,
2,[$CLJS.sD,$CLJS.rD,$CLJS.rD,20],null)],null)],null)):$CLJS.tz(c,$CLJS.pj)&&!$CLJS.tz(e,$CLJS.Zh)?$CLJS.ek.g(O6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.K4(),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.zD("10 bins"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.sD,$CLJS.aD,$CLJS.aD,10],null)],null),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.zD("50 bins"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.sD,$CLJS.aD,$CLJS.aD,50],null)],null),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.zD("100 bins"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.sD,$CLJS.aD,$CLJS.aD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.iV.m(null,$CLJS.$E,function(a){return a});
$CLJS.iV.m(null,$CLJS.BJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.DJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.oD,$CLJS.p.h($CLJS.yD()),$CLJS.ni,$CLJS.pz($CLJS.ni,$CLJS.jA)(a),$CLJS.tK,$CLJS.U.h(a)],null),a=$CLJS.o0.h(a),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PE,b,a],null);case "source/expressions":return N6(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.gD.h(a))?N6(a):W6(a);default:return W6(a)}});
$CLJS.sta=function(){function a(e,f,k){k=$CLJS.Ce($CLJS.ek.g($CLJS.kV,k));var l=ota(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.sl(function(t){return $CLJS.G1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.dk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.kV),m):null;return $CLJS.LV.l(e,f,$CLJS.uD,$CLJS.H([$CLJS.HD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.a7=function(){function a(d,e){return $CLJS.HD.h($CLJS.zV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.tta=function(){function a(d,e){var f=$CLJS.J_.v(d,e,$CLJS.zV(d,e),new $CLJS.h(null,3,[$CLJS.r_,!1,$CLJS.x_,!1,$CLJS.m_,!1],null)),k=$CLJS.a7.g(d,e);return $CLJS.pd(k)?$CLJS.ek.g(function(l){return $CLJS.T.j(l,$CLJS.DY,!0)},f):$CLJS.T2(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.X(Y6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yq,$CLJS.dj,$CLJS.rY,$CLJS.Vy],null));
$CLJS.X(pta,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MJ,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.aK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.aK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KY,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,Y6],null)],null)],null));