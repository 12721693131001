var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var Rs,uda,vda,Us,wda,Xs,Ys;
$CLJS.Qs=function(a,b,c){return function(){function d(t,u,v){return a.M?a.M(b,c,t,u,v):a.call(null,b,c,t,u,v)}function e(t,u){return a.v?a.v(b,c,t,u):a.call(null,b,c,t,u)}function f(t){return a.j?a.j(b,c,t):a.call(null,b,c,t)}function k(){return a.g?a.g(b,c):a.call(null,b,c)}var l=null,m=function(){function t(v,x,A,C){var G=null;if(3<arguments.length){G=0;for(var K=Array(arguments.length-3);G<K.length;)K[G]=arguments[G+3],++G;G=new $CLJS.w(K,0,null)}return u.call(this,v,x,A,G)}function u(v,x,A,C){return $CLJS.P.l(a,
b,c,v,x,$CLJS.H([A,C]))}t.A=3;t.B=function(v){var x=$CLJS.z(v);v=$CLJS.B(v);var A=$CLJS.z(v);v=$CLJS.B(v);var C=$CLJS.z(v);v=$CLJS.Ic(v);return u(x,A,C,v)};t.l=u;return t}();l=function(t,u,v,x){switch(arguments.length){case 0:return k.call(this);case 1:return f.call(this,t);case 2:return e.call(this,t,u);case 3:return d.call(this,t,u,v);default:var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.l(t,u,v,A)}throw Error("Invalid arity: "+
arguments.length);};l.A=3;l.B=m.B;l.o=k;l.h=f;l.g=e;l.j=d;l.l=m.l;return l}()};Rs=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.Q(null,3,5,$CLJS.R,[null,null,a],null):new $CLJS.Q(null,3,5,$CLJS.R,[a,b,null],null)};uda=function(a){return $CLJS.y(a)?$CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return $CLJS.T.j(b,d,c)},$CLJS.yf($CLJS.Ze($CLJS.P.g($CLJS.Zj,$CLJS.Ng(a)),null)),a):null};
vda=function(a,b,c){var d=$CLJS.J.g(a,c),e=$CLJS.J.g(b,c),f=$CLJS.Ss(d,e),k=$CLJS.I(f,0,null),l=$CLJS.I(f,1,null);f=$CLJS.I(f,2,null);a=$CLJS.Dd(a,c);b=$CLJS.Dd(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.Q(null,3,5,$CLJS.R,[!a||null==k&&d?null:$CLJS.Be([c,k]),!b||null==l&&d?null:$CLJS.Be([c,l]),d?$CLJS.Be([c,f]):null],null)};
Us=function(a,b){var c=$CLJS.cf.g,d=Ts.j,e=$CLJS.vd(a)?a:$CLJS.yf(a),f=$CLJS.vd(b)?b:$CLJS.yf(b);a=$CLJS.D(a);b=$CLJS.D(b);return $CLJS.yf(c.call($CLJS.cf,uda,d.call(Ts,e,f,$CLJS.bh(0,a>b?a:b))))};wda=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ce($CLJS.Vs.g(a,b)),$CLJS.Ce($CLJS.Vs.g(b,a)),$CLJS.Ce($CLJS.Ws.g(a,b))],null)};
Xs=function(a){if(null!=a&&null!=a.ih)a=a.ih(a);else{var b=Xs[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Xs._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("EqualityPartition.equality-partition",a);}return a};Ys=function(a,b){if(null!=a&&null!=a.hh)a=a.hh(a,b);else{var c=Ys[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Ys._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("Diff.diff-similar",a);}return a};
$CLJS.Ss=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.Q(null,3,5,$CLJS.R,[null,null,a],null):$CLJS.E.g(Xs(a),Xs(b))?Ys(a,b):Rs(a,b)};$CLJS.Ws=function Ws(a){switch(arguments.length){case 1:return Ws.h(arguments[0]);case 2:return Ws.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ws.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.Ws.h=function(a){return a};
$CLJS.Ws.g=function(a,b){for(;;)if($CLJS.D(b)<$CLJS.D(a)){var c=a;a=b;b=c}else return $CLJS.cb(function(d,e){return function(f,k){return $CLJS.Dd(e,k)?f:$CLJS.Wj.g(f,k)}}(a,b),a,a)};$CLJS.Ws.l=function(a,b,c){a=$CLJS.Os(function(d){return-$CLJS.D(d)},$CLJS.Zd.l(c,b,$CLJS.H([a])));return $CLJS.cb($CLJS.Ws,$CLJS.z(a),$CLJS.Ic(a))};$CLJS.Ws.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.Ws.A=2;
$CLJS.Vs=function Vs(a){switch(arguments.length){case 1:return Vs.h(arguments[0]);case 2:return Vs.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Vs.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.Vs.h=function(a){return a};$CLJS.Vs.g=function(a,b){return $CLJS.D(a)<$CLJS.D(b)?$CLJS.cb(function(c,d){return $CLJS.Dd(b,d)?$CLJS.Wj.g(c,d):c},a,a):$CLJS.cb($CLJS.Wj,a,b)};
$CLJS.Vs.l=function(a,b,c){return $CLJS.cb($CLJS.Vs,a,$CLJS.Zd.g(c,b))};$CLJS.Vs.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.Vs.A=2;var Zs=new $CLJS.M(null,"atom","atom",-397043653);var Ts=function Ts(a){switch(arguments.length){case 2:return Ts.g(arguments[0],arguments[1]);case 3:return Ts.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};Ts.g=function(a,b){return Ts.j(a,b,$CLJS.Ps.g($CLJS.Ng(a),$CLJS.Ng(b)))};Ts.j=function(a,b,c){return $CLJS.cb(function(d,e){return $CLJS.ch($CLJS.cf.j($CLJS.uk,d,e))},new $CLJS.Q(null,3,5,$CLJS.R,[null,null,null],null),$CLJS.cf.g($CLJS.Qs(vda,a,b),c))};Ts.A=3;
Xs["null"]=function(){return Zs};Xs.string=function(){return Zs};Xs.number=function(){return Zs};Xs.array=function(){return $CLJS.bj};Xs["function"]=function(){return Zs};Xs["boolean"]=function(){return Zs};
Xs._=function(a){return(null!=a?a.C&1024||$CLJS.zc===a.Wg||(a.C?0:$CLJS.Ya($CLJS.zb,a)):$CLJS.Ya($CLJS.zb,a))?$CLJS.Bj:(null!=a?a.C&4096||$CLJS.zc===a.$g||(a.C?0:$CLJS.Ya($CLJS.Eb,a)):$CLJS.Ya($CLJS.Eb,a))?$CLJS.Hi:(null!=a?a.C&16777216||$CLJS.zc===a.qf||(a.C?0:$CLJS.Ya($CLJS.Ub,a)):$CLJS.Ya($CLJS.Ub,a))?$CLJS.bj:Zs};Ys["null"]=function(a,b){return Rs(a,b)};Ys.string=function(a,b){return Rs(a,b)};Ys.number=function(a,b){return Rs(a,b)};Ys.array=function(a,b){return Us(a,b)};
Ys["function"]=function(a,b){return Rs(a,b)};Ys["boolean"]=function(a,b){return Rs(a,b)};Ys._=function(a,b){var c=function(){var d=Xs(a);d=d instanceof $CLJS.M?d.S:null;switch(d){case "atom":return Rs;case "set":return wda;case "sequential":return Us;case "map":return Ts;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}();return c.g?c.g(a,b):c.call(null,a,b)};