var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var yta,zta,m7,Ata,Bta,Cta,Dta,Eta,Fta,Gta,n7,o7,p7;
yta=function(a){return function(b){var c=$CLJS.Ve($CLJS.Sg);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Dd($CLJS.q(c),t))return l;c.nd(null,$CLJS.Zd.g(c.Lb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};zta=function(a,b,c){return $CLJS.Wf.j($CLJS.xf,$CLJS.XC(function(d){return $CLJS.J_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.t_,c,$CLJS.m_,!1],null))}),$CLJS.xM.h($CLJS.zV(a,b)))};m7=function(a,b,c){return $CLJS.Wf.j($CLJS.xf,$CLJS.XC(function(d){return $CLJS.H_.v(a,b,d,c)}),$CLJS.xM.h($CLJS.zV(a,b)))};$CLJS.l3=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.LV.l(c,d,$CLJS.T,$CLJS.H([$CLJS.R6,$CLJS.H_.j(c,d,$CLJS.zV(c,d))]))},a,$CLJS.it(0,$CLJS.yV(a,b)))};
Ata=function(a,b){a=$CLJS.zV(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.fj),d=$CLJS.J.g(a,$CLJS.IU);b=$CLJS.R6.h(a);if($CLJS.n(b))return b;var e=$CLJS.iU.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.WU);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.S:null;switch(k){case "mbql.stage/native":return $CLJS.LY;case "mbql.stage/mbql":return $CLJS.EY;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Ce(function(){return function m(l){return new $CLJS.je(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.jc(t),v=$CLJS.D(u),x=$CLJS.me(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A);C=$CLJS.uk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.nY,$CLJS.U.h(C),$CLJS.CY,$CLJS.U.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.DJ,f],null)]));x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.pe($CLJS.re(x),m($CLJS.kc(t))):$CLJS.pe($CLJS.re(x),null)}x=$CLJS.z(t);return $CLJS.be($CLJS.uk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.nY,$CLJS.U.h(x),$CLJS.CY,$CLJS.U.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.DJ,f],null)])),m($CLJS.Ic(t)))}return null}},null,null)}($CLJS.wV.h(e))}())}return null};
Bta=function(a,b,c){return $CLJS.Ce(function(){return function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t,C=$CLJS.T,G=C.l,K=x,S=$CLJS.pz($CLJS.nY,$CLJS.U)(x);x=$CLJS.X0(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.DJ,$CLJS.VJ,$CLJS.H([$CLJS.nY,S,$CLJS.CY,x]));A.add(C);v+=1}else return!0}()?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),
null)}var u=$CLJS.z(k);return $CLJS.be($CLJS.T.l(u,$CLJS.DJ,$CLJS.VJ,$CLJS.H([$CLJS.nY,$CLJS.pz($CLJS.nY,$CLJS.U)(u),$CLJS.CY,function(){var v=$CLJS.X0(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Ic(k)))}return null}},null,null)}($CLJS.r5.g(a,b))}())};
Cta=function(a,b,c){return $CLJS.Ce(function(){return function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t,C=$CLJS.T,G=C.l,K=x,S=$CLJS.U.h(x);x=$CLJS.U.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.DJ,$CLJS.dK,$CLJS.H([$CLJS.nY,S,$CLJS.CY,x]));A.add(C);v+=1}else return!0}()?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),null)}var u=
$CLJS.z(k);return $CLJS.be($CLJS.T.l(u,$CLJS.DJ,$CLJS.dK,$CLJS.H([$CLJS.nY,$CLJS.U.h(u),$CLJS.CY,function(){var v=$CLJS.U.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Ic(k)))}return null}},null,null)}($CLJS.W3.g(a,b))}())};
Dta=function(a,b,c){var d=$CLJS.zV(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.HD);return $CLJS.Ce(function(){return function l(k){return new $CLJS.je(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.wd(m)){var t=$CLJS.jc(m),u=$CLJS.D(t),v=$CLJS.me(u);return function(){for(var K=0;;)if(K<u){var S=$CLJS.hd(t,K),Y=$CLJS.I(S,0,null),ba=function(){var ra=Y;ra=ra instanceof $CLJS.M?ra.S:null;switch(ra){case "field":return $CLJS.JY;case "expression":return $CLJS.wY;default:throw Error(["No matching clause: ",
$CLJS.p.h(ra)].join(""));}}(),ha=$CLJS.VZ.j(a,b,S);$CLJS.qe(v,$CLJS.T.l(ha,$CLJS.DJ,ba,$CLJS.H([$CLJS.nY,$CLJS.A_.j(a,b,ha),$CLJS.CY,function(){var ra=$CLJS.X0(a,ha);return c.h?c.h(ra):c.call(null,ra)}()])));K+=1}else return!0}()?$CLJS.pe($CLJS.re(v),l($CLJS.kc(m))):$CLJS.pe($CLJS.re(v),null)}var x=$CLJS.z(m),A=$CLJS.I(x,0,null),C=function(){var K=A;K=K instanceof $CLJS.M?K.S:null;switch(K){case "field":return $CLJS.JY;case "expression":return $CLJS.wY;default:throw Error(["No matching clause: ",
$CLJS.p.h(K)].join(""));}}(),G=$CLJS.VZ.j(a,b,x);return $CLJS.be($CLJS.T.l(G,$CLJS.DJ,C,$CLJS.H([$CLJS.nY,$CLJS.A_.j(a,b,G),$CLJS.CY,function(){var K=$CLJS.X0(a,G);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Ic(m)))}return null}},null,null)}(e)}())}return null};Eta=function(a,b,c){return $CLJS.Ce($CLJS.Wf.j($CLJS.xf,$CLJS.XC(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.Q(null,2,5,$CLJS.R,[Bta,Cta],null)))};
Fta=function(a,b,c){var d=$CLJS.u1(a,b);return $CLJS.n(d)?$CLJS.Ce(function(){return function k(f){return new $CLJS.je(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.jc(l),t=$CLJS.D(m),u=$CLJS.me(t);return function(){for(var A=0;;)if(A<t){var C=$CLJS.hd(m,A);var G=$CLJS.pz($CLJS.CY,$CLJS.nY)(C);G=$CLJS.n(G)?G:$CLJS.A_.j(a,b,C);C=$CLJS.Vj.l($CLJS.uk.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.DJ,$CLJS.oY,$CLJS.nY,G,$CLJS.CY,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.b1.h(C))?
$CLJS.Al(C,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.b1],null)):null])),$CLJS.DV,$CLJS.H([$CLJS.gD]));u.add(C);A+=1}else return!0}()?$CLJS.pe($CLJS.re(u),k($CLJS.kc(l))):$CLJS.pe($CLJS.re(u),null)}var v=$CLJS.z(l),x=function(){var A=$CLJS.pz($CLJS.CY,$CLJS.nY)(v);return $CLJS.n(A)?A:$CLJS.A_.j(a,b,v)}();return $CLJS.be($CLJS.Vj.l($CLJS.uk.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.DJ,$CLJS.oY,$CLJS.nY,x,$CLJS.CY,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.b1.h(v))?$CLJS.Al(v,new $CLJS.Q(null,1,5,$CLJS.R,
[$CLJS.b1],null)):null])),$CLJS.DV,$CLJS.H([$CLJS.gD])),k($CLJS.Ic(l)))}return null}},null,null)}($CLJS.H_.j(a,d,$CLJS.zV(a,d)))}()):null};Gta=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.TZ(a,c),$CLJS.n(c)?$CLJS.Ce($CLJS.J_.v(a,b,c,d)):null):null};
n7=function(a,b,c){return $CLJS.Ce(function(){return function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t;var C=$CLJS.jA.h(x);var G=$CLJS.T.l,K=x,S=$CLJS.U.h(x);x=$CLJS.U.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.r1(G.call($CLJS.T,K,$CLJS.DJ,$CLJS.wY,$CLJS.H([$CLJS.nY,S,$CLJS.CY,x])),$CLJS.ni,$CLJS.n(C)?C:$CLJS.cj);A.add(C);v+=1}else return!0}()?$CLJS.pe($CLJS.re(t),
f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),null)}var u=$CLJS.z(k);return $CLJS.be(function(){var v=$CLJS.jA.h(u),x=$CLJS.T.l,A=u,C=$CLJS.U.h(u);var G=$CLJS.U.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.r1(x.call($CLJS.T,A,$CLJS.DJ,$CLJS.wY,$CLJS.H([$CLJS.nY,C,$CLJS.CY,G])),$CLJS.ni,$CLJS.n(v)?v:$CLJS.cj)}(),f($CLJS.Ic(k)))}return null}},null,null)}($CLJS.Ipa.g(a,b))}())};
o7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.t_);return $CLJS.ek.g(function(f){return $CLJS.Vj.l(f,$CLJS.TY,$CLJS.H([$CLJS.DV,$CLJS.JV,$CLJS.SJ]))},function(){var f=Fta(a,b,e);if($CLJS.n(f))return f;f=$CLJS.zV(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.aO),l=$CLJS.J.g(f,$CLJS.IU),m=$CLJS.n(k)?function(){var t=$CLJS.RZ(a,k);return $CLJS.J_.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?Gta(a,b,l,$CLJS.T.j(d,$CLJS.m_,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.je(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.wd(x)){var A=$CLJS.jc(x),C=$CLJS.D(A),G=$CLJS.me(C);return function(){for(var S=0;;)if(S<C){var Y=$CLJS.hd(A,S),ba=G,ha=$CLJS.T,ra=ha.l,Ra=Y,Ab=$CLJS.U.h(Y);Y=$CLJS.U.h(Y);Y=e.h?e.h(Y):e.call(null,Y);ha=ra.call(ha,Ra,$CLJS.DJ,$CLJS.LY,$CLJS.H([$CLJS.nY,Ab,$CLJS.CY,Y]));ba.add(ha);S+=1}else return!0}()?$CLJS.pe($CLJS.re(G),v($CLJS.kc(x))):$CLJS.pe($CLJS.re(G),null)}var K=$CLJS.z(x);return $CLJS.be($CLJS.T.l(K,$CLJS.DJ,$CLJS.LY,$CLJS.H([$CLJS.nY,$CLJS.U.h(K),
$CLJS.CY,function(){var S=$CLJS.U.h(K);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Ic(x)))}return null}},null,null)}($CLJS.wV.h($CLJS.iU.h(f)))}())};p7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.pra={};$CLJS.fE($CLJS.PU,p7);$CLJS.fE($CLJS.WU,p7);$CLJS.C0.m(null,$CLJS.PU,function(a){return $CLJS.B0(a,new $CLJS.h(null,2,[$CLJS.PE,$CLJS.Qe($CLJS.ek,$CLJS.C0),$CLJS.cV,$CLJS.Qe($CLJS.ek,$CLJS.C0)],null))});$CLJS.E_.m(null,p7,function(){throw $CLJS.Th("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.I_.m(null,p7,function(a,b,c,d){d=$CLJS.O(d);c=$CLJS.J.g(d,$CLJS.t_);var e=$CLJS.J.g(d,$CLJS.m_);a=$CLJS.l3(a,b);var f=$CLJS.O(d);var k=$CLJS.J.g(f,$CLJS.t_),l=$CLJS.J.g(f,$CLJS.r_),m=$CLJS.J.g(f,$CLJS.x_);f=$CLJS.bf.l(o7(a,b,f),$CLJS.n(m)?n7(a,b,k):null,$CLJS.H([$CLJS.n(l)?zta(a,b,k):null]));k=$CLJS.bf.g;d=$CLJS.n(e)?(e=$CLJS.Ta($CLJS.IU.h($CLJS.zV(a,b))))?e:$CLJS.v_.h(d):e;return $CLJS.yf(k.call($CLJS.bf,f,$CLJS.n(d)?$CLJS.Y0(a,b,f,c):null))});
$CLJS.G_.m(null,p7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.t_);var e=Ata(a,b);if($CLJS.n(e))return e;a=$CLJS.l3(a,b);e=Eta(a,b,d);var f=Dta(a,b,d);return $CLJS.n(e)?$CLJS.Wf.g(e,f):$CLJS.n(f)?($CLJS.ch(f),$CLJS.Wf.j($CLJS.xf,yta(function(k){return $CLJS.Vj.l(k,$CLJS.PY,$CLJS.H([$CLJS.DJ,$CLJS.o0,$CLJS.CY]))}),$CLJS.bf.g(f,m7(a,b,c)))):$CLJS.bf.l(o7(a,b,new $CLJS.h(null,2,[$CLJS.m_,!1,$CLJS.t_,d],null)),n7(a,b,d),$CLJS.H([m7(a,b,c)]))});$CLJS.y_.m(null,$CLJS.WU,function(){return $CLJS.zD("Native query")});
var q7=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aO,$CLJS.IU,$CLJS.xM],null),r7=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.PE,$CLJS.OE,$CLJS.cV,$CLJS.JQ,$CLJS.fR],null);
$CLJS.y_.m(null,$CLJS.PU,function(a,b,c,d){var e=$CLJS.l3(a,b);a=$CLJS.Ce(function(){var f=$CLJS.Wf.j($CLJS.N,$CLJS.ck.g($CLJS.iB,$CLJS.cf.h(function(l){return new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.$oa.j(e,b,l)],null)})),new $CLJS.Q(null,2,5,$CLJS.R,[q7,r7],null)),k=$CLJS.bs(" + ",$CLJS.dk.g($CLJS.xz,$CLJS.cf.g(f,q7)));f=$CLJS.cf.g(f,r7);return $CLJS.bs(", ",$CLJS.dk.g($CLJS.xz,$CLJS.be(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.u1(e,b);return $CLJS.n(a)?$CLJS.XZ.v(e,a,$CLJS.zV(e,a),d):null});