var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var d7,f7,h7,vta,j7,k7,wta;d7=function(a){if("string"===typeof a)return $CLJS.dh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.dh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.Fl(a));};$CLJS.e7=function(a){return $CLJS.Va(a)?$CLJS.Dy(a,$CLJS.H([$CLJS.ii,!0])):a};
f7=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=f7[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=f7._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.setting",a);}return a};$CLJS.g7=function(a,b){return f7($CLJS.QZ(a),b)};h7=function(a,b,c){a=(b-a)/c;if(null!=a)a=Math.ceil(a);else throw $CLJS.Th("Unexpected Null passed to ceil",new $CLJS.h(null,1,[$CLJS.zi,"ceil"],null));a=$CLJS.Sd(a);return 1<a?a:1};
vta=function(a,b,c){var d=d7(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.oz(d))/Math.log(10)));return $CLJS.Ne(function(f){f*=e;return f>=d?f:null},$CLJS.bsa)};
$CLJS.i7=function(a,b,c){var d=$CLJS.J4(b);if($CLJS.n(d)){var e=$CLJS.sD.h(d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "num-bins":e=$CLJS.rz(b,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MM,$CLJS.Si,$CLJS.pj],null));if($CLJS.n(e)){a=$CLJS.O(e);e=$CLJS.J.g(a,$CLJS.en);var f=$CLJS.J.g(a,$CLJS.Cj);a=$CLJS.O(d);a=$CLJS.J.g(a,$CLJS.aD);a=vta(e,f,a);return new $CLJS.h(null,3,[$CLJS.rD,a,$CLJS.AK,c,$CLJS.DK,c+a],null)}return null;case "bin-width":return a=$CLJS.O(d),a=$CLJS.J.g(a,$CLJS.rD),new $CLJS.h(null,
3,[$CLJS.rD,a,$CLJS.AK,c,$CLJS.DK,c+a],null);case "default":e=$CLJS.rz(b,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MM,$CLJS.Si,$CLJS.pj],null));if($CLJS.n(e)){d=$CLJS.O(e);e=$CLJS.J.g(d,$CLJS.en);f=$CLJS.J.g(d,$CLJS.Cj);a:switch(d=e,e=f,f=$CLJS.Oh instanceof $CLJS.M?$CLJS.Oh.S:null,f){case "num-bins":a=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aD,new $CLJS.h(null,2,[$CLJS.aD,null,$CLJS.rD,d7(((e-d)/0).toFixed(5))],null)],null);break a;case "bin-width":a=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rD,new $CLJS.h(null,
2,[$CLJS.rD,null,$CLJS.aD,h7(d,e,null)],null)],null);break a;case "default":$CLJS.GZ(b)?(a=$CLJS.g7(a,$CLJS.asa),a=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rD,new $CLJS.h(null,2,[$CLJS.rD,a,$CLJS.aD,h7(d,e,a)],null)],null)):(a=$CLJS.g7(a,$CLJS.$ra),a=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aD,new $CLJS.h(null,2,[$CLJS.aD,a,$CLJS.rD,d7(((e-d)/a).toFixed(5))],null)],null));break a;default:throw Error(["No matching clause: ",$CLJS.p.h(f)].join(""));}if($CLJS.n(a))return $CLJS.I(a,0,null),a=$CLJS.I(a,1,null),
a=$CLJS.O(a),a=$CLJS.J.g(a,$CLJS.rD),new $CLJS.h(null,3,[$CLJS.rD,a,$CLJS.AK,c,$CLJS.DK,c+a],null)}return null;default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}}else return null};j7=function(a){var b=$CLJS.Wy.h($CLJS.K0(a));b=b.h?b.h($CLJS.b7):b.call(null,$CLJS.b7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.pd($CLJS.V3.g(b,-1))&&$CLJS.pd($CLJS.q5.g(b,-1))){if(b=$CLJS.fk.j(b,$CLJS.kK,$CLJS.nd),!$CLJS.y($CLJS.kK.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
k7=function(a,b){var c=j7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.tU(b,new $CLJS.h(null,2,[$CLJS.DV,$CLJS.c7,$CLJS.JV,$CLJS.uta],null));var d=$CLJS.H_,e=d.j,f=a;var k=a;var l=$CLJS.u1(k,-1);k=$CLJS.n(l)?$CLJS.zV(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.G1.v(a,-2,$CLJS.kV(b),d);if($CLJS.n(b))a=$CLJS.fk.j(a,$CLJS.kK,$CLJS.nd);else return null}};
wta=function(a,b,c){var d=function(){var e=$CLJS.n($CLJS.J4(b))?function(){var f=$CLJS.o5(b,null);if(null!=c){var k=$CLJS.i7(a,b,c);if($CLJS.n(k)){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.AK);l=$CLJS.J.g(l,$CLJS.DK);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.n5(f,k),$CLJS.m5(f,l)],null)}return null}return new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.s1($CLJS.AE,new $CLJS.Q(null,1,5,$CLJS.R,[f],null))],null)}():null;if($CLJS.n(e))return e;e=function(){var f=$CLJS.c7.h(b);return $CLJS.n(f)?$CLJS.S2(b,f):b}();return new $CLJS.Q(null,
1,5,$CLJS.R,[(0,$CLJS.L2)(e,c)],null)}();return $CLJS.cb(function(e,f){return $CLJS.M2.j(e,-1,f)},a,d)};
$CLJS.l7=function(a,b){b=$CLJS.O(b);var c=$CLJS.J.g(b,$CLJS.uK),d=$CLJS.J.g(b,$CLJS.BK);b=$CLJS.LV.l(a,-1,$CLJS.Vj,$CLJS.H([$CLJS.PE,$CLJS.OE,$CLJS.JQ,$CLJS.fR,$CLJS.HD]));b=$CLJS.cb(function(f,k){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.Ai);l=$CLJS.J.g(l,$CLJS.ej);return wta(f,k,l)},b,function(){return function l(k){return new $CLJS.je(null,function(){for(var m=k;;)if(m=$CLJS.y(m)){if($CLJS.wd(m)){var t=$CLJS.jc(m),u=$CLJS.D(t),v=$CLJS.me(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);$CLJS.bk.g($CLJS.DJ.h($CLJS.Ai.h(A)),
$CLJS.dK)&&v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.pe($CLJS.re(v),l($CLJS.kc(m))):$CLJS.pe($CLJS.re(v),null)}v=$CLJS.z(m);if($CLJS.bk.g($CLJS.DJ.h($CLJS.Ai.h(v)),$CLJS.dK))return $CLJS.be(v,l($CLJS.Ic(m)));m=$CLJS.Ic(m)}else return null},null,null)}(d)}());var e=function(){var f=$CLJS.fd(c);return $CLJS.n(f)?$CLJS.jG(function(k){return $CLJS.E.g($CLJS.S4(k),f)},$CLJS.V3.g(a,-1)):null}();return $CLJS.cb(function(f,k){return $CLJS.M2.j(f,-1,k)},b,$CLJS.n(e)?function(){var f=$CLJS.z(e);switch(f instanceof
$CLJS.M?f.S:null){case "sum-where":case "count-where":case "share":return new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.fd(e)],null);case "metric":return $CLJS.N2.g($CLJS.T.j($CLJS.PX.h((0,$CLJS.vX)($CLJS.r1($CLJS.e7(new $CLJS.h(null,2,[$CLJS.Si,$CLJS.vE,$CLJS.vE,$CLJS.e7($CLJS.xY.h($CLJS.A3(a,$CLJS.fd(e))))],null)),$CLJS.Si,$CLJS.vE))),$CLJS.QO,$CLJS.QO.h(a)),-1);default:return null}}():null)};$CLJS.y5.m(null,$CLJS.w6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.q6);b=$CLJS.J.g(b,$CLJS.RJ);return new $CLJS.h(null,3,[$CLJS.Si,$CLJS.w6,$CLJS.q6,a,$CLJS.RJ,b],null)});
$CLJS.x5.m(null,$CLJS.w6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){return $CLJS.l7(j7(c),$CLJS.fk.j(e,$CLJS.BK,function(f){return function m(l){return new $CLJS.je(null,function(){for(;;){var t=$CLJS.y(l);if(t){var u=t;if($CLJS.wd(u)){var v=$CLJS.jc(u),x=$CLJS.D(v),A=$CLJS.me(x);return function(){for(var G=0;;)if(G<x){var K=$CLJS.hd(v,
G);$CLJS.qe(A,$CLJS.fk.j(K,$CLJS.Ai,function(){return function(S){return k7(c,S)}}(G,K,v,x,A,u,t)));G+=1}else return!0}()?$CLJS.pe($CLJS.re(A),m($CLJS.kc(u))):$CLJS.pe($CLJS.re(A),null)}var C=$CLJS.z(u);return $CLJS.be($CLJS.fk.j(C,$CLJS.Ai,function(){return function(G){return k7(c,G)}}(C,u,t)),m($CLJS.Ic(u)))}return null}},null,null)}(f)}))}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Ic(c);return b(d,e,f,c)};a.l=b;return a}());