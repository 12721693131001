var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var FI;$CLJS.DI=function(a){return $CLJS.n($CLJS.hB($CLJS.sd,$CLJS.rd,$CLJS.Wk)(a))?a:new $CLJS.Q(null,1,5,$CLJS.R,[a],null)};
$CLJS.EI=function(a,b){return $CLJS.Ne($CLJS.Ad,function(){return function e(d){return new $CLJS.je(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.je(null,function(K,S,Y,ba){return function(){for(var ha=C;;){var ra=$CLJS.y(ha);if(ra){var Ra=ra,Ab=$CLJS.z(Ra);if(ra=$CLJS.y(function(Pa,Wa,ib,Na,ob,Ja,cc,Md){return function Pi(od){return new $CLJS.je(null,function(jN,Ln,nr,zv,xza,BF){return function(){for(;;){var Av=
$CLJS.y(od);if(Av){if($CLJS.wd(Av)){var CF=$CLJS.jc(Av),p_=$CLJS.D(CF),Bv=$CLJS.me(p_);a:for(var kN=0;;)if(kN<p_){var DF=$CLJS.hd(CF,kN);DF=$CLJS.E.g(BF,$CLJS.OD)||$CLJS.E.g(nr,$CLJS.OD)||$CLJS.pE(BF,DF)&&$CLJS.pE(nr,DF);Bv.add(DF);kN+=1}else{CF=!0;break a}return CF?$CLJS.pe($CLJS.re(Bv),Pi($CLJS.kc(Av))):$CLJS.pe($CLJS.re(Bv),null)}Bv=$CLJS.z(Av);return $CLJS.be($CLJS.E.g(BF,$CLJS.OD)||$CLJS.E.g(nr,$CLJS.OD)||$CLJS.pE(BF,Bv)&&$CLJS.pE(nr,Bv),Pi($CLJS.Ic(Av)))}return null}}}(Pa,Wa,ib,Na,ob,Ja,cc,
Md),null,null)}}(ha,K,Ab,Ra,ra,S,Y,ba)($CLJS.$D)))return $CLJS.bf.g(ra,G($CLJS.Ic(ha)));ha=$CLJS.Ic(ha)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.DI($CLJS.CD(b)))))return $CLJS.bf.g(k,e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null}},null,null)}($CLJS.DI($CLJS.CD(a)))}())};
FI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,$CLJS.P.j($CLJS.lE,d,e),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);return $CLJS.Me($CLJS.gl,
$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.EI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Ic(d);return c(e,d)};b.l=c;return b}()};$CLJS.GI=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.HI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var II=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gr,$CLJS.ar],null)),JI=null,KI=0,LI=0;;)if(LI<KI){var Lia=JI.X(null,LI);$CLJS.oE(Lia,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pq,new $CLJS.h(null,1,[$CLJS.en,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.MD],null)],null)],null)],null)]));LI+=1}else{var MI=$CLJS.y(II);if(MI){var NI=MI;if($CLJS.wd(NI)){var OI=$CLJS.jc(NI),Mia=$CLJS.kc(NI),
Nia=OI,Oia=$CLJS.D(OI);II=Mia;JI=Nia;KI=Oia}else{var Pia=$CLJS.z(NI);$CLJS.oE(Pia,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pq,new $CLJS.h(null,1,[$CLJS.en,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.MD],null)],null)],null)],null)]));II=$CLJS.B(NI);JI=null;KI=0}LI=0}else break}$CLJS.mE($CLJS.cr,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.MD],null)]));
for(var PI=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.qF],null)),QI=null,RI=0,SI=0;;)if(SI<RI){var Qia=QI.X(null,SI);$CLJS.oE(Qia,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pq,new $CLJS.h(null,1,[$CLJS.en,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.RD],null)],null)],null)],null)]));SI+=1}else{var TI=$CLJS.y(PI);if(TI){var UI=TI;if($CLJS.wd(UI)){var VI=$CLJS.jc(UI),Ria=$CLJS.kc(UI),
Sia=VI,Tia=$CLJS.D(VI);PI=Ria;QI=Sia;RI=Tia}else{var Uia=$CLJS.z(UI);$CLJS.oE(Uia,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pq,new $CLJS.h(null,1,[$CLJS.en,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.RD],null)],null)],null)],null)]));PI=$CLJS.B(UI);QI=null;RI=0}SI=0}else break}
for(var WI=$CLJS.y(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Cq,$CLJS.Eq,$CLJS.yq,$CLJS.Aq],null)),XI=null,YI=0,ZI=0;;)if(ZI<YI){var Via=XI.X(null,ZI);$CLJS.kE.l(FI($CLJS.Tg([new $CLJS.Q(null,2,5,$CLJS.R,[0,1],null)])),Via,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null)]));ZI+=1}else{var $I=$CLJS.y(WI);if($I){var aJ=$I;if($CLJS.wd(aJ)){var bJ=$CLJS.jc(aJ),Wia=$CLJS.kc(aJ),Xia=bJ,Yia=$CLJS.D(bJ);WI=Wia;XI=Xia;YI=Yia}else{var Zia=
$CLJS.z(aJ);$CLJS.kE.l(FI($CLJS.Tg([new $CLJS.Q(null,2,5,$CLJS.R,[0,1],null)])),Zia,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null)]));WI=$CLJS.B(aJ);XI=null;YI=0}ZI=0}else break}
$CLJS.kE.l(FI($CLJS.Tg([new $CLJS.Q(null,2,5,$CLJS.R,[0,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[0,1],null)])),$CLJS.BE,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null)]));
$CLJS.kE.l(FI($CLJS.Tg([new $CLJS.Q(null,2,5,$CLJS.R,[1,3],null),new $CLJS.Q(null,2,5,$CLJS.R,[1,5],null),new $CLJS.Q(null,2,5,$CLJS.R,[0,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[0,4],null)])),$CLJS.HE,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null)]));
for(var cJ=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AE,$CLJS.ME],null)),dJ=null,eJ=0,fJ=0;;)if(fJ<eJ){var $ia=dJ.X(null,fJ);$CLJS.mE($ia,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)]));fJ+=1}else{var gJ=$CLJS.y(cJ);if(gJ){var hJ=gJ;if($CLJS.wd(hJ)){var iJ=$CLJS.jc(hJ),aja=$CLJS.kc(hJ),bja=iJ,cja=$CLJS.D(iJ);cJ=aja;dJ=bja;eJ=cja}else{var dja=$CLJS.z(hJ);$CLJS.mE(dja,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)]));cJ=$CLJS.B(hJ);
dJ=null;eJ=0}fJ=0}else break}
for(var jJ=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CE,$CLJS.JE],null)),kJ=null,lJ=0,mJ=0;;)if(mJ<lJ){var eja=kJ.X(null,mJ);$CLJS.mE(eja,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.VD],null)]));mJ+=1}else{var nJ=$CLJS.y(jJ);if(nJ){var oJ=nJ;if($CLJS.wd(oJ)){var pJ=$CLJS.jc(oJ),fja=$CLJS.kc(oJ),gja=pJ,hja=$CLJS.D(pJ);jJ=fja;kJ=gja;lJ=hja}else{var ija=$CLJS.z(oJ);$CLJS.mE(ija,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.VD],null)]));jJ=$CLJS.B(oJ);
kJ=null;lJ=0}mJ=0}else break}
for(var qJ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hF,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null)],null),rJ=$CLJS.y(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.RE,$CLJS.kF,$CLJS.ZE,$CLJS.SE],null)),sJ=null,tJ=0,uJ=0;;)if(uJ<tJ){var vJ=sJ.X(null,uJ);$CLJS.zF.v(vJ,$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,vJ],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,$CLJS.mD,qJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,
$CLJS.WD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.WD],null)],null));uJ+=1}else{var wJ=$CLJS.y(rJ);if(wJ){var xJ=wJ;if($CLJS.wd(xJ)){var yJ=$CLJS.jc(xJ),jja=$CLJS.kc(xJ),kja=yJ,lja=$CLJS.D(yJ);rJ=jja;sJ=kja;tJ=lja}else{var zJ=$CLJS.z(xJ);$CLJS.zF.v(zJ,$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,zJ],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,$CLJS.mD,qJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.WD],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.W,$CLJS.WD],null)],null));rJ=$CLJS.B(xJ);sJ=null;tJ=0}uJ=0}else break}
$CLJS.zF.v($CLJS.VA,$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.gi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.VA],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,$CLJS.mD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Fk,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yq,$CLJS.Iu,$CLJS.Nw,$CLJS.GE],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.W,$CLJS.ZD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UC],null)],null));$CLJS.zF.v($CLJS.xE,$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.xE],null),$CLJS.mD,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.$C,$CLJS.hD],null)],null));
$CLJS.X($CLJS.nF,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.GI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TE,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.Yq,$CLJS.Fq,$CLJS.qF,$CLJS.HE,$CLJS.BE,$CLJS.Cq,$CLJS.yq,$CLJS.Eq,$CLJS.Aq,$CLJS.AE,$CLJS.ME,$CLJS.CE,$CLJS.JE,$CLJS.ZE,$CLJS.SE,$CLJS.RE,$CLJS.kF],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HI,$CLJS.Ei],null)],null));