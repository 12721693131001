var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var xX,Qna,Rna,Sna,Tna,zX,Vna,Wna,Xna,Yna,BX,CX,DX,FX,Zna,GX,HX,$na,aoa,boa,IX,Una,JX,KX,coa,MX;xX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Qna=function(){return function(){function a(k,l,m){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si.j?$CLJS.Si.j(k,l,m):$CLJS.Si.call(null,k,l,m),$CLJS.Kk.j?$CLJS.Kk.j(k,l,m):$CLJS.Kk.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si.g?$CLJS.Si.g(k,l):$CLJS.Si.call(null,k,l),$CLJS.Kk.g?$CLJS.Kk.g(k,l):$CLJS.Kk.call(null,k,l)],null)}function c(k){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si.h?$CLJS.Si.h(k):$CLJS.Si.call(null,k),$CLJS.Kk.h?$CLJS.Kk.h(k):$CLJS.Kk.call(null,
k)],null)}function d(){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si.o?$CLJS.Si.o():$CLJS.Si.call(null),$CLJS.Kk.o?$CLJS.Kk.o():$CLJS.Kk.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.P.M($CLJS.Si,m,t,u,v),$CLJS.P.M($CLJS.Kk,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.z(m);m=$CLJS.B(m);var u=$CLJS.z(m);m=$CLJS.B(m);var v=$CLJS.z(m);m=$CLJS.Ic(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};$CLJS.yX=function(a,b){return $CLJS.KK(a)(b,$CLJS.xf,$CLJS.xf)};Rna=function(a,b,c){return $CLJS.cb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.T.j(d,f,e)},null==b?a:$CLJS.T.j(a,$CLJS.PE,b),$CLJS.Qr(2,2,c))};Sna=function(a,b){var c=$CLJS.Bl(a,$CLJS.BP);if($CLJS.n(c)){var d=$CLJS.T.j;c=$CLJS.Db(c);b=$CLJS.as.g?$CLJS.as.g(c,b):$CLJS.as.call(null,c,b);a=d.call($CLJS.T,a,$CLJS.BP,b)}return a};
Tna=function(a){return $CLJS.bf.g($CLJS.GU(a),$CLJS.HU(a))};zX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.xr,null,$CLJS.Pk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.kt(2,c):$CLJS.kt(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?xX(2,c):xX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.pW.j(a,e,function(k){var l=$CLJS.T.j(k,f,null);return $CLJS.vd(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.dk.h($CLJS.Wk),l):$CLJS.td(k)?$CLJS.zz($CLJS.Ua,l):l}):$CLJS.Vj.g(a,f)};
Vna=function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return zX(b,Una,d)},a,Tna(a))};
Wna=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.kK.h(a),b);a:{var d=c;for(var e=$CLJS.xf;;){var f=$CLJS.z($CLJS.cf.g(Qna(),$CLJS.ff($CLJS.ck.j(AX,$CLJS.z,$CLJS.Kk),$CLJS.Br.h($CLJS.yX($CLJS.fV,d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=zX(d,f,k),m=$CLJS.Cz($CLJS.wx);if($CLJS.n($CLJS.Bz("metabase.lib.convert",m))){var t=$CLJS.rA.l($CLJS.H([$CLJS.qy,$CLJS.xh.l($CLJS.H([k]))])),u=$CLJS.rA,v=u.l,x=$CLJS.xh,A=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.PK($CLJS.yX($CLJS.fV,d));
$CLJS.Az("metabase.lib.convert",m,$CLJS.oV("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.qy,A.call(x,$CLJS.H([C]))])),$CLJS.rA.l($CLJS.H([$CLJS.fy,$CLJS.pV($CLJS.z($CLJS.Ss(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.Zd.g(e,new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null))}else break a}}d=Vna(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.kK.h(a))-1))return a;b+=1}else a=$CLJS.fk.M(a,$CLJS.kK,$CLJS.T,b,d)}};
Xna=function(a){var b=$CLJS.CV();return $CLJS.ek.g(function(c){return $CLJS.E.g($CLJS.LD.h(c),"__join")?$CLJS.fk.j(c,$CLJS.LD,b):c},a)};Yna=function(a){return"string"===typeof $CLJS.aO.h(a)?$CLJS.Vj.g($CLJS.T.j(a,$CLJS.IU,$CLJS.AV($CLJS.aO.h(a))),$CLJS.aO):a};BX=function(){return $CLJS.dk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.ie(b))?(b=$CLJS.ee(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};
CX=function(a){return $CLJS.Wf.j($CLJS.N,BX(),a)};DX=function(a){return $CLJS.Ce($CLJS.Wf.j($CLJS.N,$CLJS.ck.g(BX(),$CLJS.dk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.ni)})),a))};
FX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.cf.h($CLJS.EX),c);a=$CLJS.E.g(a,$CLJS.IE)&&$CLJS.B(c)?$CLJS.Zd.g($CLJS.nd(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.md(d)],null)):d;b=$CLJS.Ce(DX(b));return $CLJS.n(b)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oL,a,b],null):a};Zna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.ck.g($CLJS.cf.h(function(b){return $CLJS.uz(b,$CLJS.wA)}),$CLJS.cf.h($CLJS.EX)),$CLJS.wV.h(a))};
GX=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.kK);a=$CLJS.z($CLJS.cb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.R;var f=$CLJS.EX.h(c);d=$CLJS.n(d)?$CLJS.T.j(f,$CLJS.DP,d):f;d=$CLJS.n(e)?$CLJS.T.j(d,$CLJS.NN,Zna(e)):d;return new $CLJS.Q(null,2,5,b,[d,$CLJS.iU.h(c)],null)},null,a));return $CLJS.n($CLJS.eP.h(a))?$CLJS.tU(a,new $CLJS.h(null,1,[$CLJS.eP,$CLJS.vE],null)):a};
HX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.OV(a,b,$CLJS.ck.g($CLJS.EX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.OV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.gr],null),$CLJS.cf.h($CLJS.EX),e)}):d;return $CLJS.tU(a,$CLJS.Be([b,c]))};$na=function(a){var b=$CLJS.IU.h(a);return $CLJS.n(b)?$CLJS.T.j($CLJS.Vj.g(a,$CLJS.IU),$CLJS.aO,["card__",$CLJS.p.h(b)].join("")):a};
aoa=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);boa=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);IX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Una=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);JX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
KX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.LX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);coa=new $CLJS.M(null,"m","m",1632677161);MX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var AX,doa,eoa,foa,goa,hoa,ioa,joa,koa,loa,moa;$CLJS.NX=$CLJS.N;$CLJS.OX=$CLJS.N;AX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.cV,null,$CLJS.HD,null,$CLJS.xM,null,$CLJS.VO,null,$CLJS.OE,null,$CLJS.JQ,null,$CLJS.PE,null],null),null);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.Se($CLJS.N);foa=$CLJS.Se($CLJS.N);goa=$CLJS.Se($CLJS.N);hoa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.YA],null),$CLJS.Oi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.PX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.OA,hoa,doa,eoa,foa,goa);$CLJS.PX.m(null,$CLJS.Oh,function(a){if($CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.md(a);var c=$CLJS.td(b);a=c?$CLJS.nd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.jV($CLJS.Wf.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.PX),d))}else b=a;return b});$CLJS.PX.m(null,$CLJS.bV,function(a){return a});
$CLJS.PX.m(null,$CLJS.PU,function(a){var b=$CLJS.PX.h($CLJS.PE.h(a)),c=$CLJS.Ce($CLJS.ek.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.tV($CLJS.PX.h(t),u)},$CLJS.VO.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.oD);return new $CLJS.Q(null,
2,5,$CLJS.R,[u,t],null)}),b),f=$CLJS.OX,k=$CLJS.NX;$CLJS.OX=d;$CLJS.NX=e;try{var l=Rna(Yna(a),b,$CLJS.H([$CLJS.VO,c])),m=$CLJS.cb(function(t,u){return $CLJS.Ta($CLJS.J.g(t,u))?t:$CLJS.fk.j(t,u,$CLJS.PX)},l,$CLJS.Wj.l(AX,$CLJS.PE,$CLJS.H([$CLJS.VO])));return $CLJS.n($CLJS.xM.h(m))?$CLJS.fk.j(m,$CLJS.xM,Xna):m}finally{$CLJS.NX=k,$CLJS.OX=f}});$CLJS.PX.m(null,$CLJS.WU,function(a){return Sna(a,function(b){return $CLJS.OV(b,$CLJS.sE,$CLJS.PX)})});
$CLJS.PX.m(null,$CLJS.fK,function(a){a=$CLJS.fk.j($CLJS.fk.j(a,$CLJS.nK,$CLJS.PX),$CLJS.kK,$CLJS.PX);var b=$CLJS.n($CLJS.HD.h(a))?$CLJS.fk.j(a,$CLJS.HD,function(c){return $CLJS.hl(c)?$CLJS.ek.g($CLJS.PX,c):$CLJS.mh.h(c)}):a;return $CLJS.Ta($CLJS.LD.h(a))?$CLJS.T.j(b,$CLJS.LD,"__join"):b});$CLJS.PX.m(null,$CLJS.dA,function(a){return $CLJS.ek.g($CLJS.PX,a)});
$CLJS.PX.m(null,$CLJS.fA,function(a){return $CLJS.n($CLJS.Si.h(a))?Wna($CLJS.T.j($CLJS.fk.j($CLJS.xV(a),$CLJS.kK,function(b){return $CLJS.ek.g($CLJS.PX,b)}),$CLJS.LX,!0)):$CLJS.as(a,$CLJS.PX)});$CLJS.PX.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.td(b)?new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null):new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.jV(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,a,b],null))});
$CLJS.PX.m(null,$CLJS.ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.tU(a,new $CLJS.h(null,3,[$CLJS.UB,$CLJS.jA,$CLJS.zN,$CLJS.tj,$CLJS.RQ,$CLJS.jD],null));var c=$CLJS.T.j;var d=$CLJS.ni.h(a);$CLJS.n(d)||(d=$CLJS.jA.h(a),d=$CLJS.n(d)?d:$CLJS.CD(b));a=c.call($CLJS.T,a,$CLJS.ni,d);return $CLJS.jV(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ej,a,b],null))});
$CLJS.PX.m(null,$CLJS.IE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IE,$CLJS.Vj.g(c,$CLJS.Oh),$CLJS.ek.g($CLJS.PX,b)],null);b=$CLJS.jV(b);return null!=a?$CLJS.Zd.g(b,$CLJS.PX.h(a)):b});$CLJS.PX.m(null,$CLJS.gA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.jV(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,c],null))});
$CLJS.PX.m(null,$CLJS.PE,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.R;var f=$CLJS.J.g($CLJS.OX,c);if(!$CLJS.n(f))throw $CLJS.Th(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.UQ,a],null));return $CLJS.jV(new $CLJS.Q(null,3,5,e,[b,d,f],null))});
$CLJS.PX.m(null,$CLJS.oL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.PX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.uk.l($CLJS.H([c,a]))],null),d)});ioa=$CLJS.Se($CLJS.N);joa=$CLJS.Se($CLJS.N);koa=$CLJS.Se($CLJS.N);loa=$CLJS.Se($CLJS.N);moa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.YA],null),$CLJS.Oi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.EX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.OA,moa,ioa,joa,koa,loa);
$CLJS.EX.m(null,$CLJS.Oh,function(a){if($CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.td(a)){c=$CLJS.Wf.g;b=new $CLJS.Q(null,1,5,$CLJS.R,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.EX,d);a=DX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.Q(null,1,5,$CLJS.R,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[b],null),$CLJS.cf.g($CLJS.EX,$CLJS.be(a,d)))}else a=$CLJS.td(a)?$CLJS.as(CX(a),$CLJS.EX):
a;return a});for(var QX=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[MX,KX],null)),RX=null,SX=0,TX=0;;)if(TX<SX){var noa=RX.X(null,TX);$CLJS.fE(noa,JX);TX+=1}else{var UX=$CLJS.y(QX);if(UX){var VX=UX;if($CLJS.wd(VX)){var WX=$CLJS.jc(VX),ooa=$CLJS.kc(VX),poa=WX,qoa=$CLJS.D(WX);QX=ooa;RX=poa;SX=qoa}else{var roa=$CLJS.z(VX);$CLJS.fE(roa,JX);QX=$CLJS.B(VX);RX=null;SX=0}TX=0}else break}
for(var XX=$CLJS.y(new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.Jw,$CLJS.UE,$CLJS.iF,$CLJS.EE,$CLJS.Cj,$CLJS.mF,$CLJS.en,$CLJS.LE,$CLJS.gF,$CLJS.oF,$CLJS.lF,$CLJS.tF],null)),YX=null,ZX=0,$X=0;;)if($X<ZX){var soa=YX.X(null,$X);$CLJS.fE(soa,MX);$X+=1}else{var aY=$CLJS.y(XX);if(aY){var bY=aY;if($CLJS.wd(bY)){var cY=$CLJS.jc(bY),toa=$CLJS.kc(bY),uoa=cY,voa=$CLJS.D(cY);XX=toa;YX=uoa;ZX=voa}else{var woa=$CLJS.z(bY);$CLJS.fE(woa,MX);XX=$CLJS.B(bY);YX=null;ZX=0}$X=0}else break}
for(var dY=$CLJS.y($CLJS.tf([$CLJS.Hq,$CLJS.Tr,$CLJS.Iq,$CLJS.uF,$CLJS.IE,$CLJS.cF,$CLJS.zE,$CLJS.wF,$CLJS.qE,$CLJS.NE,$CLJS.uE,$CLJS.YE,$CLJS.eF,$CLJS.wE,$CLJS.QA,$CLJS.RA,$CLJS.xx,$CLJS.OH,$CLJS.TH,$CLJS.IH,$CLJS.SA,$CLJS.NH,$CLJS.GH,$CLJS.WH,$CLJS.aI,$CLJS.YH,$CLJS.MH,$CLJS.cI,$CLJS.TA,$CLJS.WA,$CLJS.yE,$CLJS.rE,$CLJS.xF,$CLJS.OG,$CLJS.bF,$CLJS.Hw,$CLJS.pF,$CLJS.rF,$CLJS.yF,$CLJS.ds,$CLJS.js],!0)),eY=null,fY=0,gY=0;;)if(gY<fY){var xoa=eY.X(null,gY);$CLJS.fE(xoa,KX);gY+=1}else{var hY=$CLJS.y(dY);
if(hY){var iY=hY;if($CLJS.wd(iY)){var jY=$CLJS.jc(iY),yoa=$CLJS.kc(iY),zoa=jY,Aoa=$CLJS.D(jY);dY=yoa;eY=zoa;fY=Aoa}else{var Boa=$CLJS.z(iY);$CLJS.fE(Boa,KX);dY=$CLJS.B(iY);eY=null;fY=0}gY=0}else break}$CLJS.EX.m(null,JX,function(a){return FX(a)});$CLJS.EX.m(null,$CLJS.fA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.ck.g(BX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.EX.h(b)],null)})),a)});
$CLJS.EX.m(null,$CLJS.PE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.td(b))try{var d=DX(b);b=$CLJS.R;var e=$CLJS.NX,f=$CLJS.J.j(e,c,IX);if($CLJS.E.g(f,IX))throw $CLJS.Th(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[coa,e,$CLJS.Nv,c],null));var k=new $CLJS.Q(null,2,5,b,[$CLJS.PE,f],null);return $CLJS.n(d)?$CLJS.Zd.g(k,d):k}catch(l){throw c=l,d=$CLJS.nU(c),d=$CLJS.ia.g?$CLJS.ia.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ia.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Sh(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.EX.m(null,$CLJS.dA,function(a){return $CLJS.ek.g($CLJS.EX,a)});
$CLJS.EX.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.td(b)?new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,$CLJS.EX.h(a),DX(b)],null)});
$CLJS.EX.m(null,$CLJS.ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=DX($CLJS.tU(b,new $CLJS.h(null,3,[$CLJS.jA,$CLJS.UB,$CLJS.tj,$CLJS.zN,$CLJS.jD,$CLJS.RQ],null)));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ej,a,b],null)});
$CLJS.EX.m(null,$CLJS.fK,function(a){var b=CX(a);a=0==$CLJS.LD.h(a).lastIndexOf("__join",0)?$CLJS.Vj.g(b,$CLJS.LD):b;return $CLJS.uk.l($CLJS.H([$CLJS.as($CLJS.Vj.l(a,$CLJS.kK,$CLJS.H([$CLJS.nK])),$CLJS.EX),HX($CLJS.Al(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.nK],null)),$CLJS.nK,$CLJS.LQ),GX(a)]))});
$CLJS.EX.m(null,$CLJS.PU,function(a){var b=$CLJS.PE.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null)}),b);var d=$CLJS.OX,e=$CLJS.NX;$CLJS.OX=c;$CLJS.NX=b;try{return $CLJS.cb(function(f,k){return $CLJS.OV(f,
k,$CLJS.EX)},HX($CLJS.OV($CLJS.OV($na(CX(a)),$CLJS.PE,function(f){return $CLJS.ek.g(FX,f)}),$CLJS.VO,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.je(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.jc(t),v=$CLJS.D(u),x=$CLJS.me(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A),G=$CLJS.EX.h(C);C=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sV(C),$CLJS.E.g($CLJS.ej,$CLJS.z(G))?$CLJS.ed(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.pe($CLJS.re(x),
m($CLJS.kc(t))):$CLJS.pe($CLJS.re(x),null)}x=$CLJS.z(t);u=$CLJS.EX.h(x);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sV(x),$CLJS.E.g($CLJS.ej,$CLJS.z(u))?$CLJS.ed(u):u],null),m($CLJS.Ic(t)))}return null}},null,null)}(f)}())}),$CLJS.cV,$CLJS.DE),$CLJS.Wj.l(AX,$CLJS.PE,$CLJS.H([$CLJS.cV,$CLJS.VO])))}finally{$CLJS.NX=e,$CLJS.OX=d}});$CLJS.EX.m(null,$CLJS.WU,function(a){return $CLJS.as(CX(a),$CLJS.EX)});
$CLJS.EX.m(null,$CLJS.bV,function(a){try{var b=CX(a),c=$CLJS.oM.h(b),d=GX(b),e=$CLJS.E.g($CLJS.fj.h($CLJS.fd($CLJS.kK.h(a))),$CLJS.WU)?$CLJS.eP:$CLJS.vE;return $CLJS.uk.l($CLJS.H([$CLJS.as($CLJS.Vj.l(b,$CLJS.kK,$CLJS.H([$CLJS.oM,$CLJS.LX])),$CLJS.EX),function(){var k=$CLJS.Be([$CLJS.Si,e,e,d]);return $CLJS.y(c)?$CLJS.T.j(k,$CLJS.oM,c):k}()]))}catch(k){var f=k;throw $CLJS.Sh(function(){var l=$CLJS.nU(f);return $CLJS.ia.g?$CLJS.ia.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ia.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.vE,a],null),f);}});
$CLJS.kY=function(){function a(d,e,f){f=$CLJS.wX(null,$CLJS.Dy(f,$CLJS.H([$CLJS.ii,!0])));var k=$CLJS.zV(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.PE);k=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[x,v],null)}),l);var m=
$CLJS.OX,t=$CLJS.NX;$CLJS.OX=k;$CLJS.NX=l;try{try{return $CLJS.PX.h(f)}catch(v){var u=v;throw $CLJS.Sh(function(){var x=$CLJS.nU(u);return $CLJS.ia.g?$CLJS.ia.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ia.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.vE,d,$CLJS.yK,e,boa,f,aoa,$CLJS.OX],null),u);}}finally{$CLJS.NX=t,$CLJS.OX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();