var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.reader.js");
'use strict';var cz=function(a,b,c,d,e){this.ab=a;this.ra=b;this.K=c;this.Na=d;this.J=e;this.C=2565220111;this.I=0},dz=new $CLJS.M("tailrecursion.priority-map","not-found","tailrecursion.priority-map/not-found",-436727517);$CLJS.g=cz.prototype;$CLJS.g.na=function(a,b){return $CLJS.J.g(this.ra,b)};$CLJS.g.$=function(a,b,c){return $CLJS.J.j(this.ra,b,c)};$CLJS.g.da=function(a,b,c){return $CLJS.ih(b,function(d){return $CLJS.ih(b,$CLJS.lh,""," ","",c,d)},"#tailrecursion.priority-map {",", ","}",c,this)};$CLJS.g.O=function(){return this.K};$CLJS.g.ha=function(){return $CLJS.D(this.ra)};
$CLJS.g.pc=function(){if(0===$CLJS.D(this.ra))return null;var a=$CLJS.z(this.ab),b=$CLJS.z($CLJS.Db(a));return $CLJS.n(this.Na)?new $CLJS.Q(null,2,5,$CLJS.R,[b,this.ra.h?this.ra.h(b):this.ra.call(null,b)],null):new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.Cb(a)],null)};
$CLJS.g.qc=function(){if(0===$CLJS.D(this.ra))throw Error("Can't pop empty priority map");var a=$CLJS.z(this.ab),b=$CLJS.Db(a),c=$CLJS.z(b);a=$CLJS.Cb(a);return $CLJS.E.g($CLJS.D(b),1)?new cz($CLJS.Vj.g(this.ab,a),$CLJS.Vj.g(this.ra,c),this.K,this.Na,null):new cz($CLJS.T.j(this.ab,a,$CLJS.Wj.g(b,c)),$CLJS.Vj.g(this.ra,c),this.K,this.Na,null)};
$CLJS.g.oc=function(){var a=this,b=this;return $CLJS.n(a.Na)?$CLJS.y(function(){return function e(d){return new $CLJS.je(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,A,C,G,K,S){return function ha(ba){return new $CLJS.je(null,function(){return function(){for(;;){var ra=$CLJS.y(ba);if(ra){if($CLJS.wd(ra)){var Ra=$CLJS.jc(ra),Ab=$CLJS.D(Ra),Pa=$CLJS.me(Ab);a:for(var Wa=0;;)if(Wa<Ab){var ib=$CLJS.hd(Ra,Wa);
ib=new $CLJS.Q(null,2,5,$CLJS.R,[ib,a.ra.h?a.ra.h(ib):a.ra.call(null,ib)],null);Pa.add(ib);Wa+=1}else{Ra=!0;break a}return Ra?$CLJS.pe($CLJS.re(Pa),ha($CLJS.kc(ra))):$CLJS.pe($CLJS.re(Pa),null)}Pa=$CLJS.z(ra);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[Pa,a.ra.h?a.ra.h(Pa):a.ra.call(null,Pa)],null),ha($CLJS.Ic(ra)))}return null}}}(v,x,A,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.bf.g(k,e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null}},null,null)}($CLJS.Yb(a.ab))}()):$CLJS.y(function(){return function e(d){return new $CLJS.je(null,
function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,A,C,G,K,S){return function ha(ba){return new $CLJS.je(null,function(ra,Ra,Ab){return function(){for(;;){var Pa=$CLJS.y(ba);if(Pa){if($CLJS.wd(Pa)){var Wa=$CLJS.jc(Pa),ib=$CLJS.D(Wa),Na=$CLJS.me(ib);a:for(var ob=0;;)if(ob<ib){var Ja=$CLJS.hd(Wa,ob);Na.add(new $CLJS.Q(null,2,5,$CLJS.R,[Ja,Ab],null));ob+=1}else{Wa=!0;break a}return Wa?$CLJS.pe($CLJS.re(Na),ha($CLJS.kc(Pa))):
$CLJS.pe($CLJS.re(Na),null)}Na=$CLJS.z(Pa);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[Na,Ab],null),ha($CLJS.Ic(Pa)))}return null}}}(v,x,A,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.bf.g(k,e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null}},null,null)}($CLJS.Yb(a.ab))}())};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=$CLJS.Nc(this)};$CLJS.g.V=function(a,b){return $CLJS.Qb(this.ra,b)};$CLJS.g.oa=function(){return $CLJS.kd($CLJS.ez,this.K)};
$CLJS.g.Gb=function(a,b){a=this.ra.g?this.ra.g(b,dz):this.ra.call(null,b,dz);if($CLJS.E.g(a,dz))return this;a=this.Na.h?this.Na.h(a):this.Na.call(null,a);var c=this.ab.h?this.ab.h(a):this.ab.call(null,a);return $CLJS.E.g($CLJS.D(c),1)?new cz($CLJS.Vj.g(this.ab,a),$CLJS.Vj.g(this.ra,b),this.K,this.Na,null):new cz($CLJS.T.j(this.ab,a,$CLJS.Wj.g(c,b)),$CLJS.Vj.g(this.ra,b),this.K,this.Na,null)};
$CLJS.g.ma=function(a,b,c){var d=$CLJS.J.j(this.ra,b,null);if($CLJS.n(d)){if($CLJS.E.g(d,c))return this;a=this.Na.h?this.Na.h(c):this.Na.call(null,c);d=this.Na.h?this.Na.h(d):this.Na.call(null,d);var e=$CLJS.J.g(this.ab,d);return $CLJS.E.g($CLJS.D(e),1)?new cz($CLJS.T.j($CLJS.Vj.g(this.ab,d),a,$CLJS.Zd.g($CLJS.J.j(this.ab,a,$CLJS.Sg),b)),$CLJS.T.j(this.ra,b,c),this.K,this.Na,null):new cz($CLJS.T.l(this.ab,d,$CLJS.Wj.g($CLJS.J.g(this.ab,d),b),$CLJS.H([a,$CLJS.Zd.g($CLJS.J.j(this.ab,a,$CLJS.Sg),b)])),
$CLJS.T.j(this.ra,b,c),this.K,this.Na,null)}a=this.Na.h?this.Na.h(c):this.Na.call(null,c);return new cz($CLJS.T.j(this.ab,a,$CLJS.Zd.g($CLJS.J.j(this.ab,a,$CLJS.Sg),b)),$CLJS.T.j(this.ra,b,c),this.K,this.Na,null)};$CLJS.g.Va=function(a,b){return $CLJS.Dd(this.ra,b)};
$CLJS.g.ga=function(){var a=this,b=this;return $CLJS.n(a.Na)?$CLJS.y(function(){return function e(d){return new $CLJS.je(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,A,C,G,K,S){return function ha(ba){return new $CLJS.je(null,function(){return function(){for(;;){var ra=$CLJS.y(ba);if(ra){if($CLJS.wd(ra)){var Ra=$CLJS.jc(ra),Ab=$CLJS.D(Ra),Pa=$CLJS.me(Ab);a:for(var Wa=0;;)if(Wa<Ab){var ib=$CLJS.hd(Ra,Wa);
ib=new $CLJS.Q(null,2,5,$CLJS.R,[ib,a.ra.h?a.ra.h(ib):a.ra.call(null,ib)],null);Pa.add(ib);Wa+=1}else{Ra=!0;break a}return Ra?$CLJS.pe($CLJS.re(Pa),ha($CLJS.kc(ra))):$CLJS.pe($CLJS.re(Pa),null)}Pa=$CLJS.z(ra);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[Pa,a.ra.h?a.ra.h(Pa):a.ra.call(null,Pa)],null),ha($CLJS.Ic(ra)))}return null}}}(v,x,A,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.bf.g(k,e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null}},null,null)}(a.ab)}()):$CLJS.y(function(){return function e(d){return new $CLJS.je(null,
function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,A,C,G,K,S){return function ha(ba){return new $CLJS.je(null,function(ra,Ra,Ab){return function(){for(;;){var Pa=$CLJS.y(ba);if(Pa){if($CLJS.wd(Pa)){var Wa=$CLJS.jc(Pa),ib=$CLJS.D(Wa),Na=$CLJS.me(ib);a:for(var ob=0;;)if(ob<ib){var Ja=$CLJS.hd(Wa,ob);Na.add(new $CLJS.Q(null,2,5,$CLJS.R,[Ja,Ab],null));ob+=1}else{Wa=!0;break a}return Wa?$CLJS.pe($CLJS.re(Na),ha($CLJS.kc(Pa))):
$CLJS.pe($CLJS.re(Na),null)}Na=$CLJS.z(Pa);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[Na,Ab],null),ha($CLJS.Ic(Pa)))}return null}}}(v,x,A,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.bf.g(k,e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null}},null,null)}(a.ab)}())};$CLJS.g.P=function(a,b){return new cz(this.ab,this.ra,b,this.Na,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.vd(b)?this.ma(null,$CLJS.hd(b,0),$CLJS.hd(b,1)):$CLJS.cb($CLJS.mb,this,b)};
$CLJS.g.call=function(a){switch(arguments.length-1){case 1:return this.h(arguments[1]);case 2:return this.g(arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length-1)].join(""));}};$CLJS.g.apply=function(a,b){return this.call.apply(this,[this].concat($CLJS.bb(b)))};$CLJS.g.h=function(a){return this.na(null,a)};$CLJS.g.g=function(a,b){return this.$(null,a,b)};
$CLJS.ez=new cz(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;a:for(b=$CLJS.y(0<b.length?new $CLJS.w(b.slice(0),0,null):null),d=$CLJS.sk;;)if(b)c=$CLJS.B($CLJS.B(b)),d=$CLJS.T.j(d,$CLJS.z(b),$CLJS.ed(b)),b=c;else break a;return d}(),$CLJS.N,$CLJS.N,$CLJS.Qd,null);$CLJS.J.g($CLJS.q($CLJS.bz),"tailrecursion.priority-map");
$CLJS.zh.v($CLJS.bz,$CLJS.T,"tailrecursion.priority-map",function(a){if($CLJS.td(a))return $CLJS.Wf.g($CLJS.ez,a);throw Error("Priority map literal expects a map for its elements.");});