var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var lY,Coa,Doa,Eoa,Foa,sY,Goa,Hoa,tY,Ioa,vY,Joa,Koa,Loa,AY,BY,Moa,Noa,IY,Ooa,Poa,Qoa,NY,Roa;lY=function(){};$CLJS.mY=function(a){return null!=a?$CLJS.zc===a.ni?!0:a.Wc?!1:$CLJS.Ya(lY,a):$CLJS.Ya(lY,a)};Coa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.nY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Doa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Eoa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.oY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.pY=new $CLJS.M(null,"database-id","database-id",1883826326);Foa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.qY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.rY=new $CLJS.M(null,"search","search",1564939822);sY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Goa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Hoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
tY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Ioa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.uY=new $CLJS.M(null,"dataset","dataset",1159262238);vY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Joa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);
$CLJS.wY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.xY=new $CLJS.M(null,"definition","definition",-1198729982);Koa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.yY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Loa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.zY=new $CLJS.M("source","joins","source/joins",1225821486);
AY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);BY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.CY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.DY=new $CLJS.M(null,"selected?","selected?",-742502788);Moa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Noa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.EY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.FY=new $CLJS.M(null,"active","active",1895962068);$CLJS.GY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.HY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);IY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);
Ooa=new $CLJS.M(null,"details","details",1956795411);$CLJS.JY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.KY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);Poa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.LY=new $CLJS.M("source","native","source/native",-1444604147);Qoa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);
$CLJS.MY=new $CLJS.M(null,"auto-list","auto-list",314197731);NY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.OY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Roa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);$CLJS.PY=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.QY=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(AY,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Yq,$CLJS.EY,$CLJS.LY,$CLJS.oY,$CLJS.qY,$CLJS.JY,$CLJS.dK,$CLJS.VJ,$CLJS.zY,$CLJS.wY,$CLJS.yY],null));$CLJS.X(tY,$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Yq],null),$CLJS.sz($CLJS.Ed,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.MY,null,$CLJS.rY,null,$CLJS.dj,null,$CLJS.Vy,null],null),null))));
$CLJS.X(IY,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.cK],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gi,$CLJS.LJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MJ,$CLJS.aK],null)],null));
$CLJS.X(sY,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.XJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gi,$CLJS.LJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,$CLJS.Xh],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IJ,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,$CLJS.Xh],null)],null)],null));
$CLJS.X($CLJS.KJ,new $CLJS.Q(null,20,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,"Valid column metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.BJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.wj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jA,$CLJS.qD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Gi,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.aK],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.$q,$CLJS.wj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ni,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.qD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.aK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.aK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PY,
new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.hD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.hD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,AY],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OY,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.NJ],
null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nY,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.hD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CY,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,new $CLJS.h(null,2,[$CLJS.en,1,$CLJS.Cj,60],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DY,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.KY,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,tY],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,IY],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,sY],null)],null)],
null)],null));$CLJS.X(NY,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RJ,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[Joa,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[Ioa,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jA,$CLJS.qD],null)],null)],null)],null)],null)],null));
$CLJS.X(vY,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FY,$CLJS.qr],null),new $CLJS.Q(null,2,5,$CLJS.R,[Koa,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RJ,$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xY,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,NY],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,
$CLJS.hD],null)],null)],null));
$CLJS.X(Poa,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,"Valid Card metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.bK],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gi,$CLJS.NJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pY,$CLJS.OJ],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GY,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.Bj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HY,
new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,$CLJS.Bj],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uY,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.GJ],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Eoa,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.W,vY],null)],null)],null)],null));
$CLJS.X(Moa,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,"Valid Segment metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.FJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gi,$CLJS.EJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TJ,$CLJS.GJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xY,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.Bj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.hD],null)],null)],null));
$CLJS.X(Noa,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,"Valid Metric metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.HJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gi,$CLJS.CJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TJ,$CLJS.GJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xY,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.Bj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.hD],null)],null)],null));
$CLJS.X(Qoa,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,"Valid Table metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.JJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gi,$CLJS.GJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.hD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Nk,new $CLJS.h(null,
1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.hD],null)],null)],null));
$CLJS.X(Doa,new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,"Valid Database metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.ZJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gi,$CLJS.OJ],null),new $CLJS.Q(null,3,5,$CLJS.R,[Hoa,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.Bj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Ooa,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.Bj],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.QY,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.Ei],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wy,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,$CLJS.Ei],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Loa,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.Bj],null)],null)],null));
$CLJS.X(BY,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"Valid MetadataProvider"],null),new $CLJS.Dc(function(){return $CLJS.mY},Foa,$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[Coa,Roa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Dp],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.mY)?$CLJS.mY.H:null]))],null));
$CLJS.X(Goa,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,BY],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,BY],null)],null)],null)],null));