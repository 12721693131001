var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var kG,lG,nG,oG,yha,zha,Aha,pG,mG;$CLJS.iG=function(a,b){return $CLJS.ec($CLJS.cb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Zd.g($CLJS.J.j(c,e,$CLJS.xf),d))},$CLJS.bc($CLJS.N),b))};$CLJS.jG=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Pc(d):null},null,b)};
kG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.tz(b,$CLJS.Ak)?$CLJS.UF:$CLJS.tz(b,$CLJS.Jk)?$CLJS.TC:$CLJS.tz(b,$CLJS.Dk)?$CLJS.UC:null;return $CLJS.n(b)?$CLJS.eE(b,a):!0};
lG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.iG(function(d){return $CLJS.tz($CLJS.CD(d),$CLJS.SB)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.bk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.CD($CLJS.z(a));return $CLJS.Ne(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(kG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
nG=function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ns,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.mD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,mG],null)],
null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,mG],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ys,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.ej);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(lG(b))].join("")}],null),$CLJS.Oe(lG)],null)],null)};
oG=function(a){return new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.hj,new $CLJS.h(null,1,[$CLJS.ns,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.mD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pq,new $CLJS.h(null,1,[$CLJS.en,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)],null)],null)};
yha=function(a){return $CLJS.Nd($CLJS.hE,$CLJS.cf.g(function(b){var c=$CLJS.CD(b),d=$CLJS.tz(c,$CLJS.OD);b=d?$CLJS.eE($CLJS.dF,b):d;return $CLJS.n(b)?$CLJS.pj:c},a))};zha=function(a){a=$CLJS.jG(function(b){return!$CLJS.tz(b,$CLJS.SB)},$CLJS.cf.g($CLJS.CD,a));return $CLJS.tz(a,$CLJS.OD)?$CLJS.uj:a};
Aha=function(a,b){return $CLJS.n($CLJS.Ne(function(c){return $CLJS.tz($CLJS.CD(c),$CLJS.SB)},b))?zha(b):$CLJS.E.g(a,$CLJS.Tr)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Me(function(c){return $CLJS.tz($CLJS.CD(c),$CLJS.Ak)},b)||$CLJS.Me(function(c){return $CLJS.tz($CLJS.CD(c),$CLJS.Dk)},b))?$CLJS.SB:yha(b)};pG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.qG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);mG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ri,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ID,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hq,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.W,mG],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.CD(a);return $CLJS.Me(function(d){return kG(d,c)},b)}],null)],null));
$CLJS.X(pG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pq,new $CLJS.h(null,1,[$CLJS.en,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)],null));
var Bha=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.hj,new $CLJS.h(null,1,[$CLJS.ns,":- clause taking the difference of two temporal expressions"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.Tr],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.mD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null)],
null);$CLJS.zF.g($CLJS.Hq,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,nG($CLJS.Hq),oG($CLJS.Hq)],null));$CLJS.zF.g($CLJS.Tr,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ar,nG($CLJS.Tr),Bha,oG($CLJS.Tr)],null));$CLJS.oE($CLJS.Iq,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Er,pG],null)]));$CLJS.oE($CLJS.uF,$CLJS.H([$CLJS.Tr,$CLJS.BC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Er,pG],null)]));
for(var rG=$CLJS.y(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Hq,$CLJS.Tr,$CLJS.Iq],null)),sG=null,tG=0,uG=0;;)if(uG<tG){var Cha=sG.X(null,uG);$CLJS.fE(Cha,$CLJS.qG);uG+=1}else{var vG=$CLJS.y(rG);if(vG){var wG=vG;if($CLJS.wd(wG)){var xG=$CLJS.jc(wG),Dha=$CLJS.kc(wG),Eha=xG,Fha=$CLJS.D(xG);rG=Dha;sG=Eha;tG=Fha}else{var Gha=$CLJS.z(wG);$CLJS.fE(Gha,$CLJS.qG);rG=$CLJS.B(wG);sG=null;tG=0}uG=0}else break}
$CLJS.BD.m(null,$CLJS.qG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return Aha(a,b)});$CLJS.mE($CLJS.zE,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));$CLJS.fE($CLJS.zE,$CLJS.SD);
for(var yG=$CLJS.y(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wF,$CLJS.qE,$CLJS.NE],null)),zG=null,AG=0,BG=0;;)if(BG<AG){var Hha=zG.X(null,BG);$CLJS.mE(Hha,$CLJS.H([$CLJS.Tr,$CLJS.BC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));BG+=1}else{var CG=$CLJS.y(yG);if(CG){var DG=CG;if($CLJS.wd(DG)){var EG=$CLJS.jc(DG),Iha=$CLJS.kc(DG),Jha=EG,Kha=$CLJS.D(EG);yG=Iha;zG=Jha;AG=Kha}else{var Lha=$CLJS.z(DG);$CLJS.mE(Lha,$CLJS.H([$CLJS.Tr,$CLJS.BC,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));yG=$CLJS.B(DG);zG=null;AG=0}BG=0}else break}
for(var FG=$CLJS.y(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uE,$CLJS.YE,$CLJS.eF],null)),GG=null,HG=0,IG=0;;)if(IG<HG){var Mha=GG.X(null,IG);$CLJS.mE(Mha,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));IG+=1}else{var JG=$CLJS.y(FG);if(JG){var KG=JG;if($CLJS.wd(KG)){var LG=$CLJS.jc(KG),Nha=$CLJS.kc(KG),Oha=LG,Pha=$CLJS.D(LG);FG=Nha;GG=Oha;HG=Pha}else{var Qha=$CLJS.z(KG);$CLJS.mE(Qha,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));FG=$CLJS.B(KG);GG=null;HG=0}IG=0}else break}$CLJS.mE($CLJS.wE,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));
$CLJS.BD.m(null,$CLJS.wE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.tz($CLJS.CD(b),$CLJS.Zi)&&$CLJS.tz($CLJS.CD(a),$CLJS.Zi)?$CLJS.Zi:$CLJS.BC});