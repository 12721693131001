var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var AD,FD,Pga,Qga,Rga,Sga,Tga,Uga;
$CLJS.yD=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.Xy([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.zD=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.YC(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};AD=function(a){return $CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M};
$CLJS.CD=function(a){var b=function(){var c=AD(a);return c?(c=$CLJS.td($CLJS.ed(a)))?(c=$CLJS.ni.h($CLJS.ed(a)),$CLJS.n(c)?c:$CLJS.jA.h($CLJS.ed(a))):c:c}();return $CLJS.n(b)?b:$CLJS.BD.h(a)};
FD=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,2,[$CLJS.ns,"valid MBQL clause",$CLJS.ys,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.ej);return["invalid MBQL clause: ",$CLJS.xh.l($CLJS.H([c]))].join("")}],null),$CLJS.Oe(AD)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.DD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,b],null),function(c){c=$CLJS.CD(c);
return ED(c,a)}],null)],null)};$CLJS.GD=new $CLJS.M(null,"right-join","right-join",-56349359);Pga=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Qga=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Rga=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.HD=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.ID=new $CLJS.M(null,"expr","expr",745722291);$CLJS.JD=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.KD=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.LD=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.MD=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.ND=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.OD=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.PD=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Sga=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.QD=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.RD=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.SD=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.TD=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Tga=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
Uga=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.UD=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.VD=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.DD=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.WD=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.XD=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.YD=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.ZD=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.BD=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.YA],null),$CLJS.Oi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.OA(f);return $CLJS.E.g(k,$CLJS.iA)?$CLJS.Za(f):k},e,a,b,c,d)}();
$CLJS.X(Qga,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.OD],null),$CLJS.qD],null));$CLJS.BD.m(null,$CLJS.Oh,function(a){throw $CLJS.Th($CLJS.YC("{0}: Don''t know how to determine the type of {1}",$CLJS.H([Tga,$CLJS.xh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.ID,a],null));});$CLJS.BD.m(null,$CLJS.SD,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.CD(a)});
var ED=function ED(a,b){return $CLJS.rd(a)?$CLJS.Ne(function(d){return ED.g?ED.g(d,b):ED.call(null,d,b)},a):$CLJS.rd(b)?$CLJS.Ne(function(d){return ED.g?ED.g(a,d):ED.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.OD)?!0:$CLJS.tz(a,b)};$CLJS.X($CLJS.MD,FD($CLJS.nj,"expression returning a boolean"));$CLJS.X($CLJS.WD,FD($CLJS.yj,"expression returning a string"));$CLJS.X($CLJS.ZD,FD($CLJS.Zi,"expression returning an integer"));$CLJS.X(Rga,FD($CLJS.BC,"expression returning a non-integer real number"));
$CLJS.X($CLJS.YD,FD($CLJS.pj,"expression returning a number"));$CLJS.X(Sga,FD($CLJS.Ak,"expression returning a date"));$CLJS.X(Uga,FD($CLJS.Jk,"expression returning a time"));$CLJS.X(Pga,FD($CLJS.Dk,"expression returning a date time"));$CLJS.X($CLJS.KD,FD($CLJS.uj,"expression returning a date, time, or date time"));$CLJS.$D=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.pj,null,$CLJS.yj,null,$CLJS.uj,null,$CLJS.nj,null],null),null);$CLJS.X($CLJS.TD,FD($CLJS.$D,"an expression that can be compared with :\x3e or :\x3c"));
var Vga=new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.sB,null,$CLJS.pj,null,$CLJS.yj,null,$CLJS.XB,null,$CLJS.uj,null,$CLJS.cj,null,$CLJS.nj,null,$CLJS.zC,null],null),null);$CLJS.X($CLJS.VD,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.WD],null),FD($CLJS.XB,"expression returning a BSONID")],null));$CLJS.X($CLJS.RD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,FD(Vga,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.X($CLJS.ND,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,FD($CLJS.cj,"any type of expression")],null));
$CLJS.X($CLJS.JD,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.en,1],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.Xh,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gD,$CLJS.wj],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Iq,$CLJS.Xh],null)],null)],null)],null));