var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./metabase.util.malli.registry.js");
'use strict';var CU,EU,FU,zma,JU,KU,LU,Bma,MU,NU,OU,QU,RU,SU,TU,UU,VU,XU,YU,ZU,$U,aV,dV,eV,Ama;CU=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.dk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.ie(c)}),a)};
$CLJS.DU=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.P.g($CLJS.oB,function(){return function e(d){return new $CLJS.je(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v);$CLJS.qe(t,$CLJS.xD(x,function(){return function(A){return $CLJS.Vj.l(CU(A),$CLJS.jA,$CLJS.H([$CLJS.ni]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.pe($CLJS.re(t),e($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),
null)}var u=$CLJS.z(k);return $CLJS.be($CLJS.xD(u,function(){return function(v){return $CLJS.Vj.l(CU(v),$CLJS.jA,$CLJS.H([$CLJS.ni]))}}(u,k,f,b)),e($CLJS.Ic(k)))}return null}},null,null)}(a)}())};
EU=function(a,b){a=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xf,a],null)],null);for(var c=$CLJS.xf;;){var d=$CLJS.md(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.nd(a);a=function(t,u,v,x,A,C,G,K){return function(S){return $CLJS.Wf.j(v,$CLJS.cf.h(function(Y,ba,ha,ra,Ra){return function(Ab){var Pa=$CLJS.I(Ab,0,null);Ab=$CLJS.I(Ab,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zd.g(Ra,Pa),Ab],null)}}(t,u,v,x,A,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.Zd.g(c,l)):a=$CLJS.td(k)?a(k):$CLJS.sd(k)?a($CLJS.zl($CLJS.tr,k)):m}else return c}};FU=function(a,b,c){return $CLJS.vd(c)&&$CLJS.E.g(a,$CLJS.z(c))&&!$CLJS.Dd(b,$CLJS.J.g(c,2))};$CLJS.GU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.ck.g($CLJS.gD,$CLJS.ed)),$CLJS.VO.h(a));return EU($CLJS.Vj.l(a,$CLJS.xM,$CLJS.H([$CLJS.iU])),function(c){return FU($CLJS.gA,b,c)})};
$CLJS.HU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.ck.g($CLJS.oD,$CLJS.ed)),$CLJS.PE.h(a));return EU($CLJS.Vj.l(a,$CLJS.xM,$CLJS.H([$CLJS.iU])),function(c){return FU($CLJS.PE,b,c)})};zma=function(a){function b(d){return $CLJS.df(c,$CLJS.H([$CLJS.xM.h(d)]))}function c(d){return $CLJS.be($CLJS.LD.h(d),$CLJS.df(b,$CLJS.H([$CLJS.kK.h(d)])))}return $CLJS.n($CLJS.IU.h(a))?$CLJS.Pe(!0):$CLJS.Wg(b(a))};
JU=function(a){if($CLJS.sd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.y(a),d=$CLJS.z(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.z(v),A=$CLJS.B(v),C=x,G=A,K=$CLJS.pz(m,zma(C));f=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ua,function(S,Y,ba,ha,ra,Ra){return function ib(Pa,Wa){try{if($CLJS.vd(Wa)&&3===$CLJS.D(Wa))try{var Na=$CLJS.F(Wa,0);if($CLJS.de(Na,$CLJS.$E))try{var ob=$CLJS.F(Wa,1);if($CLJS.n($CLJS.pO.h(ob)))try{var Ja=$CLJS.F(Wa,1);if(null!=Ja?Ja.C&256||$CLJS.zc===Ja.mf||(Ja.C?0:$CLJS.Ya($CLJS.ub,
Ja)):$CLJS.Ya($CLJS.ub,Ja))try{var cc=$CLJS.J.j(Ja,$CLJS.pO,$CLJS.xU);if($CLJS.n($CLJS.Oe(ha)(cc))){var Md=$CLJS.J.g(Ja,$CLJS.pO);$CLJS.F(Wa,2);return new $CLJS.Q(null,1,5,$CLJS.R,[["Invalid :field reference in stage ",$CLJS.p.h(Ra),": no join named ",$CLJS.xh.l($CLJS.H([Md]))].join("")],null)}throw $CLJS.Z;}catch(od){if(od instanceof Error){var Id=od;if(Id===$CLJS.Z)throw $CLJS.Z;throw Id;}throw od;}else throw $CLJS.Z;}catch(od){if(od instanceof Error){Id=od;if(Id===$CLJS.Z)throw $CLJS.Z;throw Id;
}throw od;}else throw $CLJS.Z;}catch(od){if(od instanceof Error){Id=od;if(Id===$CLJS.Z)throw $CLJS.Z;throw Id;}throw od;}else throw $CLJS.Z;}catch(od){if(od instanceof Error){Id=od;if(Id===$CLJS.Z)throw $CLJS.Z;throw Id;}throw od;}else throw $CLJS.Z;}catch(od){if(od instanceof Error){Id=od;if(Id===$CLJS.Z)return $CLJS.uU(ib,Pa,Wa);throw Id;}throw od;}}}(f,k,l,K,m,t,u,v,x,A,C,G,b,0,a,a,c,d,e,d,e)($CLJS.xf,$CLJS.Vj.l(C,$CLJS.xM,$CLJS.H([Ama]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};KU=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);LU=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Bma=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);MU=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
NU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);OU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.PU=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);QU=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
RU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);SU=new $CLJS.r(null,"refs","refs",80480079,null);TU=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);UU=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);VU=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.WU=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);XU=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);YU=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);ZU=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);$U=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
aV=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.bV=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.IU=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.cV=new $CLJS.M(null,"filters","filters",974726919);dV=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);eV=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.fV=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Ama=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(TU,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.WU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eP,$CLJS.Ua],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Er,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,$CLJS.Zk],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BP,new $CLJS.h(null,1,[$CLJS.ur,
!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.aU],null)],null)],null));$CLJS.X(YU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.dF],null));
$CLJS.X(KU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.en,1],null),YU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"Breakouts must be distinct"],null),new $CLJS.Dc(function(){return $CLJS.DU},LU,$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.mU,MU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.Q(null,1,5,$CLJS.R,[SU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.DU)?$CLJS.DU.H:null]))],null)],null));
$CLJS.X(ZU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.en,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.dF],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,":fields must be distinct"],null),new $CLJS.Dc(function(){return $CLJS.DU},LU,$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.mU,MU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.Q(null,
1,5,$CLJS.R,[SU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.DU)?$CLJS.DU.H:null]))],null)],null));$CLJS.X($U,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.MD],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.xE],null),$CLJS.Bj,$CLJS.wj],null)],null));
$CLJS.X(XU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.en,1],null),$U],null));
var gV=$CLJS.pz(function(a){a=$CLJS.z($CLJS.GU(a));if($CLJS.n(a)){var b=$CLJS.rz(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.xh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.z($CLJS.HU(a));if($CLJS.n(a)){var b=$CLJS.rz(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.X(aV,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,2,[$CLJS.ns,"Valid references for a single query stage",$CLJS.ys,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.ej);return gV(a)}],null),$CLJS.Oe(gV)],null));
$CLJS.X($CLJS.fV,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.PU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xM,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.iK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VO,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.JD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.OE,new $CLJS.h(null,1,[$CLJS.ur,!0],null),KU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PE,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.EF],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),ZU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cV,new $CLJS.h(null,1,[$CLJS.ur,!0],null),XU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JQ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.qK],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aO,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.GJ],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IU,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.NJ],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Dd(a,$CLJS.DP)}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,
new $CLJS.h(null,1,[$CLJS.ns,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.WC($CLJS.aO,$CLJS.IU))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,aV],null)],null));$CLJS.X(NU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.fV],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aO,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.GJ],null)],null)],null)],null));
$CLJS.X(OU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.fV],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.NJ],null)],null)],null)],null));$CLJS.X(RU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,NU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,OU],null)],null));
$CLJS.X(UU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.fV],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.pz($CLJS.aO,$CLJS.IU))],null)],null));$CLJS.X(VU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yq,$CLJS.WU,$CLJS.PU],null));
$CLJS.X(Bma,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,VU],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.wi,$CLJS.fj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,TU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.fV],null)],null)],null)],null));
$CLJS.X(eV,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,VU],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.wi,$CLJS.fj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,TU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,RU],null)],null)],null)],null));$CLJS.X(QU,UU);
$CLJS.X(dV,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,2,[$CLJS.ns,"Valid references for all query stages",$CLJS.ys,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.ej);return JU.h?JU.h(a):JU.call(null,a)}],null),$CLJS.Oe(JU)],null));
$CLJS.X($CLJS.gK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eV],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Iq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,QU],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dV],null)],null));
$CLJS.X($CLJS.EK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.bV],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QO,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.OJ,$CLJS.WJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.gK],null)],null)],null),$CLJS.rma],null));