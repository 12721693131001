var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.automatic_insights.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.malli.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var L7,M7,N7,P7,Q7,R7,Rta,S7,Sta,Tta,T7,U7,Uta,V7,W7,Vta,X7,Wta,Xta,Y7,Z7,$7,a8,Yta,b8,Zta,$ta,aua,bua,c8,cua,dua,d8,eua,fua,gua,hua,iua,e8,jua,kua,lua,mua,nua,oua,pua,f8,qua,rua,sua,tua,uua,vua,wua,xua,yua,zua,Aua,Bua,Cua,Dua,Eua,Fua,Gua,g8,h8,Hua,Iua,Jua,i8,Kua,Lua,Mua,Nua,j8,Oua,Pua,Qua,k8,Rua,Sua,Tua,Uua,Vua;$CLJS.K7=function(a){return $CLJS.ec($CLJS.cb(function(b,c){return $CLJS.Zf.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.bc($CLJS.N),a))};
L7=function(a){var b=$CLJS.MZ(a);return $CLJS.n(b)?$CLJS.ff($CLJS.yZ,$CLJS.SZ(a,b)):$CLJS.xf};
M7=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ai),e=$CLJS.J.g(c,$CLJS.uK),f=$CLJS.J.g(c,$CLJS.BK),k=$CLJS.J.g(c,$CLJS.ej);return $CLJS.n(function(){var l=$CLJS.v5(a,b);return l?(l=$CLJS.Ce(f),$CLJS.n(l)?(l=$CLJS.n(d)?null!=k:d,l=$CLJS.n(l)?l:null==d&&null==k,$CLJS.n(l)?$CLJS.Ta($CLJS.n(d)?$CLJS.zZ(d):d):l):l):l}())?new $CLJS.h(null,6,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.w6,$CLJS.q6,"number"!==typeof k||0>k?2:k,$CLJS.RJ,function(){var l=null==a?null:$CLJS.MZ(a);l=null==l?null:$CLJS.RZ(a,l);
$CLJS.n(l)||(l=null==a?null:$CLJS.w1(a),l=null==l?null:$CLJS.TZ(a,l));return $CLJS.n(l)?$CLJS.XZ.j(a,b,l):null}(),$CLJS.BK,f,$CLJS.uK,e],null):null};
N7=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ai),e=$CLJS.J.g(c,$CLJS.uK),f=$CLJS.J.g(c,$CLJS.BK),k=$CLJS.J.g(c,$CLJS.ej);return $CLJS.n(function(){var l=$CLJS.v5(a,b);return l?(l=$CLJS.Ta(d)||null!=k)?(l=$CLJS.g7(a,$CLJS.xta),$CLJS.n(l)?$CLJS.Ce(f):l):l:l}())?new $CLJS.h(null,4,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.a6,$CLJS.uK,e,$CLJS.BK,f],null):null};$CLJS.O7=function(a){return $CLJS.fk.v(a,$CLJS.kK,$CLJS.Zd,new $CLJS.h(null,1,[$CLJS.fj,$CLJS.PU],null))};
P7=function(a,b,c){var d=$CLJS.S1(a,$CLJS.yV(a,b)),e=$CLJS.bk.g($CLJS.DJ.h(c),$CLJS.dK)?new $CLJS.h(null,2,[$CLJS.vE,a,$CLJS.yK,b],null):$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.vE,a,$CLJS.yK,d],null):new $CLJS.h(null,2,[$CLJS.vE,$CLJS.O7(a),$CLJS.yK,-1],null),f=$CLJS.O2.g($CLJS.vE.h(e),$CLJS.yK.h(e));a=function(){var k=$CLJS.G1.v($CLJS.vE.h(e),$CLJS.yK.h(e),$CLJS.kV(c),f);if($CLJS.n(k))return k;k=$CLJS.o0.h(c);return $CLJS.n(k)?$CLJS.jG(function(l){return $CLJS.E.g($CLJS.o0.h(l),$CLJS.o0.h(c))},f):k}();
return $CLJS.T.j(e,$CLJS.Ai,a)};Q7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ai);d=$CLJS.J.g(d,$CLJS.ej);var e=$CLJS.v5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.zZ(c):c:e;return $CLJS.n(d)?(d=$CLJS.rZ(c)?null:$CLJS.T.j($CLJS.z($CLJS.d2(c)),$CLJS.fj,$CLJS.GI),$CLJS.uk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.V5,$CLJS.T5,d],null),P7(a,b,c)]))):null};
R7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ai);d=$CLJS.J.g(d,$CLJS.ej);var e=$CLJS.v5(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.bk.g($CLJS.DJ.h(c),$CLJS.dK)&&!$CLJS.yZ(c)&&!$CLJS.zZ(c)&&!$CLJS.JZ(c)&&!$CLJS.wZ(c)&&$CLJS.Ta($CLJS.y($CLJS.t5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.u6,$CLJS.Ai,c],null):null};
Rta=function(a,b,c){c=$CLJS.O(c);b=$CLJS.J.g(c,$CLJS.Ai);c=$CLJS.J.g(c,$CLJS.ej);return $CLJS.xZ(b)&&null!=c&&$CLJS.bk.g(c,$CLJS.GK)?new $CLJS.h(null,5,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.t6,$CLJS.Ai,b,$CLJS.F5,c,$CLJS.z5,1<$CLJS.D(L7(a))],null):null};
S7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ai);d=$CLJS.J.g(d,$CLJS.ej);if($CLJS.n($CLJS.n(c)?null!=d&&$CLJS.bk.g(d,$CLJS.GK)&&$CLJS.v5(a,b)&&!$CLJS.yZ(c)&&$CLJS.xZ(c):c)){var e=null==a?null:$CLJS.MZ(a);e=null==e?null:$CLJS.RZ(a,e);$CLJS.n(e)||(e=null==a?null:$CLJS.w1(a),e=null==e?null:$CLJS.TZ(a,e));return new $CLJS.h(null,5,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.Z5,$CLJS.DE,$CLJS.jV(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Fq,$CLJS.N,$CLJS.kV(c),d],null)),$CLJS.KE,$CLJS.XZ.v(a,b,c,$CLJS.YZ),$CLJS.RJ,
$CLJS.XZ.j(a,0,e)],null)}return null};
Sta=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Ai),f=$CLJS.J.g(d,$CLJS.ej),k=$CLJS.J.g(d,$CLJS.E5);if(null!=f&&$CLJS.v5(a,b)&&1<$CLJS.D(L7(a))&&!$CLJS.xZ(e)){if($CLJS.yZ(e))return null!=f&&$CLJS.bk.g(f,$CLJS.GK)?new $CLJS.h(null,3,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.i6,$CLJS.BK,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.Ai,e,$CLJS.ej,f],null)],null)],null):null;var l=L7(a);a=function(){return function u(t){return new $CLJS.je(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=
x;if($CLJS.wd(A)){var C=$CLJS.jc(A),G=$CLJS.D(C),K=$CLJS.me(G);return function(){for(var ba=0;;)if(ba<G){var ha=$CLJS.hd(C,ba),ra=$CLJS.ej.h($CLJS.jG(function(Ra,Ab,Pa){return function(Wa){return $CLJS.E.g($CLJS.U.h($CLJS.Ai.h(Wa)),$CLJS.U.h(Pa))}}(ba,v,ha,C,G,K,A,x,l,c,d,d,e,f,k),k));$CLJS.n(ra)&&$CLJS.qe(K,new $CLJS.h(null,2,[$CLJS.Ai,ha,$CLJS.ej,ra],null));ba+=1}else return!0}()?$CLJS.pe($CLJS.re(K),u($CLJS.kc(A))):$CLJS.pe($CLJS.re(K),null)}var S=$CLJS.z(A),Y=$CLJS.ej.h($CLJS.jG(function(ba,ha){return function(ra){return $CLJS.E.g($CLJS.U.h($CLJS.Ai.h(ra)),
$CLJS.U.h(ha))}}(v,S,A,x,l,c,d,d,e,f,k),k));if($CLJS.n(Y))return $CLJS.be(new $CLJS.h(null,2,[$CLJS.Ai,S,$CLJS.ej,Y],null),u($CLJS.Ic(A)));v=$CLJS.Ic(A)}else return null}},null,null)}(l)}();return $CLJS.y(a)?new $CLJS.h(null,3,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.i6,$CLJS.BK,$CLJS.yf($CLJS.Q0(function(m){return $CLJS.rz(m,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ai,$CLJS.Gi],null))},a))],null):null}return null};
Tta=function(a,b,c){var d=$CLJS.O(c);b=$CLJS.J.g(d,$CLJS.Ai);c=$CLJS.J.g(d,$CLJS.ej);d=$CLJS.J.g(d,$CLJS.E5);if(null==c||1<$CLJS.D(L7(a)))return null;if($CLJS.yZ(b))return $CLJS.E.g(c,$CLJS.GK)?null:new $CLJS.h(null,5,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.L6,$CLJS.Ai,b,$CLJS.F5,c,$CLJS.z5,!1],null);a=L7(a);var e=$CLJS.I(a,0,null);a=$CLJS.ej.h($CLJS.jG(function(f){return $CLJS.E.g($CLJS.U.h($CLJS.Ai.h(f)),$CLJS.U.h(e))},d));return $CLJS.n(a)?new $CLJS.h(null,5,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.L6,$CLJS.Ai,
e,$CLJS.F5,a,$CLJS.z5,!1],null):null};T7=function(a,b,c){return $CLJS.Ne(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)},new $CLJS.Q(null,3,5,$CLJS.R,[Rta,Sta,Tta],null))};U7=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.J.g(e,$CLJS.Ai);e=$CLJS.J.g(e,$CLJS.ej);var f=$CLJS.v5(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.DJ.h(c),$CLJS.dK):c:f;return $CLJS.n(c)?$CLJS.ff(d,$CLJS.s5.g(a,b)):null};
Uta=function(a,b){var c=$CLJS.K7($CLJS.cf.g(function(d){d=$CLJS.VZ.j(a,b,d);return $CLJS.rZ(d)?$CLJS.xO:$CLJS.CZ(d)?$CLJS.s7:$CLJS.vZ(d)?$CLJS.sF:null},$CLJS.q5.g(a,b)));return $CLJS.E.g(new $CLJS.h(null,1,[$CLJS.xO,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.sF,null,$CLJS.P5,null],null),null):$CLJS.E.g(new $CLJS.h(null,2,[$CLJS.xO,1,$CLJS.sF,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.sF,null,$CLJS.P5,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.s7,1],null),c)?new $CLJS.Rg(null,
new $CLJS.h(null,2,[$CLJS.sF,null,$CLJS.xx,null],null),null):$CLJS.E.g($CLJS.N,c)?new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.sF,null,$CLJS.xx,null,$CLJS.P5,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.sF,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.sF,null,$CLJS.xx,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.sF,2],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.sF,null,$CLJS.xx,null],null),null):$CLJS.Sg};
V7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Ai);c=$CLJS.J.g(d,$CLJS.BK);var f=$CLJS.J.g(d,$CLJS.ej);if($CLJS.n(function(){var m=$CLJS.v5(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.DJ.h(e),$CLJS.dK)&&0<$CLJS.D($CLJS.V3.g(a,b)):e:m}())){var k=Uta(a,b),l=$CLJS.Wf.g($CLJS.N,function(){return function u(t){return new $CLJS.je(null,function(){for(var v=t;;)if(v=$CLJS.y(v)){if($CLJS.wd(v)){var x=$CLJS.jc(v),A=$CLJS.D(x),C=$CLJS.me(A);a:for(var G=0;;)if(G<A){var K=$CLJS.hd(x,G),S=$CLJS.J.g($CLJS.t7,
K);S=U7(a,b,d,S);$CLJS.n($CLJS.Ce(S))&&C.add(new $CLJS.Q(null,2,5,$CLJS.R,[K,S],null));G+=1}else{x=!0;break a}return x?$CLJS.pe($CLJS.re(C),u($CLJS.kc(v))):$CLJS.pe($CLJS.re(C),null)}C=$CLJS.z(v);x=$CLJS.J.g($CLJS.t7,C);x=U7(a,b,d,x);if($CLJS.n($CLJS.Ce(x)))return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[C,x],null),u($CLJS.Ic(v)));v=$CLJS.Ic(v)}else return null},null,null)}(k)}());return $CLJS.pd(l)?null:new $CLJS.h(null,4,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.D6,$CLJS.BK,c,$CLJS.L5,l],null)}return null};
W7=function(a,b){return $CLJS.jV($CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[a,$CLJS.N],null),b))};
Vta=function(a,b){var c=$CLJS.kV(a);if($CLJS.zZ(a))return $CLJS.xf;if($CLJS.E.g(b,$CLJS.GK))return new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.U,"\x3d",$CLJS.DE,W7($CLJS.AE,$CLJS.H([c]))],null),new $CLJS.h(null,2,[$CLJS.U,"≠",$CLJS.DE,W7($CLJS.ME,$CLJS.H([c]))],null)],null);if($CLJS.sZ(a)||$CLJS.rZ(a))return a=function f(e){return new $CLJS.je(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);return function(){for(var x=0;;)if(x<
m){var A=$CLJS.hd(l,x),C=$CLJS.I(A,0,null);A=$CLJS.I(A,1,null);var G=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.yq,null,$CLJS.Cq,null],null),null);G=G.h?G.h(C):G.call(null,C);G=(G=$CLJS.Ta(G))?G:$CLJS.EI(c,b);$CLJS.n(G)&&(C=new $CLJS.h(null,2,[$CLJS.U,A,$CLJS.DE,W7(C,$CLJS.H([c,b]))],null),t.add(C));x+=1}else return!0}()?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);if($CLJS.n(function(){var x=new $CLJS.Rg(null,new $CLJS.h(null,
2,[$CLJS.yq,null,$CLJS.Cq,null],null),null);x=x.h?x.h(v):x.call(null,v);return(x=$CLJS.Ta(x))?x:$CLJS.EI(c,b)}()))return $CLJS.be(new $CLJS.h(null,2,[$CLJS.U,u,$CLJS.DE,W7(v,$CLJS.H([c,b]))],null),f($CLJS.Ic(k)));k=$CLJS.Ic(k)}else return null},null,null)},a(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cq,"\x3c"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yq,"\x3e"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,"\x3d"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qF,"≠"],null)],null));
if($CLJS.tZ(a)&&($CLJS.JZ(a)||$CLJS.wZ(a)))return a=function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.U,v,$CLJS.DE,W7(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);
return $CLJS.be(new $CLJS.h(null,2,[$CLJS.U,l,$CLJS.DE,W7(t,$CLJS.H([c,b]))],null),f($CLJS.Ic(k)))}return null}},null,null)},a(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZE,"contains"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SE,"does-not-contain"],null)],null));a=function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,
1,null);x=new $CLJS.h(null,2,[$CLJS.U,v,$CLJS.DE,W7(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.be(new $CLJS.h(null,2,[$CLJS.U,l,$CLJS.DE,W7(t,$CLJS.H([c,b]))],null),f($CLJS.Ic(k)))}return null}},null,null)};return a(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,"\x3d"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qF,"≠"],null)],
null))};X7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ai);d=$CLJS.J.g(d,$CLJS.ej);var e=(e=$CLJS.v5(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.yZ(c)&&!$CLJS.xZ(c):c:e;return $CLJS.n(e)?(a=P7(a,b,c),$CLJS.uk.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.D5,$CLJS.wK,Vta($CLJS.Ai.h(a),d),$CLJS.ej,d],null),a]))):null};Wta=function(a,b,c){return $CLJS.G1.v(a,b,$CLJS.kV(c),$CLJS.z7.g(a,b))};
Xta=function(a,b,c){a=$CLJS.B7(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
Y7=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ai),e=$CLJS.J.g(c,$CLJS.ej);if($CLJS.n(function(){var k=$CLJS.v5(a,b);return k?$CLJS.n(d)?null==e&&!$CLJS.zZ(d):d:k}())&&$CLJS.n(Wta(a,b,d))){var f=Xta(a,b,d);return new $CLJS.h(null,4,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.e6,$CLJS.Ai,d,$CLJS.h6,function(){switch(f instanceof $CLJS.M?f.S:null){case "asc":return new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.QE],null);case "desc":return new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.XE],null);default:return new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.XE,$CLJS.QE],null)}}()],null)}return null};
Z7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ai);d=$CLJS.J.g(d,$CLJS.ej);var e=$CLJS.v5(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.zZ(c)&&$CLJS.bk.g($CLJS.DJ.h(c),$CLJS.dK)&&$CLJS.Ta($CLJS.y($CLJS.t5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.bf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.EE],null),$CLJS.uZ(c)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lF,$CLJS.UE],null):null),new $CLJS.h(null,4,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.f6,$CLJS.Ai,c,$CLJS.k6,a],null)):null};
$7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ai);d=$CLJS.J.g(d,$CLJS.ej);var e=$CLJS.v5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.zZ(c)&&$CLJS.uZ(c)&&$CLJS.bk.g($CLJS.DJ.h(c),$CLJS.dK):c:e;if($CLJS.n(d)&&(d=$CLJS.jG($CLJS.rZ,$CLJS.s5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.jG($CLJS.Oh,$CLJS.i0.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.S2(d,e);return $CLJS.n($CLJS.DU($CLJS.cf.g($CLJS.kV,$CLJS.be(f,$CLJS.q5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.A6,$CLJS.Ai,c,$CLJS.OE,d,$CLJS.ci,$CLJS.f0.h(e)],
null):null}return null};
a8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ai);d=$CLJS.J.g(d,$CLJS.ej);if($CLJS.n($CLJS.n(c)?d:c))if(b=$CLJS.z($CLJS.t5.j(a,b,c)),$CLJS.n(b))if(b=$CLJS.J4(b),$CLJS.n(b))if(a=$CLJS.i7(a,c,d),$CLJS.n(a)){var e=$CLJS.O(a);a=$CLJS.J.g(e,$CLJS.AK);var f=$CLJS.J.g(e,$CLJS.DK);e=$CLJS.J.g(e,$CLJS.rD);var k=$CLJS.sD.h(b);k=k instanceof $CLJS.M?k.S:null;switch(k){case "num-bins":case "default":return new $CLJS.h(null,6,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.j6,$CLJS.Ai,c,$CLJS.AK,d,$CLJS.DK,d+e,$CLJS.b6,
new $CLJS.h(null,1,[$CLJS.sD,$CLJS.Oh],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.j6,$CLJS.Ai,c,$CLJS.AK,a,$CLJS.DK,f,$CLJS.b6,$CLJS.fk.j(b,$CLJS.rD,function(l){return l/10})],null);default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
Yta=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.E5),e=$CLJS.zV(a,b);b=$CLJS.Ne(function(f){var k=$CLJS.jG($CLJS.HZ,f);return $CLJS.n(k)?(f=$CLJS.jG($CLJS.IZ,f),$CLJS.n(f)?new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null):null):null},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.H_.j(a,b,e),$CLJS.J_.j(a,b,e)],null));a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?(e=function(f){return $CLJS.Ne(function(k){var l=$CLJS.Ai.h(k);l=$CLJS.n($CLJS.Gi.h(f))?$CLJS.E.g($CLJS.Gi.h(f),$CLJS.Gi.h(l)):
$CLJS.E.g($CLJS.CY.h(f),$CLJS.CY.h(l));return $CLJS.n(l)?$CLJS.ej.h(k):null},d)},$CLJS.T.l(c,$CLJS.F7,a,$CLJS.H([$CLJS.G7,b,$CLJS.H7,e(a),$CLJS.E7,e(b)]))):null};
b8=function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.Ai);var d=$CLJS.J.g(c,$CLJS.ej),e=$CLJS.J.g(c,$CLJS.F7);c=$CLJS.J.g(c,$CLJS.G7);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.fj,$CLJS.HK,$CLJS.Si,$CLJS.H6,$CLJS.J5,$CLJS.X5,$CLJS.Ai,a,$CLJS.ej,d,$CLJS.$5,new $CLJS.h(null,2,[$CLJS.Ai,e,$CLJS.rD,b],null),$CLJS.Y5,new $CLJS.h(null,2,[$CLJS.Ai,c,$CLJS.rD,b],null)],null):null};Zta=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ai);return $CLJS.n(null==b?null:$CLJS.FZ(b))?b8(a,10):null};
$ta=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ai);return $CLJS.n(null==b?null:$CLJS.EZ(b))?b8(a,1):null};aua=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ai);return $CLJS.n(null==b?null:$CLJS.DZ(b))?b8(a,.1):null};
bua=function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.F7);var d=$CLJS.J.g(c,$CLJS.G7),e=$CLJS.J.g(c,$CLJS.H7);c=$CLJS.J.g(c,$CLJS.E7);if($CLJS.n($CLJS.n(e)?c:e)){var f=$CLJS.i7(a,b,e);if($CLJS.n(f)&&(f=$CLJS.O(f),f=$CLJS.J.g(f,$CLJS.rD),a=$CLJS.i7(a,d,c),$CLJS.n(a))){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.rD);var k=20<=f&&20<=a?new $CLJS.Q(null,2,5,$CLJS.R,[10,10],null):new $CLJS.Q(null,2,5,$CLJS.R,[f/10,a/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.fj,$CLJS.HK,$CLJS.Si,
$CLJS.H6,$CLJS.J5,$CLJS.B5,$CLJS.$5,new $CLJS.h(null,4,[$CLJS.Ai,b,$CLJS.rD,l,$CLJS.en,e,$CLJS.Cj,e+f],null),$CLJS.Y5,new $CLJS.h(null,4,[$CLJS.Ai,d,$CLJS.rD,k,$CLJS.en,c,$CLJS.Cj,c+a],null)],null)}}return null};c8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.ej);if($CLJS.n(d)){var e=Yta(a,b,c);return $CLJS.n(e)?$CLJS.Ne(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.Q(null,4,5,$CLJS.R,[Zta,$ta,aua,$CLJS.Qe(bua,a)],null)):null}return null};
cua=function(a,b,c){return $CLJS.z(function(){return function f(e){return new $CLJS.je(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.z(m);if($CLJS.n(function(){var u=$CLJS.t1(t,$CLJS.$E);return u?$CLJS.$1(t):u}())&&(l=$CLJS.y(function(u,v,x,A){return function K(G){return new $CLJS.je(null,function(S,Y){return function(){for(var ba=G;;)if(ba=$CLJS.y(ba)){if($CLJS.wd(ba)){var ha=$CLJS.jc(ba),ra=$CLJS.D(ha),Ra=$CLJS.me(ra);return function(){for(var ib=0;;)if(ib<ra){var Na=$CLJS.hd(ha,
ib),ob=$CLJS.O(Na);Na=ob;ob=$CLJS.J.g(ob,$CLJS.Ai);var Ja=$CLJS.G1.g(Y,new $CLJS.Q(null,1,5,$CLJS.R,[ob],null));ob=$CLJS.n(Ja)?$CLJS.E.g($CLJS.$1(Y),$CLJS.$1(ob)):Ja;$CLJS.n(ob)&&(Na=$CLJS.T.j(Na,$CLJS.uK,Y),Ra.add(Na));ib+=1}else return!0}()?$CLJS.pe($CLJS.re(Ra),K($CLJS.kc(ba))):$CLJS.pe($CLJS.re(Ra),null)}var Ab=$CLJS.z(ba),Pa=Ab=$CLJS.O(Ab),Wa=$CLJS.J.g(Ab,$CLJS.Ai);if($CLJS.n(function(){var ib=$CLJS.G1.g(Y,new $CLJS.Q(null,1,5,$CLJS.R,[Wa],null));return $CLJS.n(ib)?$CLJS.E.g($CLJS.$1(Y),$CLJS.$1(Wa)):
ib}()))return $CLJS.be($CLJS.T.j(Pa,$CLJS.uK,Y),K($CLJS.Ic(ba)));ba=$CLJS.Ic(ba)}else return null}}(u,v,x,A),null,null)}}(k,t,m,l)(c))))return $CLJS.bf.g(l,f($CLJS.Ic(k)));k=$CLJS.Ic(k)}else return null}},null,null)}($CLJS.q5.g(a,b))}())};
dua=function(a){a=a instanceof $CLJS.M?a.S:null;switch(a){case "quarter":return $CLJS.zD("See this year by quarter");case "month":return $CLJS.zD("See this quarter by month");case "week":return $CLJS.zD("See this month by week");case "day":return $CLJS.zD("See this week by day");case "hour":return $CLJS.zD("See this day by hour");case "minute":return $CLJS.zD("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
d8=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.BK);var d=(d=$CLJS.v5(a,b))?$CLJS.Ce(c):d;return $CLJS.n(d)&&(a=cua(a,b,c),$CLJS.n(a)&&(a=$CLJS.O(a),b=$CLJS.J.g(a,$CLJS.ej),$CLJS.n(b)))?(b=$CLJS.Ai.h(a),b=$CLJS.f0.h(b),b=$CLJS.n(b)?$CLJS.Dd($CLJS.Wg($CLJS.I7),b)?$CLJS.J7.h?$CLJS.J7.h(b):$CLJS.J7.call(null,b):null:null,$CLJS.n(b)?new $CLJS.h(null,5,[$CLJS.fj,$CLJS.HK,$CLJS.cD,dua(b),$CLJS.Si,$CLJS.F6,$CLJS.sE,a,$CLJS.z6,b],null):null):null};
eua=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.BK);return $CLJS.Ce(function(){return function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.uk.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),null)}t=$CLJS.z(k);return $CLJS.be($CLJS.uk.l($CLJS.H([b,t])),f($CLJS.Ic(k)))}return null}},null,
null)}(c)}())};fua=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);gua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);hua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);
iua=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);e8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);jua=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);kua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);
lua=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);mua=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);nua=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);oua=new $CLJS.M(null,"drill","drill",-1704624793);pua=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);f8=new $CLJS.r(null,"_context","_context",-1238791322,null);
qua=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);rua=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);sua=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);tua=new $CLJS.r(null,"automatic-insights-drill","automatic-insights-drill",-538289251,null);
uua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);vua=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);wua=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);
xua=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);yua=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);zua=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);Aua=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);
Bua=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);Cua=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);Dua=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);
Eua=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);Fua=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);Gua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);g8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);
h8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);Hua=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);Iua=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);Jua=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);
i8=new $CLJS.r(null,"context","context",810340414,null);Kua=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);Lua=new $CLJS.r("metabase.lib.drill-thru.automatic-insights","automatic-insights-drill","metabase.lib.drill-thru.automatic-insights/automatic-insights-drill",1471807986,null);Mua=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
Nua=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);j8=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);Oua=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);Pua=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
Qua=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);k8=new $CLJS.r(null,"query","query",352022017,null);Rua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);Sua=new $CLJS.r(null,"metabase.lib.drill-thru.automatic-insights","metabase.lib.drill-thru.automatic-insights",912989182,null);
Tua=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);Uua=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);Vua=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var Wua;$CLJS.F_.m(null,$CLJS.HK,function(a,b,c){return $CLJS.y5.j(a,b,c)});
Wua=new $CLJS.Q(null,14,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return N7},Lua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.d6],null)],null),Sua,tua,"metabase/lib/drill_thru/automatic_insights.cljc",34,1,11,11,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,new $CLJS.h(null,
1,[$CLJS.ho,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Py,j8,g8,$CLJS.Bi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru]\n          \n\n  Automatic insights appears:\n  - When clicking on a value with a breakout - eg. a point in a time series, a cell of a table, a bar or pie slice\n  - Or when clicking a pivot cell, with a value but no column.\n  - Or when clicking a chart legend, in which case there\'s no column or value set.\n  - There must be at least 1 breakout\n  - X-rays must be enabled (check the settings)\n\n  There are two forms: X-ray, and "Compare to the rest". This is a simple user choice and does not need extra data.',
$CLJS.n(N7)?N7.H:null])),h8,!1],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return Q7},Hua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.R5],null)],null),Jua,Dua,"metabase/lib/drill_thru/column_filter.cljc",29,1,81,81,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,new $CLJS.h(null,
1,[$CLJS.ho,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Py,$CLJS.Bi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(Q7)?Q7.H:null])),h8,!0],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return R7},vua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.I5],null)],null),qua,mua,"metabase/lib/drill_thru/distribution.cljc",28,1,42,42,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,new $CLJS.h(null,
1,[$CLJS.ho,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Py,$CLJS.Bi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n(R7)?R7.H:null])),h8,!0],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return S7},Tua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.x6],null)],null),Pua,wua,"metabase/lib/drill_thru/fk_filter.cljc",25,1,36,36,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,new $CLJS.h(null,
2,[$CLJS.ho,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Py,$CLJS.Bi],null),$CLJS.Rr,f8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(S7)?S7.H:null])),h8,!1],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return T7},Nua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ar,$CLJS.C5,$CLJS.y6,$CLJS.n6],null)],null)],null),sua,Iua,"metabase/lib/drill_thru/object_details.cljc",
29,1,10,10,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,i8],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int context :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(T7)?T7.H:null])),h8,!1],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return V7},Oua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.M5],null)],null),fua,yua,"metabase/lib/drill_thru/pivot.cljc",21,1,120,120,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,new $CLJS.h(null,
2,[$CLJS.ho,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Py,g8,$CLJS.Bi],null),$CLJS.Rr,i8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n(V7)?V7.H:null])),h8,!1],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return X7},pua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.o6],null)],null),zua,Vua,"metabase/lib/drill_thru/quick_filter.cljc",28,1,93,93,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,new $CLJS.h(null,
2,[$CLJS.ho,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Py,$CLJS.Bi],null),$CLJS.Rr,f8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(X7)?X7.H:null])),h8,!1],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return Y7},Uua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.I6],null)],null),Fua,nua,"metabase/lib/drill_thru/sort.cljc",20,1,53,53,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,new $CLJS.h(null,
2,[$CLJS.ho,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Py,$CLJS.Bi],null),$CLJS.Rr,f8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(Y7)?Y7.H:null])),h8,!0],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return Z7},Qua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,
$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.U5],null)],null),xua,Cua,"metabase/lib/drill_thru/summarize_column.cljc",32,1,31,31,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,new $CLJS.h(null,1,[$CLJS.ho,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Py,$CLJS.Bi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(Z7)?Z7.H:null])),h8,!0],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return $7},Bua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.S5],null)],null),iua,Mua,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,40,40,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,
[k8,e8,new $CLJS.h(null,1,[$CLJS.ho,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Py,$CLJS.Bi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n($7)?
$7.H:null])),h8,!0],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return M7},Eua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.G6],null)],null),Aua,jua,"metabase/lib/drill_thru/underlying_records.cljc",34,1,51,51,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,new $CLJS.h(null,
2,[$CLJS.ho,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Py,j8,g8,$CLJS.Bi],null),$CLJS.Rr,f8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.\n\n  There is another quite different case: clicking the legend of a chart with multiple bars or lines broken out by\n  category. Then `column` is nil!",
$CLJS.n(M7)?M7.H:null])),h8,!1],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return d8},kua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.B6],null)],null),Rua,lua,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,89,89,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,
new $CLJS.h(null,2,[$CLJS.ho,new $CLJS.Q(null,1,5,$CLJS.R,[g8],null),$CLJS.Rr,f8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [dimensions], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(d8)?d8.H:null])),h8,!1],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return c8},uua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.C6],null)],null),Gua,Kua,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,189,189,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,
e8,new $CLJS.h(null,2,[$CLJS.ho,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Bi],null),$CLJS.Rr,i8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(c8)?c8.H:null])),h8,!0],null),new $CLJS.h(null,2,[$CLJS.Lr,new $CLJS.Dc(function(){return a8},hua,$CLJS.zg([$CLJS.Nk,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mn,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,$CLJS.EK,$CLJS.lr,$CLJS.O5],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.E6],null)],null),gua,rua,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,86,86,$CLJS.V(new $CLJS.Q(null,3,5,$CLJS.R,[k8,e8,new $CLJS.h(null,
2,[$CLJS.ho,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Py,$CLJS.Bi],null),$CLJS.Rr,f8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(a8)?a8.H:null])),h8,!0],null)],null);
$CLJS.Xua=function(){function a(d,e,f){try{return $CLJS.Wf.g($CLJS.xf,$CLJS.M0(d)?function(){var l=eua(f);return function u(t){return new $CLJS.je(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=x,C=$CLJS.z(A),G=$CLJS.O(C),K=$CLJS.J.g(G,$CLJS.Lr),S=$CLJS.J.g(G,h8);if(x=$CLJS.y(function(Y,ba,ha,ra,Ra,Ab,Pa,Wa){return function ob(Na){return new $CLJS.je(null,function(Ja,cc,Md,Id){return function(){for(var od=Na;;)if(od=$CLJS.y(od)){if($CLJS.wd(od)){var Pi=$CLJS.jc(od),jN=$CLJS.D(Pi),Ln=
$CLJS.me(jN);a:for(var nr=0;;)if(nr<jN){var zv=$CLJS.hd(Pi,nr);zv=Id.j?Id.j(d,e,zv):Id.call(null,d,e,zv);$CLJS.n(zv)&&Ln.add(zv);nr+=1}else{Pi=!0;break a}return Pi?$CLJS.pe($CLJS.re(Ln),ob($CLJS.kc(od))):$CLJS.pe($CLJS.re(Ln),null)}Ln=$CLJS.z(od);Ln=Id.j?Id.j(d,e,Ln):Id.call(null,d,e,Ln);if($CLJS.n(Ln))return $CLJS.be(Ln,ob($CLJS.Ic(od)));od=$CLJS.Ic(od)}else return null}}(Y,ba,ha,ra,Ra,Ab,Pa,Wa),null,null)}}(v,C,G,K,S,A,x,l)($CLJS.n(function(){var Y=S;return $CLJS.n(Y)?l:Y}())?l:new $CLJS.Q(null,
1,5,$CLJS.R,[f],null))))return $CLJS.bf.g(x,u($CLJS.Ic(v)));v=$CLJS.Ic(v)}else return null}},null,null)}(Wua)}():null)}catch(l){var k=l;throw $CLJS.Sh(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.nU(k))].join(""),new $CLJS.h(null,3,[$CLJS.vE,d,$CLJS.yK,e,$CLJS.jM,f],null),k);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Yua=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.Cz($CLJS.tx);$CLJS.n($CLJS.Bz("metabase.lib.drill-thru",t))&&$CLJS.Az("metabase.lib.drill-thru",t,$CLJS.oV("Applying drill thru: %s",$CLJS.H([$CLJS.pV(new $CLJS.h(null,4,[$CLJS.vE,f,$CLJS.yK,
k,oua,l,$CLJS.Er,m],null))])),null);return $CLJS.P.M($CLJS.x5,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.z(f);f=$CLJS.B(f);var l=$CLJS.z(f);f=$CLJS.B(f);var m=$CLJS.z(f);f=$CLJS.Ic(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();