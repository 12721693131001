var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var YT,ZT,$T,bU,cU,dU,eU,fU,gU,hU;YT=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);ZT=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);$T=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.aU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);bU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);cU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);dU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
eU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);fU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);gU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);hU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(YT,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"Valid template tag :widget-type"],null),$CLJS.Vy],null),$CLJS.Ng($CLJS.TT)));$CLJS.X(bU,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Yq,$CLJS.AL,$CLJS.pM,$CLJS.sE,$CLJS.Js,$CLJS.nA,$CLJS.xO],null));
$CLJS.X(eU,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cD,$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Gi,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.hD,$CLJS.pr],null)],null)],null));
$CLJS.X(fU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.Zk],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RM,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null)],null)],null));
$CLJS.X(cU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,fU],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.sE],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.PD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,YT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Mi,new $CLJS.h(null,1,[$CLJS.ur,!0],
null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.Bj],null)],null)],null)],null));
$CLJS.X(dU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eU],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.AL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mL,$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PO,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.$J],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QO,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.OJ],null)],null)],null));
$CLJS.X(hU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.pM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YP,$CLJS.NJ],null)],null)],null));$CLJS.X(gU,$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Yq],null),$CLJS.HT));
$CLJS.X(ZT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,fU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,gU],null)],null)],null)],null));
$CLJS.X($T,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bU],null)],null)],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.wi,$CLJS.Si],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pM,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,hU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Un,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,ZT],null)],null)],null)],null));
$CLJS.X($CLJS.aU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,$CLJS.hD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$T],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.U.h(b))},a)}],null)],null));