var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var vK,xK,zK,CK,FK;$CLJS.sK=function(a){return $CLJS.Hh($CLJS.q($CLJS.YA),a,$CLJS.dF)};$CLJS.tK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.uK=new $CLJS.M(null,"column-ref","column-ref",2018188376);vK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.wK=new $CLJS.M(null,"operators","operators",-2064102509);
xK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.yK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);zK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.AK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.BK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
CK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.DK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.EK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);FK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.GK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.HK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(xK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,$CLJS.mD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fF,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.VC],null)],null)],null));$CLJS.X(FK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,xK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jA,$CLJS.qD],null)],null)],null));$CLJS.X(zK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.$E],null),FK,$CLJS.hD],null));
$CLJS.X(CK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.$E],null),xK,$CLJS.aK],null));
$CLJS.zF.g($CLJS.$E,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.$E],null),xK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.aK,$CLJS.hD],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.wi,function(a){return $CLJS.sd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.OA(a)):null},$CLJS.ns,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.Q(null,2,
5,$CLJS.R,[$CLJS.bA,CK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aA,zK],null)],null)],null));$CLJS.fE($CLJS.$E,$CLJS.dF);$CLJS.BD.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.pz($CLJS.ni,$CLJS.jA)(b);return $CLJS.n(a)?a:$CLJS.OD});$CLJS.mE($CLJS.gA,$CLJS.H([$CLJS.hD]));$CLJS.BD.m(null,$CLJS.gA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.pz($CLJS.ni,$CLJS.jA)(b);return $CLJS.n(a)?a:$CLJS.OD});
$CLJS.fE($CLJS.gA,$CLJS.dF);$CLJS.X(vK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,$CLJS.mD,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tK,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.hD],null)],null)],null));
$CLJS.zF.g($CLJS.PE,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.PE],null),vK,$CLJS.wj],null));$CLJS.BD.m(null,$CLJS.PE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.pz($CLJS.ni,$CLJS.jA)(b);return $CLJS.n(a)?a:$CLJS.OD});$CLJS.fE($CLJS.PE,$CLJS.dF);$CLJS.mE($CLJS.xE,$CLJS.H([$CLJS.Tr,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.EJ],null)],null)]));$CLJS.fE($CLJS.xE,$CLJS.dF);
$CLJS.mE($CLJS.VE,$CLJS.H([$CLJS.Tr,$CLJS.OD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.CJ],null)],null)]));$CLJS.fE($CLJS.VE,$CLJS.dF);
$CLJS.X($CLJS.dF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,$CLJS.DD,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ys,function(){return["Valid reference, must be one of these clauses: ",$CLJS.bs(", ",$CLJS.sz($CLJS.Ed,$CLJS.xk.g($CLJS.q($CLJS.YA),$CLJS.dF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.sK(a)}],null)],null));