var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Xga,Yga,Zga,$ga,aha,bha,cha,HF,dha,eha,fha,gha,hha,iha,jha,kha,JF,lha;$CLJS.AF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Xga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.EF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Yga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Zga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.FF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$ga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.GF=new $CLJS.M(null,"display-info","display-info",-816930907);aha=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);bha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
cha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);HF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);dha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);eha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);fha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);gha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
hha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);iha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);jha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.IF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);kha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);JF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.KF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);lha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.oE($CLJS.Jw,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gA,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)],null)],null)],null)]));$CLJS.oE($CLJS.aF,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gA,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)],null)],null)],null)]));
$CLJS.mE($CLJS.UE,$CLJS.H([$CLJS.Tr,$CLJS.BC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));$CLJS.mE($CLJS.EE,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)],null)]));$CLJS.mE($CLJS.iF,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.MD],null)],null)]));
$CLJS.mE($CLJS.Cj,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null)],null)]));$CLJS.fE($CLJS.Cj,$CLJS.SD);$CLJS.mE($CLJS.mF,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));$CLJS.fE($CLJS.mF,$CLJS.SD);$CLJS.mE($CLJS.en,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TD],null)],null)]));$CLJS.fE($CLJS.en,$CLJS.SD);
$CLJS.X(HF,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ns,"valid percentile"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.mE($CLJS.LE,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,HF],null)]));$CLJS.fE($CLJS.LE,$CLJS.SD);
$CLJS.mE($CLJS.gF,$CLJS.H([$CLJS.Tr,$CLJS.BC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.MD],null)],null)]));$CLJS.mE($CLJS.oF,$CLJS.H([$CLJS.Tr,$CLJS.BC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));$CLJS.mE($CLJS.lF,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));
$CLJS.mE($CLJS.vF,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));$CLJS.fE($CLJS.lF,$CLJS.SD);$CLJS.fE($CLJS.vF,$CLJS.SD);$CLJS.mE($CLJS.tF,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.MD],null)],null)]));$CLJS.fE($CLJS.tF,$CLJS.SD);
$CLJS.mE($CLJS.FE,$CLJS.H([$CLJS.Tr,$CLJS.BC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.YD],null)],null)]));$CLJS.X(JF,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.ar,kha,iha,bha,Yga,Xga,aha,cha,dha,eha,hha,fha,lha,jha,Zga,gha,$CLJS.Zk],null));$CLJS.X($CLJS.EF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.en,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,JF],null)],null));
$CLJS.LF=new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.h(null,4,[$CLJS.TE,$CLJS.Jw,$CLJS.FF,!1,$CLJS.IF,$CLJS.tE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.zD("Count of rows"),$CLJS.KE,$CLJS.zD("Count"),$CLJS.Bs,$CLJS.zD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.TE,$CLJS.lF,$CLJS.AF,$CLJS.zj,$CLJS.FF,!0,$CLJS.IF,$CLJS.tE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.zD("Sum of ..."),$CLJS.KE,$CLJS.zD("Sum"),$CLJS.Bs,$CLJS.zD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.TE,$CLJS.UE,$CLJS.AF,$CLJS.zj,$CLJS.FF,!0,$CLJS.IF,$CLJS.tE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.zD("Average of ..."),$CLJS.KE,$CLJS.zD("Average"),$CLJS.Bs,$CLJS.zD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.TE,$CLJS.mF,$CLJS.AF,$CLJS.zj,$CLJS.FF,!0,$CLJS.IF,$CLJS.WE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.zD("Median of ..."),$CLJS.KE,$CLJS.zD("Median"),$CLJS.Bs,$CLJS.zD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.TE,$CLJS.EE,$CLJS.AF,$CLJS.Xh,$CLJS.FF,!0,$CLJS.IF,$CLJS.tE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.zD("Number of distinct values of ..."),$CLJS.KE,$CLJS.zD("Distinct values"),$CLJS.Bs,$CLJS.zD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.TE,$CLJS.vF,$CLJS.AF,$CLJS.zj,$CLJS.FF,!0,$CLJS.IF,$CLJS.tE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.zD("Cumulative sum of ..."),
$CLJS.KE,$CLJS.zD("Sum"),$CLJS.Bs,$CLJS.zD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.TE,$CLJS.aF,$CLJS.FF,!1,$CLJS.IF,$CLJS.tE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.zD("Cumulative count of rows"),$CLJS.KE,$CLJS.zD("Count"),$CLJS.Bs,$CLJS.zD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.TE,$CLJS.oF,$CLJS.AF,$CLJS.zj,$CLJS.FF,
!0,$CLJS.IF,$CLJS.jF,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.zD("Standard deviation of ..."),$CLJS.KE,$CLJS.zD("SD"),$CLJS.Bs,$CLJS.zD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.TE,$CLJS.en,$CLJS.AF,$CLJS.Fi,$CLJS.FF,!0,$CLJS.IF,$CLJS.tE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.zD("Minimum of ..."),$CLJS.KE,$CLJS.zD("Min"),$CLJS.Bs,$CLJS.zD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.TE,$CLJS.Cj,$CLJS.AF,$CLJS.Fi,$CLJS.FF,!0,$CLJS.IF,$CLJS.tE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.zD("Maximum of ..."),$CLJS.KE,$CLJS.zD("Max"),$CLJS.Bs,$CLJS.zD("Maximum value of a column")],null)}],null)],null);
$CLJS.X($ga,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.KF],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TE,$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Yq],null),$CLJS.cf.h($CLJS.TE),$CLJS.LF)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AF,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.Ei],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FF,$CLJS.qr],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.IF,$CLJS.Ei],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GF,$CLJS.id],null)],null));