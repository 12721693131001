var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var aE,jE,gE,Wga;aE=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=aE[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=aE._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("Cached.-cache",a);}return a};
$CLJS.bE=function(a,b,c){if(null!=a&&$CLJS.zc===a.Wa){var d=aE(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.zh.v(d,$CLJS.T,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.dE=function(a,b,c){a=$CLJS.cE.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.eE=function(a,b){return $CLJS.dE(a,b,null)};$CLJS.fE=function(a,b){$CLJS.zh.v($CLJS.YA,$CLJS.Xz,a,b)};$CLJS.hE=function(a,b){a=gE(a,b);return $CLJS.n(a)?a:$CLJS.cj};
jE=function(){return $CLJS.Wf.j(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.wi,$CLJS.z,$CLJS.ys,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.ej);return $CLJS.vd(a)?["Invalid ",$CLJS.xh.l($CLJS.H([$CLJS.z(a)]))," clause: ",$CLJS.xh.l($CLJS.H([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Un,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"not a known MBQL clause"],null),$CLJS.Pe(!1)],null)],null)],null),$CLJS.cf.h(function(a){return new $CLJS.Q(null,
2,5,$CLJS.R,[a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.mh.g("mbql.clause",$CLJS.Xg(a))],null)],null)}),$CLJS.q(iE))};$CLJS.mE=function(a,b){$CLJS.P.v($CLJS.kE,$CLJS.lE,a,b)};$CLJS.oE=function(a,b){$CLJS.P.v($CLJS.kE,nE,a,b)};$CLJS.po.prototype.ia=$CLJS.va(26,function(){return this.cache});$CLJS.ro.prototype.ia=$CLJS.va(25,function(){return this.cache});$CLJS.to.prototype.ia=$CLJS.va(24,function(){return this.cache});$CLJS.vo.prototype.ia=$CLJS.va(23,function(){return this.cache});
$CLJS.xo.prototype.ia=$CLJS.va(22,function(){return this.cache});$CLJS.zo.prototype.ia=$CLJS.va(21,function(){return this.cache});$CLJS.Co.prototype.ia=$CLJS.va(20,function(){return this.cache});$CLJS.Eo.prototype.ia=$CLJS.va(19,function(){return this.cache});$CLJS.Go.prototype.ia=$CLJS.va(18,function(){return this.cache});$CLJS.Io.prototype.ia=$CLJS.va(17,function(){return this.cache});$CLJS.Ko.prototype.ia=$CLJS.va(16,function(){return this.cache});$CLJS.Mo.prototype.ia=$CLJS.va(15,function(){return this.cache});
$CLJS.Oo.prototype.ia=$CLJS.va(14,function(){return this.cache});$CLJS.Qo.prototype.ia=$CLJS.va(13,function(){return this.cache});$CLJS.So.prototype.ia=$CLJS.va(12,function(){return this.cache});$CLJS.Uo.prototype.ia=$CLJS.va(11,function(){return this.cache});$CLJS.Xo.prototype.ia=$CLJS.va(10,function(){return this.cache});$CLJS.bp.prototype.ia=$CLJS.va(9,function(){return this.cache});$CLJS.dp.prototype.ia=$CLJS.va(8,function(){return this.cache});$CLJS.gp.prototype.ia=$CLJS.va(7,function(){return this.cache});
$CLJS.qp.prototype.ia=$CLJS.va(6,function(){return this.cache});$CLJS.LC.prototype.ia=$CLJS.va(5,function(){return this.cache});$CLJS.cE=function cE(a){switch(arguments.length){case 1:return cE.h(arguments[0]);case 2:return cE.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.cE.h=function(a){return $CLJS.cE.g(a,null)};$CLJS.cE.g=function(a,b){return $CLJS.bE($CLJS.zn.g(a,b),$CLJS.Kj,$CLJS.Dm)};$CLJS.cE.A=2;
$CLJS.pE=function pE(a,b){return $CLJS.Hh($CLJS.EC,a,b)||$CLJS.Ad($CLJS.Ne(function(d){return pE.g?pE.g(a,d):pE.call(null,a,d)},$CLJS.xk.h(b)))||$CLJS.Ad($CLJS.Ne(function(d){return pE.g?pE.g(d,b):pE.call(null,d,b)},$CLJS.Jh(a)))};
gE=function gE(a,b){return $CLJS.E.g(a,$CLJS.cj)?null:$CLJS.E.g(b,$CLJS.cj)?null:$CLJS.pE(a,b)?b:$CLJS.pE(b,a)?a:$CLJS.Ne(function(d){return $CLJS.Ne(function(e){return $CLJS.E.g(new $CLJS.Q(null,2,5,$CLJS.R,[d,e],null),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null))?null:gE.g?gE.g(d,e):gE.call(null,d,e)},$CLJS.be(b,$CLJS.Jh(b)))},$CLJS.be(a,$CLJS.Jh(a)))};$CLJS.qE=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.rE=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.sE=new $CLJS.M(null,"dimension","dimension",543254198);$CLJS.tE=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.uE=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.vE=new $CLJS.M(null,"query","query",-1288509510);$CLJS.wE=new $CLJS.M(null,"power","power",-937852079);$CLJS.xE=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.yE=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.zE=new $CLJS.M(null,"abs","abs",-246026477);
$CLJS.AE=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.BE=new $CLJS.M(null,"between","between",1131099276);$CLJS.CE=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.DE=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.EE=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.FE=new $CLJS.M(null,"var","var",-769682797);$CLJS.GE=new $CLJS.M(null,"next","next",-117701485);$CLJS.HE=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.IE=new $CLJS.M(null,"case","case",1143702196);
$CLJS.JE=new $CLJS.M(null,"not-empty","not-empty",388922063);$CLJS.KE=new $CLJS.M(null,"column-name","column-name",551523580);$CLJS.LE=new $CLJS.M(null,"percentile","percentile",-601188752);$CLJS.ME=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.NE=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.OE=new $CLJS.M(null,"breakout","breakout",-732419050);$CLJS.PE=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.QE=new $CLJS.M(null,"desc","desc",2093485764);
$CLJS.RE=new $CLJS.M(null,"starts-with","starts-with",366503009);$CLJS.SE=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.TE=new $CLJS.M(null,"short","short",1928760516);$CLJS.UE=new $CLJS.M(null,"avg","avg",197406200);$CLJS.VE=new $CLJS.M(null,"metric","metric",408798077);$CLJS.WE=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.XE=new $CLJS.M(null,"asc","asc",356854569);$CLJS.YE=new $CLJS.M(null,"floor","floor",1882041021);
$CLJS.ZE=new $CLJS.M(null,"contains","contains",676899812);$CLJS.$E=new $CLJS.M(null,"field","field",-1302436500);$CLJS.aF=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.bF=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);$CLJS.cF=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.dF=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.eF=new $CLJS.M(null,"round","round",2009433328);
$CLJS.fF=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);$CLJS.gF=new $CLJS.M(null,"share","share",-589433933);$CLJS.hF=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.iF=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.jF=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.kF=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.lF=new $CLJS.M(null,"sum","sum",136986814);
$CLJS.mF=new $CLJS.M(null,"median","median",569566131);$CLJS.nF=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);Wga=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.oF=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.pF=new $CLJS.M(null,"trim","trim",774319767);$CLJS.qF=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.rF=new $CLJS.M(null,"ltrim","ltrim",1654269283);
$CLJS.sF=new $CLJS.M(null,"category","category",-593092832);$CLJS.tF=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.uF=new $CLJS.M(null,"/","/",1282502798);$CLJS.vF=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.wF=new $CLJS.M(null,"log","log",-1595516004);$CLJS.xF=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.yF=new $CLJS.M(null,"rtrim","rtrim",-661336449);var iE=$CLJS.Se($CLJS.Sg),nE;$CLJS.X($CLJS.DD,jE());$CLJS.ZA(iE,Wga,function(){return $CLJS.X($CLJS.DD,jE())});
$CLJS.zF=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.BD.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.mh.g("mbql.clause",$CLJS.Xg(d));$CLJS.X(f,e);$CLJS.Dd($CLJS.q(iE),d)||$CLJS.zh.j(iE,$CLJS.Zd,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
nE=function nE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return nE.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
nE.l=function(a,b){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,$CLJS.Wf.g(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ri,new $CLJS.h(null,1,[$CLJS.ns,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,a],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.mD],null)],null)],null)],null),b)],null)};nE.A=1;
nE.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};$CLJS.lE=function lE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return lE.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.lE.l=function(a,b){return $CLJS.Wf.g(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gi,new $CLJS.h(null,1,[$CLJS.ns,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.mD],null)],null),b)};$CLJS.lE.A=1;$CLJS.lE.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.kE=function kE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return kE.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.kE.l=function(a,b,c){c=$CLJS.E.g($CLJS.z(c),$CLJS.Tr)?$CLJS.be($CLJS.ed(c),$CLJS.Xe(2,c)):$CLJS.be(null,c);var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);a=$CLJS.P.j(a,b,d);return $CLJS.n(c)?$CLJS.zF.v(b,$CLJS.Tr,c,a):$CLJS.zF.g(b,a)};$CLJS.kE.A=2;
$CLJS.kE.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};