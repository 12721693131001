var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.field.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.time.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var Fya=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},Gya=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},z9=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.jA);b=$CLJS.J.g(b,$CLJS.ni);a=$CLJS.n(b)?b:a;return $CLJS.tz(a,$CLJS.yj)||$CLJS.tz(a,$CLJS.Vi)},Hya=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(f,k){return new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null)}),d);return function l(k){return new $CLJS.je(null,
function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.wd(m)){var t=$CLJS.jc(m),u=$CLJS.D(t),v=$CLJS.me(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);A=$CLJS.G1.v(a,b,A,d);A=$CLJS.J.j(e,A,-1);v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.pe($CLJS.re(v),l($CLJS.kc(m))):$CLJS.pe($CLJS.re(v),null)}v=$CLJS.z(m);v=$CLJS.G1.v(a,b,v,d);return $CLJS.be($CLJS.J.j(e,v,-1),l($CLJS.Ic(m)))}return null}},null,null)}(c)},A9=function(a,b){return new $CLJS.uh(function(){try{return $CLJS.v9.g(a,b)}catch(e){if(e instanceof
Error){var c=e,d=$CLJS.Cz($CLJS.ux);$CLJS.n($CLJS.Bz("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.Az("metabase.lib.js.metadata",d,$CLJS.oV("Error parsing %s objects: %s",$CLJS.H([a,$CLJS.nU(c)])),c):$CLJS.Az("metabase.lib.js.metadata",d,$CLJS.oV(c,$CLJS.H(["Error parsing %s objects: %s",a,$CLJS.nU(c)])),null));return null}throw e;}})},Iya=function(a,b){return function e(d){return new $CLJS.je(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.wd(f)){var k=$CLJS.jc(f),l=$CLJS.D(k),
m=$CLJS.me(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.hd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.TJ.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.pe($CLJS.re(m),e($CLJS.kc(f))):$CLJS.pe($CLJS.re(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?
$CLJS.E.g($CLJS.TJ.h(v),b):x}()))return $CLJS.be(v,e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.HD.h(a);return null==d?null:$CLJS.q(d)}())},Jya=function(a,b){return function e(d){return new $CLJS.je(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.wd(f)){var k=$CLJS.jc(f),l=$CLJS.D(k),m=$CLJS.me(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.hd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:
$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.TJ.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.pe($CLJS.re(m),e($CLJS.kc(f))):$CLJS.pe($CLJS.re(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.TJ.h(v),b):x}()))return $CLJS.be(v,e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.o9.h(a);
return null==d?null:$CLJS.q(d)}())},Kya=function(a,b){return function e(d){return new $CLJS.je(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.wd(f)){var k=$CLJS.jc(f),l=$CLJS.D(k),m=$CLJS.me(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.hd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.TJ.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.pe($CLJS.re(m),e($CLJS.kc(f))):
$CLJS.pe($CLJS.re(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.TJ.h(v),b):x}()))return $CLJS.be(v,e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.p9.h(a);return null==d?null:$CLJS.q(d)}())},B9=function(a,b,c,d){this.fg=a;this.Fg=b;this.metadata=c;this.mi=d;this.C=393216;this.I=0},Lya=function(a){return $CLJS.Va(a)?
Fya(a,function(b){return void 0!==b}):a},C9=function(a){return $CLJS.as($CLJS.Ey(Gya(a,function(b){return Lya(b)})),function(b){return $CLJS.fk.j($CLJS.uz(b,$CLJS.mh),$CLJS.Si,$CLJS.mh)})},D9=function(a,b){if($CLJS.mY(b))a=b;else{var c=new $CLJS.h(null,6,[$CLJS.t9,A9($CLJS.QO,b),$CLJS.r9,A9($CLJS.IV,b),$CLJS.HD,A9($CLJS.$E,b),$CLJS.m9,A9($CLJS.pM,b),$CLJS.o9,A9($CLJS.VE,b),$CLJS.p9,A9($CLJS.xE,b)],null),d=$CLJS.Cz($CLJS.tx);$CLJS.n($CLJS.Bz("metabase.lib.js.metadata",d))&&$CLJS.Az("metabase.lib.js.metadata",
d,$CLJS.pv.l($CLJS.H(["Created metadata provider for metadata"])),null);a=new B9(a,b,c,$CLJS.N)}return a},E9=function(a){a=$CLJS.Qz(a);const b=a.length-1;a=0<=b&&a.indexOf("?",b)==b?["is-",$CLJS.wz(a,/\?$/,"")].join(""):a;return $CLJS.xA.h?$CLJS.xA.h(a):$CLJS.xA.call(null,a)},H9=function(a){return $CLJS.td(a)?F9.h?F9.h(a):F9.call(null,a):$CLJS.sd(a)?G9.h?G9.h(a):G9.call(null,a):a instanceof $CLJS.M?$CLJS.Qz(a):a},I9=function(a,b){return $CLJS.OV($CLJS.OV((0,$CLJS.vX)($CLJS.y9($CLJS.Ey(a))),$CLJS.eP,
function(c){return $CLJS.uk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.BP,$CLJS.N],null),c]))}),$CLJS.vE,function(c){var d=function(){var e=$CLJS.HD.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.je(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.wd(m)){var t=$CLJS.jc(m),u=$CLJS.D(t),v=$CLJS.me(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);v.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,A,null],null));x+=1}else{t=!0;break a}return t?$CLJS.pe($CLJS.re(v),l($CLJS.kc(m))):$CLJS.pe($CLJS.re(v),
null)}v=$CLJS.z(m);return $CLJS.be(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$E,v,null],null),l($CLJS.Ic(m)))}return null}},null,null)}(b)}();return $CLJS.T.j(c,$CLJS.HD,$CLJS.K7(d))})},Mya=function(a,b){function c(l,m){l=$CLJS.Ta($CLJS.jA.h(m))?$CLJS.Vj.g(l,$CLJS.jA):l;m=$CLJS.Ta($CLJS.ni.h(m))?$CLJS.Vj.g(l,$CLJS.ni):l;return $CLJS.Ce(m)}var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return $CLJS.E.g(new $CLJS.Q(null,3,5,
$CLJS.R,[d,e,c(a,b)],null),new $CLJS.Q(null,3,5,$CLJS.R,[f,k,c(b,a)],null))},Nya=function(a,b){var c=$CLJS.zV(a,b);return $CLJS.se($CLJS.cf.g(function(d){return $CLJS.T.j(d,$CLJS.DY,!0)},$CLJS.H_.j(a,b,c)))},Oya=function(a){return $CLJS.n(function(){var b=$CLJS.z(a),c=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.xE,null,$CLJS.VE,null],null),null);return c.h?c.h(b):c.call(null,b)}())?$CLJS.jk.j(a,0,2):$CLJS.fk.v(a,2,$CLJS.as,function(b){return $CLJS.ie(b)?$CLJS.Qz(b):b})},J9=function(a){return $CLJS.PX.h($CLJS.fk.j($CLJS.Dy(a,
$CLJS.H([$CLJS.ii,!0])),0,$CLJS.mh))},Pya=function(a){var b=$CLJS.Va(a)?a:null;return $CLJS.n(b)?(a=$CLJS.x9.h?$CLJS.x9.h(b):$CLJS.x9.call(null,b),$CLJS.E.g($CLJS.DJ.h(a),$CLJS.dK)?(b=J9(b.field_ref),$CLJS.T.j(a,$CLJS.o0,$CLJS.fd(b))):a):a},K9=function(a,b){var c=null==a?null:$CLJS.z(a);c=null==c?null:$CLJS.E.g(c,$CLJS.PE);return $CLJS.n(c)?$CLJS.T.j(b,$CLJS.o0,$CLJS.fd(a)):b},L9=function(a,b,c){b=$CLJS.X8.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,
1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[k,l],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[l,k],null)}),b);var d=$CLJS.OX,e=$CLJS.NX;$CLJS.OX=a;$CLJS.NX=b;try{var f=function(){var k=c.field_ref;return $CLJS.n(k)?J9(k):null}();return K9(f,$CLJS.x9.h?$CLJS.x9.h(c):$CLJS.x9.call(null,c))}finally{$CLJS.NX=e,$CLJS.OX=d}},M9=function(a){return function(b){var c=
function(){var e=a.h?a.h(b):a.call(null,b);return $CLJS.x9.h?$CLJS.x9.h(e):$CLJS.x9.call(null,e)}(),d=function(){var e=$CLJS.c1.h(c);return $CLJS.n(e)?J9(e):null}();return new $CLJS.h(null,3,[$CLJS.Ai,K9(d,c),$CLJS.uK,d,$CLJS.ej,b.value],null)}};$CLJS.g=B9.prototype;$CLJS.g.P=function(a,b){return new B9(this.fg,this.Fg,this.metadata,b)};$CLJS.g.O=function(){return this.mi};$CLJS.g.ni=$CLJS.zc;
$CLJS.g.vg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.o9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.wg=function(a,b){return Jya(this.metadata,b)};$CLJS.g.Ag=function(a,b){a=this.metadata;a=null==a?null:$CLJS.r9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ug=function(a,b){return Iya(this.metadata,b)};
$CLJS.g.tg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.HD.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.yg=function(a,b){return Kya(this.metadata,b)};$CLJS.g.zg=function(a,b){return $CLJS.c9($CLJS.c9(this.Fg,"settings"),$CLJS.Xg(b))};$CLJS.g.rg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.m9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.g.xg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.p9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.sg=function(){var a=this.metadata,b=this.fg;a=null==a?null:$CLJS.t9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};var N9=function N9(a){switch(arguments.length){case 1:return N9.h(arguments[0]);case 2:return N9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.extract_template_tags",N9);N9.h=function(a){return N9.g(a,$CLJS.N)};N9.g=function(a,b){return $CLJS.wk($CLJS.Axa.l($CLJS.H([a,C9(b)])))};N9.A=2;
var O9=function O9(a){switch(arguments.length){case 2:return O9.g(arguments[0],arguments[1]);case 3:return O9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.query",O9);O9.g=function(a,b){return $CLJS.$8.l($CLJS.H([a,b]))};
O9.j=function(a,b,c){c=$CLJS.PX.h((0,$CLJS.vX)($CLJS.r1($CLJS.e7(c),$CLJS.Si,$CLJS.vE)));var d=$CLJS.Cz($CLJS.tx);$CLJS.n($CLJS.Bz("metabase.lib.js",d))&&$CLJS.Az("metabase.lib.js",d,$CLJS.oV("query map: %s",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),null);return $CLJS.$8.l($CLJS.H([D9(a,b),c]))};O9.A=3;
var P9=function P9(a){return $CLJS.ie(a)?[$CLJS.ee(a),"/",$CLJS.Xg(a)].join(""):$CLJS.td(a)?$CLJS.as(a,P9):$CLJS.sd(a)?$CLJS.cf.g(P9,a):a},F9=$CLJS.Pz(function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);d=E9(d);c=H9.h?H9.h(c):H9.call(null,c);b[d]=c;return b},{},a)}),G9=$CLJS.Pz(function(a){return $CLJS.se($CLJS.cf.g(H9,a))}),Q9=function Q9(a){switch(arguments.length){case 1:return Q9.h(arguments[0]);case 2:return Q9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",
$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.order_by_clause",Q9);Q9.h=function(a){return Q9.g(a,$CLJS.XE)};Q9.g=function(a,b){return $CLJS.Dxa.l($CLJS.H([$CLJS.Z8.l($CLJS.H([$CLJS.Dy(a,$CLJS.H([$CLJS.ii,!0]))])),$CLJS.mh.h(b)]))};Q9.A=2;var R9=function R9(a){switch(arguments.length){case 2:return R9.g(arguments[0],arguments[1]);case 3:return R9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.ya("metabase.lib.js.available_binning_strategies",R9);R9.g=function(a,b){return $CLJS.se($CLJS.Y8.l($CLJS.H([a,b])))};R9.j=function(a,b,c){return $CLJS.se($CLJS.Y8.l($CLJS.H([a,b,c])))};R9.A=3;var S9=function S9(a){switch(arguments.length){case 2:return S9.g(arguments[0],arguments[1]);case 3:return S9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.available_temporal_buckets",S9);
S9.g=function(a,b){return $CLJS.se($CLJS.b9.l($CLJS.H([a,b])))};S9.j=function(a,b,c){return $CLJS.se($CLJS.b9.l($CLJS.H([a,b,c])))};S9.A=3;
var T9=function T9(a,b){return $CLJS.vd(a)&&$CLJS.vd(b)&&$CLJS.E.l($CLJS.z(a),$CLJS.z(b),$CLJS.H([$CLJS.$E]))?Mya(a,b):$CLJS.td(a)&&$CLJS.td(b)?$CLJS.E.g($CLJS.Wg($CLJS.Ng(a)),$CLJS.Wg($CLJS.Ng(b)))&&$CLJS.Me(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.J.g(b,e);return T9.g?T9.g(d,e):T9.call(null,d,e)},a):$CLJS.sd(a)&&$CLJS.sd(b)?$CLJS.E.g($CLJS.D(a),$CLJS.D(b))&&$CLJS.Me($CLJS.gl,$CLJS.cf.j(T9,a,b)):$CLJS.E.g(a,b)},U9=function U9(a){switch(arguments.length){case 2:return U9.g(arguments[0],
arguments[1]);case 3:return U9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.query_EQ_",U9);U9.g=function(a,b){return U9.j(a,b,null)};U9.j=function(a,b,c){a=I9(a,c);b=I9(b,c);return T9(a,b)};U9.A=3;
var V9=function V9(a){switch(arguments.length){case 1:return V9.h(arguments[0]);case 2:return V9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.aggregation_clause",V9);V9.h=function(a){return $CLJS.W8.l($CLJS.H([a]))};V9.g=function(a,b){return $CLJS.W8.l($CLJS.H([a,b]))};V9.A=2;
var W9=function W9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return W9.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.ya("metabase.lib.js.filter_clause",W9);W9.l=function(a,b,c){return $CLJS.P.v($CLJS.Pwa,a,b,c)};W9.A=2;W9.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};
var X9=function X9(a){switch(arguments.length){case 3:return X9.j(arguments[0],arguments[1],arguments[2]);case 4:return X9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.with_different_database",X9);X9.j=function(a,b,c){return X9.v(a,b,c,null)};X9.v=function(a,b,c,d){return $CLJS.yxa.l($CLJS.H([a,D9(b,c),$CLJS.Dy(d,$CLJS.H([$CLJS.ii,!0]))]))};X9.A=4;
var Qya=M9(function(a){return a.col}),Rya=M9(function(a){return a.column}),Y9=function Y9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Y9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.w(c.slice(3),0,null):null)};$CLJS.ya("metabase.lib.js.drill_thru",Y9);Y9.l=function(a,b,c,d){return $CLJS.P.M($CLJS.uwa,a,b,c,d)};Y9.A=3;
Y9.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){return $CLJS.UZ($CLJS.mh.g("returned-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return Nya(a,b)})},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.X8.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[k,l],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);
l=$CLJS.J.g(l,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[l,k],null)}),b);var d=$CLJS.OX,e=$CLJS.NX;$CLJS.OX=a;$CLJS.NX=b;try{var f=$CLJS.EX.h(c);return $CLJS.wk(function(){var k;if(k=$CLJS.vd(f)){k=$CLJS.z(f);var l=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ej,null,$CLJS.oL,null],null),null);k=l.h?l.h(k):l.call(null,k)}return $CLJS.n(k)?$CLJS.J.g(f,1):f}())}finally{$CLJS.NX=e,$CLJS.OX=d}},drop_stage_if_empty:function(a){return $CLJS.Oxa.l($CLJS.H([a]))},extract_template_tags:N9,engine:function(a){return $CLJS.Xg($CLJS.txa.l($CLJS.H([a])))},
filter_operator:function(a,b,c){return $CLJS.Qwa.l($CLJS.H([a,b,c]))},binning:function(a){return $CLJS.jwa.l($CLJS.H([a]))},query:O9,columns_group_columns:function(a){return $CLJS.se($CLJS.pwa.l($CLJS.H([a])))},join_fields:function(a){a=$CLJS.dxa.l($CLJS.H([a]));return a instanceof $CLJS.M?$CLJS.Qz(a):$CLJS.se(a)},aggregate:function(a,b,c){return $CLJS.ewa.l($CLJS.H([a,b,$CLJS.Dy(c,$CLJS.H([$CLJS.ii,!0]))]))},join_conditions:function(a){return $CLJS.se($CLJS.cxa.l($CLJS.H([a])))},query_EQ_:U9,temporal_bucket:function(a){return $CLJS.Txa.l($CLJS.H([a]))},
available_drill_thrus:function(a,b,c,d,e,f){var k=$CLJS.X8.l($CLJS.H([a,b])),l=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[v,x],null)}),k);k=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[x,v],null)}),k);var m=$CLJS.OX,t=$CLJS.NX;$CLJS.OX=l;$CLJS.NX=k;try{var u=function(){var v=$CLJS.n(c)?
c.field_ref:c;return $CLJS.n(v)?J9(v):null}();return $CLJS.se($CLJS.twa.l($CLJS.H([a,b,$CLJS.uk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Ai,$CLJS.n(c)?K9(u,$CLJS.x9.h?$CLJS.x9.h(c):$CLJS.x9.call(null,c)):null,$CLJS.uK,u,$CLJS.ej,void 0===d?null:null==d?$CLJS.GK:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.E5,$CLJS.ek.g(Qya,e)],null):null,$CLJS.n($CLJS.Ce(f))?new $CLJS.h(null,1,[$CLJS.BK,$CLJS.ek.g(Rya,f)],null):null]))])))}finally{$CLJS.NX=t,$CLJS.OX=m}},has_clauses:function(a,b){return $CLJS.Pxa.l($CLJS.H([a,
b]))},remove_clause:function(a,b,c){return $CLJS.Hxa.l($CLJS.H([a,b,$CLJS.Z8.l($CLJS.H([$CLJS.Dy(c,$CLJS.H([$CLJS.ii,!0]))]))]))},source_table_or_card_id:function(a){var b=$CLJS.MZ(a);if($CLJS.n(b))return b;a=$CLJS.w1(a);return null==a?null:["card__",$CLJS.p.h(a)].join("")},legacy_ref:function(a){return $CLJS.wk(Oya($CLJS.EX.h($CLJS.a9.l($CLJS.H([a])))))},replace_clause:function(a,b,c,d){return $CLJS.Kxa.l($CLJS.H([a,b,$CLJS.Z8.l($CLJS.H([$CLJS.Dy(c,$CLJS.H([$CLJS.ii,!0]))])),$CLJS.Z8.l($CLJS.H([$CLJS.Dy(d,
$CLJS.H([$CLJS.ii,!0]))]))]))},drop_stage:function(a){return $CLJS.Nxa.l($CLJS.H([a]))},join_lhs_display_name:function(a,b,c,d){return $CLJS.exa.l($CLJS.H([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.Gwa.l($CLJS.H([a,b,c]))},segment_metadata:function(a,b){return $CLJS.J8(a,b)},pivot_types:function(a){return $CLJS.se($CLJS.cf.g($CLJS.Xg,$CLJS.wwa.l($CLJS.H([a]))))},native_extras:function(a){return $CLJS.wk($CLJS.wxa.l($CLJS.H([a])))},available_temporal_buckets:S9,with_binning:function(a,b){return $CLJS.kwa.l($CLJS.H([a,
b]))},append_stage:function(a){return $CLJS.Mxa.l($CLJS.H([a]))},join:function(a,b,c){return $CLJS.Xwa.l($CLJS.H([a,b,c]))},aggregations:function(a,b){return $CLJS.se($CLJS.X8.l($CLJS.H([a,b])))},drill_thru:Y9,raw_native_query:function(a){return $CLJS.qxa.l($CLJS.H([a]))},filterable_column_operators:function(a){return $CLJS.se($CLJS.Owa.l($CLJS.H([a])))},with_temporal_bucket:function(a,b){return $CLJS.Uxa.l($CLJS.H([a,b]))},valid_filter_for_QMARK_:function(a,b){return $CLJS.tZ(a)&&$CLJS.tZ(b)||$CLJS.BZ(a)&&
$CLJS.BZ(b)||$CLJS.rZ(a)&&$CLJS.rZ(b)||$CLJS.tz($CLJS.jA.h(a),$CLJS.jA.h(b))},with_join_conditions:function(a,b){return $CLJS.lxa.l($CLJS.H([a,$CLJS.Dy(b,$CLJS.H([$CLJS.ii,!0]))]))},update_lat_lon_filter:function(a,b,c,d,e){e=$CLJS.Dy(e,$CLJS.H([$CLJS.ii,!0]));c=L9(a,b,c);d=L9(a,b,d);return $CLJS.Twa.l($CLJS.H([a,b,c,d,e]))},available_metrics:function(a){return $CLJS.se($CLJS.oxa.l($CLJS.H([a])))},with_different_database:X9,with_fields:function(a,b,c){return $CLJS.Kwa.l($CLJS.H([a,b,c]))},is_column_metadata:function(a){return $CLJS.td(a)&&
$CLJS.E.g($CLJS.BJ,$CLJS.fj.h(a))},filter_args_display_name:function(a,b,c){return $CLJS.Fwa.l($CLJS.H([a,b,c]))},join_clause:function(a,b){return $CLJS.Ywa.l($CLJS.H([a,b]))},aggregation_clause:V9,external_op:function(a){a=$CLJS.rwa.l($CLJS.H([a]));var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.kD);var c=$CLJS.J.g(b,$CLJS.Mi);b=$CLJS.J.g(b,$CLJS.Er);return{operator:a,options:$CLJS.wk(c),args:$CLJS.se(b)}},with_join_fields:function(a,b){return $CLJS.jxa.l($CLJS.H([a,"string"===typeof b?$CLJS.mh.h(b):b]))},
visible_columns:function(a,b){return $CLJS.UZ($CLJS.mh.g("visible-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){var c=$CLJS.zV(a,b),d=$CLJS.J_.j(a,b,c);c=$CLJS.H_.j(a,b,c);return $CLJS.se($CLJS.T2(a,b,d,c))})},update_numeric_filter:function(a,b,c,d,e){c=L9(a,b,c);return $CLJS.Uwa.l($CLJS.H([a,b,c,d,e]))},available_binning_strategies:R9,update_temporal_filter:function(a,b,c,d,e){c=L9(a,b,c);return $CLJS.Vwa.l($CLJS.H([a,b,c,d,e]))},find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=
$CLJS.PE.h($CLJS.zV(a,b)),f=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[u,v],null)}),e);e=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null)}),e);var k=$CLJS.OX,l=$CLJS.NX;$CLJS.OX=f;$CLJS.NX=e;try{var m=$CLJS.ek.g(Pya,c),t=$CLJS.cf.g(J9,d);return $CLJS.se(Hya(a,b,t,m))}finally{$CLJS.NX=
l,$CLJS.OX=k}},expression:function(a,b,c,d){return $CLJS.zwa.l($CLJS.H([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.mh.h(a):a;b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.Sxa.l($CLJS.H([a,b]))},order_bys:function(a,b){return $CLJS.se($CLJS.Exa.l($CLJS.H([a,b])))},display_info:function(a,b,c){return $CLJS.UZ($CLJS.mh.g("display-info-outer",["stage-",$CLJS.p.h(b)].join("")),c,function(d){return H9($CLJS.mxa.l($CLJS.H([$CLJS.l3(a,b),b,d])))})},fieldable_columns:function(a,
b){return $CLJS.UZ($CLJS.mh.g("fieldable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.se($CLJS.Hwa.l($CLJS.H([a,b])))})},template_tags:function(a){return $CLJS.wk($CLJS.sxa.l($CLJS.H([a])))},selected_aggregation_operators:function(a,b){return $CLJS.se($CLJS.iwa.l($CLJS.H([$CLJS.y(a),b])))},order_by_clause:Q9,filterable_columns:function(a,b){return $CLJS.UZ($CLJS.mh.g("filterable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.se($CLJS.Nwa.l($CLJS.H([a,
b])))})},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.se($CLJS.axa.l($CLJS.H([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.bxa.l($CLJS.H([a,b,c,d]))},filters:function(a,b){return $CLJS.se($CLJS.Mwa.l($CLJS.H([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.se($CLJS.Zwa.l($CLJS.H([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.U0(a,b)},with_expression_name:function(a,b){return $CLJS.Cwa.l($CLJS.H([a,b]))},
orderable_columns:function(a,b){return $CLJS.UZ($CLJS.mh.g("orderable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.se($CLJS.z7.g(a,b))})},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Rwa.l($CLJS.H([a,b,$CLJS.Dy(c,$CLJS.H([$CLJS.ii,!0]))]))},suggestedName:function(a){return $CLJS.nxa.l($CLJS.H([a]))},find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.xwa.l($CLJS.H([a,b,c,d]))},has_write_permission:function(a){return $CLJS.zxa.l($CLJS.H([a]))},metadataProvider:D9,
filter_clause:W9,fields:function(a,b){return $CLJS.se($CLJS.Iwa.l($CLJS.H([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.se($CLJS.hwa.l($CLJS.H([a,b])))},breakouts:function(a,b){return $CLJS.se($CLJS.owa.l($CLJS.H([a,b])))},joinable_columns:function(a,b,c){return $CLJS.se($CLJS.gxa.l($CLJS.H([a,b,c])))},group_columns:function(a){return $CLJS.se($CLJS.qwa.l($CLJS.H([a])))},describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.Qxa.l($CLJS.H([a,
b]))},breakout_column:function(a,b,c){return $CLJS.mwa.l($CLJS.H([a,b,c]))},joins:function(a,b){return $CLJS.se($CLJS.hxa.l($CLJS.H([a,b])))},native_query:function(a,b,c){return $CLJS.pxa.l($CLJS.H([D9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.se($CLJS.Wwa.l($CLJS.H([a,b])))},legacy_query:function(a){return $CLJS.wk.l(P9($CLJS.EX.h(a)),$CLJS.H([$CLJS.Ij,$CLJS.Qz]))},stage_count:function(a){return $CLJS.Fxa.l($CLJS.H([a]))},expression_clause:function(a,b,c){return $CLJS.Z8.l($CLJS.H([$CLJS.Dwa.l($CLJS.H([$CLJS.mh.h(a),
b,$CLJS.Dy(c,$CLJS.H([$CLJS.ii,!0]))]))]))},find_matching_column:function(a,b,c,d){return $CLJS.ywa.l($CLJS.H([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.xxa.l($CLJS.H([a,$CLJS.Dy(b,$CLJS.H([$CLJS.ii,!0]))]))},join_strategy:function(a){return $CLJS.fxa.l($CLJS.H([a]))},picker_info:function(a,b){var c=$CLJS.fj.h(b);switch(c instanceof $CLJS.M?c.S:null){case "metadata/table":return{databaseId:$CLJS.QO.h(a),tableId:$CLJS.Gi.h(b)};case "metadata/card":return{databaseId:$CLJS.QO.h(a),tableId:["card__",
$CLJS.p.h($CLJS.Gi.h(b))].join(""),cardId:$CLJS.Gi.h(b),isModel:$CLJS.uY.h(b)};default:return a=$CLJS.Cz($CLJS.wx),$CLJS.n($CLJS.Bz("metabase.lib.js",a))&&$CLJS.Az("metabase.lib.js",a,$CLJS.pv.l($CLJS.H(["Cannot provide picker-info for",$CLJS.fj.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.Ewa.l($CLJS.H([a,b,c]));return $CLJS.oU(function(d){if($CLJS.td(d)&&$CLJS.E.g($CLJS.l8,$CLJS.fj.h(d))){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.kD);var f=$CLJS.J.g(e,$CLJS.Mi);e=$CLJS.J.g(e,$CLJS.Er);
return{operator:$CLJS.Xg(d),options:$CLJS.wk($CLJS.Al(f,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hF,$CLJS.Fk],null))),args:$CLJS.se($CLJS.cf.g(function(k){return k instanceof $CLJS.M?$CLJS.Qz(k):k},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,b,c){b=$CLJS.X8.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[l,m],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Cl(function(l,
m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.oD);return new $CLJS.Q(null,2,5,$CLJS.R,[m,l],null)}),b);var d=$CLJS.OX,e=$CLJS.NX;$CLJS.OX=a;$CLJS.NX=b;try{var f=$CLJS.Dy(c,$CLJS.H([$CLJS.ii,!0])),k=$CLJS.z($CLJS.wX(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vE,$CLJS.PE],null),new $CLJS.Q(null,1,5,$CLJS.R,[f],null)));return $CLJS.PX.h(k)}finally{$CLJS.NX=e,$CLJS.OX=d}},format_relative_date_range:function(a,b,c,d,e){b=$CLJS.mh.h(b);d=null==d?null:$CLJS.mh.h(d);e=$CLJS.Dy(e,$CLJS.H([$CLJS.ii,
!0]));return $CLJS.W1((0,$CLJS.FH)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.se($CLJS.gwa.l($CLJS.H([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.Swa.l($CLJS.H([a,b,c]))},available_segments:function(a,b){return $CLJS.se($CLJS.Lxa.l($CLJS.H([a,b])))},join_condition_operators:function(a,b,c,d){return $CLJS.se($CLJS.$wa.l($CLJS.H([a,b,c,d])))},aggregation_column:function(a,b,c){return $CLJS.fwa.l($CLJS.H([a,b,c]))},expressionable_columns:function(a,
b,c){return $CLJS.UZ($CLJS.mh.g("expressionable-columns",["stage-",$CLJS.p.h(b),"-",$CLJS.p.h(c)].join("")),a,function(){return $CLJS.se($CLJS.Bwa.l($CLJS.H([a,b,c])))})},filter:function(a,b,c){return $CLJS.Lwa.l($CLJS.H([a,b,$CLJS.Dy(c,$CLJS.H([$CLJS.ii,!0]))]))},metric_metadata:function(a,b){return $CLJS.A3(a,b)},change_direction:function(a,b){return $CLJS.Bxa.l($CLJS.H([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.se($CLJS.vwa.l($CLJS.H([a,$CLJS.mh.h(b)])))},filter_drill_details:function(a){var b=
$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.vE);var c=$CLJS.J.g(b,$CLJS.Ai),d=$CLJS.J.g(b,$CLJS.yK);b=$CLJS.J.g(b,$CLJS.ej);return{column:c,query:a,stageIndex:d,value:$CLJS.E.g(b,$CLJS.GK)?null:b}},remove_field:function(a,b,c){return $CLJS.Jwa.l($CLJS.H([a,b,c]))},with_template_tags:function(a,b){return $CLJS.uxa.l($CLJS.H([a,C9(b)]))},expressions:function(a,b){return $CLJS.se($CLJS.Awa.l($CLJS.H([a,b])))},suggested_join_conditions:function(a,b,c){return $CLJS.se($CLJS.ixa.l($CLJS.H([a,b,c])))},with_join_strategy:function(a,
b){return $CLJS.kxa.l($CLJS.H([a,b]))},order_by:function(a,b,c,d){return $CLJS.Cxa.l($CLJS.H([a,b,c,$CLJS.mh.h(d)]))},field_values_search_info:function(a,b){var c=$CLJS.fk.j;if($CLJS.n(b)){var d=$CLJS.Gi.h(b),e=$CLJS.Gi.h;var f=$CLJS.yZ(b)?b:null;$CLJS.n(f)?a=f:(f=$CLJS.rz(b,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QJ,$CLJS.MJ],null)),a=$CLJS.n(f)?$CLJS.L0(a,f):null,a=$CLJS.n(a)?a:b);a=z9(a)?a:null;e=e.call($CLJS.Gi,a);d=$CLJS.jl(d)?d:null;e=$CLJS.jl(e)?e:null;$CLJS.n(b)?(b=$CLJS.O(b),a=$CLJS.J.g(b,$CLJS.KY),
b=$CLJS.E.g(a,$CLJS.MY)?$CLJS.dj:$CLJS.n(a)?a:z9(b)?$CLJS.rY:$CLJS.Vy):b=$CLJS.Vy;b=new $CLJS.h(null,3,[$CLJS.MJ,d,$CLJS.X6,e,$CLJS.KY,b],null)}else b=null;return $CLJS.wk($CLJS.uz(c.call($CLJS.fk,b,$CLJS.KY,$CLJS.Xg),E9))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.mh.h(a):a;b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.Rxa.l($CLJS.H([a,b]))},breakout:function(a,b,c){return $CLJS.lwa.l($CLJS.H([a,b,$CLJS.a9.l($CLJS.H([c]))]))},database_id:function(a){return $CLJS.swa.l($CLJS.H([a]))},
required_native_extras:function(a,b){return $CLJS.se($CLJS.cf.g($CLJS.Qz,$CLJS.vxa.l($CLJS.H([D9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.Ixa.l($CLJS.H([a,b,c]))},joined_thing:function(a,b){return $CLJS.f3(a,b)},legacy_column__GT_metadata:L9,with_native_query:function(a,b){return $CLJS.rxa.l($CLJS.H([a,b]))},breakoutable_columns:function(a,b){return $CLJS.UZ($CLJS.mh.g("breakoutable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.se($CLJS.nwa.l($CLJS.H([a,b])))})},
rename_join:function(a,b,c,d){return $CLJS.Jxa.l($CLJS.H([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.Gxa.l($CLJS.H([a,b]))}};