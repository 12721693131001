var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var LK,MK,NK,oja,pja,RK,qja,UK,VK,WK,XK,rja,YK,ZK,aL,bL,OK,cL,sja,dL,tja,eL,fL,gL,uja,hL,iL,vja,jL,wja,kL,lL,xja,nL,yja,zja,Aja,pL,qL,rL,sL,tL,uL,vL,wL,Bja,xL,yL,zL,BL,CL,Cja,DL,EL,FL,GL,Dja,HL,IL,JL,KL,Eja,LL,ML,NL,OL,Fja,PL,Gja,QL,RL,SL,Hja,TL,UL,VL,WL,YL,ZL,Ija,$L,aM,Jja,bM,cM,dM,eM,fM,Kja,gM,Lja,hM,iM,kM,Mja,lM,mM,nM,Nja,qM,rM,Oja,Pja,sM,uM,vM,wM,Qja,zM,AM,Rja,Sja,Tja,BM,CM,DM,EM,FM,GM,Uja,HM,Vja,IM,JM,LM,NM,OM,PM,QM,Wja,Xja,SM,Yja,Zja,TM,UM,VM,WM,XM,$ja,YM,aka,bka,ZM,$M,aN,bN,cN,
cka,dka,eka,dN,fka,eN,gka,gN,hN,hka,lN,mN,nN,oN,ika,rN,jka,kka,sN,tN,uN,lka,vN,wN,xN,yN,mka,AN,BN,CN,DN,EN,nka,FN,GN,oka,HN,IN,JN,KN,LN,MN,ON,pka,PN,qka,QN,RN,SN,rka,ska,tka,UN,VN,WN,uka,XN,YN,ZN,$N,vka,wka,bO,xka,yka,cO,dO,eO,fO,gO,zka,hO,iO,jO,kO,Aka,lO,mO,nO,oO,qO,Bka,rO,sO,Cka,tO,uO,vO,Dka,wO,Eka,yO,Fka,zO,AO,BO,CO,DO,EO,FO,Gka,Hka,Ika,GO,Jka,HO,IO,Kka,Lka,JO,KO,LO,Mka,MO,Nka,NO,Oka,Pka,OO,Qka,Rka,Ska,RO,SO,Tka,TO,UO,WO,Uka,XO,Vka,YO,ZO,$O,Wka,Xka,aP,bP,cP,dP,Yka,fP,gP,Zka,hP,iP,jP,kP,lP,mP,nP,
oP,$ka,pP,qP,ala,rP,bla,sP,tP,uP,cla,vP,wP,dla,ela,xP,yP,zP,fla,gla,AP,hla,ila,jla,CP,kla,EP,lla,mla,FP,GP,nla,ola,pla,HP,IP,JP,KP,LP,MP,NP,qla,OP,PP,rla,QP,sla,tla,ula,RP,SP,TP,vla,UP,VP,wla,WP,xla,XP,yla,zla,ZP,$P,aQ,bQ,cQ,Ala,dQ,eQ,Bla,fQ,gQ,hQ,iQ,jQ,kQ,lQ,mQ,Cla,nQ,oQ,qQ,rQ,sQ,tQ,uQ,vQ,wQ,xQ,yQ,zQ,Dla,AQ,BQ,CQ,DQ,EQ,Ela,FQ,GQ,HQ,IQ,Fla,KQ,Gla,Hla,MQ,Ila,NQ,Jla,OQ,Kla,Lla,PQ,Mla,QQ,SQ,TQ,Nla,Ola,XQ,YQ,Pla,Qla,ZQ,$Q,aR,bR,cR,Rla,dR,eR,gR,hR,Sla;
$CLJS.KK=function(a){var b=$CLJS.zn.g(a,null),c=$CLJS.bE(b,$CLJS.vr,function(d){return $CLJS.Em(d,$CLJS.xf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.Nk,b,$CLJS.ej,k,$CLJS.Br,l],null):null}function e(k){return f.j(k,$CLJS.xf,$CLJS.xf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
LK=function(a,b){return $CLJS.td(a)?$CLJS.J.g(a,b):a};MK=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Hm($CLJS.zn.g($CLJS.Nk.h(a),null));if($CLJS.n(b)){var e=LK($CLJS.ys.h(b),c);$CLJS.n(e)?(e=$CLJS.xp(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:LK($CLJS.ns.h(b),c)}return null};NK=function(a,b){return $CLJS.rd(a)||$CLJS.el(a)?$CLJS.J.g(a,b):$CLJS.sd(a)?$CLJS.J.g($CLJS.yf(a),b):null};
oja=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Nk),d=$CLJS.J.g(a,$CLJS.Si);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Br,$CLJS.lda),f=$CLJS.J.j(b,$CLJS.gda,!0),k=$CLJS.J.g(b,$CLJS.zs),l=$CLJS.J.j(b,$CLJS.ws,$CLJS.us),m=MK(a,$CLJS.zp.h(c),k,b);if($CLJS.n(m))return m;m=MK(a,$CLJS.Nr.h(c),k,b);if($CLJS.n(m))return m;m=MK(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=MK(a,function(){var t=$CLJS.ln.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=MK(a,$CLJS.zp.h(c),
l,b);if($CLJS.n(m))return m;m=MK(a,$CLJS.Nr.h(c),l,b);if($CLJS.n(m))return m;d=MK(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=MK(a,function(){var t=$CLJS.ln.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?MK(a,e.h?e.h($CLJS.ss):e.call(null,$CLJS.ss),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?MK(a,e.h?e.h($CLJS.ss):e.call(null,$CLJS.ss),l,b):f};
pja=function(a,b,c){a=$CLJS.R;var d=$CLJS.Wf.g;var e=$CLJS.Kk.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.Nk),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.zs);l=$CLJS.J.j(l,$CLJS.ws,$CLJS.us);k=$CLJS.zp.h(k);f=LK($CLJS.vs.h(k),f);f=$CLJS.n(f)?f:LK($CLJS.vs.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.Q(null,2,5,a,[d,oja(b,c)],null)};
$CLJS.PK=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.ej);a=$CLJS.J.g(b,$CLJS.Br);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.hda,$CLJS.yi),f=$CLJS.J.j(d,$CLJS.jda,pja);return $CLJS.n(a)?$CLJS.cb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.T.j(l,$CLJS.yi,m);l=e.h?e.h(l):e.call(null,l);return OK(k,c,t,l)},null,a):null};
RK=function(a,b,c){var d=$CLJS.rz($CLJS.q($CLJS.PC),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.zh.v($CLJS.PC,$CLJS.QK,new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null),c);return c};$CLJS.SK=function(a){return RK($CLJS.Kj,a,function(){return $CLJS.cE.h(a)})};qja=function(){var a=TK;return RK($CLJS.vr,a,function(){var b=$CLJS.cE.h(a),c=$CLJS.KK(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
UK=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,$CLJS.ie(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,a],null):a],null)};VK=function(a){if($CLJS.vd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,UK(c)],null)],null);case "rest":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Iq,UK(c)],null);default:return UK(a)}}else return UK(a)};
WK=function(a,b){var c=$CLJS.vd(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.qd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
XK=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(WK,a)],null),$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ri,new $CLJS.Q(null,2,5,$CLJS.R,["tag",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,a],null)],null)],null),function(){return function e(d){return new $CLJS.je(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.wd(f)){var k=$CLJS.jc(f),l=$CLJS.D(k),m=$CLJS.me(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.Q(null,2,5,$CLJS.R,[v,VK(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.pe($CLJS.re(m),e($CLJS.kc(f))):$CLJS.pe($CLJS.re(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[m,VK(k)],null),e($CLJS.Ic(f)))}return null}},null,null)}($CLJS.Qr(2,2,b))}())],null)};rja=function(a){return $CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
YK=function(a){return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.wi,rja,$CLJS.ns,["valid instance of one of these MBQL clauses: ",$CLJS.bs(", ",$CLJS.cf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.je(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.jc(e),k=$CLJS.D(f),l=$CLJS.me(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,
$CLJS.ie(t)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.pe($CLJS.re(l),d($CLJS.kc(e))):$CLJS.pe($CLJS.re(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.ie(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,f],null):f],null),d($CLJS.Ic(e)))}return null}},null,null)}(a)}())};
ZK=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.JK],null)],null)};$CLJS.$K={};aL={};bL={};$CLJS.QK=function QK(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.T.j;var k=$CLJS.J.g(a,b);c=QK.j?QK.j(k,f,c):QK.call(null,k,f,c);a=e.call($CLJS.T,a,b,c)}else a=$CLJS.T.j(a,b,c);return a};
OK=function OK(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=NK(b,c);f=$CLJS.n(a)?a:$CLJS.sd(b)?$CLJS.xf:$CLJS.ud(b)?$CLJS.N:$CLJS.gd(b);return $CLJS.n($CLJS.n(c)?$CLJS.ts.h($CLJS.ld(f)):c)?a:$CLJS.n(c)?(b=NK(f,c),d=OK.v?OK.v(b,l,k,d):OK.call(null,b,l,k,d),$CLJS.jl(c)&&$CLJS.sd(f)&&c>$CLJS.D(f)&&(b=$CLJS.Ze(c-$CLJS.D(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.zd(f)?b:$CLJS.Wf.g($CLJS.gd(f),b)),null==f||$CLJS.el(f)?$CLJS.T.j(f,c,d):$CLJS.rd(f)?$CLJS.Zd.g(f,d):$CLJS.P.g($CLJS.V,$CLJS.T.j($CLJS.yf(f),
c,d))):$CLJS.td(a)?(c=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ida],null),OK.v?OK.v(f,b,c,d):OK.call(null,f,b,c,d)):$CLJS.n($CLJS.ts.h($CLJS.ld(f)))?$CLJS.Zd.g(f,d):$CLJS.vd($CLJS.Ce(f))?f:$CLJS.kd(new $CLJS.Q(null,1,5,$CLJS.R,[d],null),new $CLJS.h(null,1,[$CLJS.ts,!0],null))};cL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);sja=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
dL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);tja=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);eL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);fL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);gL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
uja=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);hL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);iL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);vja=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);jL=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
wja=new $CLJS.M(null,"from","from",1815293044);kL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);lL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);xja=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.mL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);nL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
yja=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);zja=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.oL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Aja=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);pL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
qL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);rL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);sL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);tL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);uL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
vL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);wL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Bja=new $CLJS.M(null,"lon-max","lon-max",1590224717);xL=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);yL=new $CLJS.r(null,"stddev","stddev",775056588,null);zL=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.AL=new $CLJS.M(null,"snippet","snippet",953581994);BL=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);CL=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Cja=new $CLJS.M(null,"lat-field","lat-field",-830652957);DL=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);EL=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
FL=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);GL=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Dja=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);HL=new $CLJS.M("location","country","location/country",1666636202);IL=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
JL=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);KL=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Eja=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);LL=new $CLJS.M(null,"unary","unary",-989314568);ML=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
NL=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);OL=new $CLJS.r(null,"ceil","ceil",-184398425,null);Fja=new $CLJS.M(null,"lon-min","lon-min",-787291357);PL=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Gja=new $CLJS.M(null,"match","match",1220059550);QL=new $CLJS.r(null,"count-where","count-where",2025939247,null);RL=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
SL=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Hja=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);TL=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);UL=new $CLJS.r(null,"sum","sum",1777518341,null);VL=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);WL=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.XL=new $CLJS.M("date","range","date/range",1647265776);YL=new $CLJS.r(null,"between","between",-1523336493,null);ZL=new $CLJS.M(null,"clause-form","clause-form",1820463737);Ija=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);$L=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);aM=new $CLJS.r(null,"field","field",338095027,null);Jja=new $CLJS.M(null,"segment-id","segment-id",1810133590);
bM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);cM=new $CLJS.r(null,"not-null","not-null",313812992,null);dM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);eM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);fM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Kja=new $CLJS.M(null,"template-tag","template-tag",310841038);
gM=new $CLJS.M(null,"invalid","invalid",412869516);Lja=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);hM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);iM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.jM=new $CLJS.M(null,"context","context",-830191113);kM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Mja=new $CLJS.M(null,"format-rows?","format-rows?",992129486);lM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);mM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);nM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.oM=new $CLJS.M(null,"parameters","parameters",-1229919748);Nja=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.pM=new $CLJS.M(null,"card","card",-1430355152);qM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);rM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Oja=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Pja=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);sM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.tM=new $CLJS.M("date","month-year","date/month-year",1948031290);
uM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);vM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);wM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.xM=new $CLJS.M(null,"joins","joins",1033962699);Qja=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.yM=new $CLJS.M(null,"source-field","source-field",933829534);zM=new $CLJS.r(null,"Field","Field",430385967,null);
AM=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Rja=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Sja=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Tja=new $CLJS.M(null,"items","items",1031954938);BM=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);CM=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
DM=new $CLJS.M(null,"more","more",-2058821800);EM=new $CLJS.M(null,"first-clause","first-clause",-20953491);FM=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);GM=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Uja=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);HM=new $CLJS.r(null,"contains","contains",-1977535957,null);
Vja=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);IM=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);JM=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.KM=new $CLJS.M(null,"widget-type","widget-type",1836256899);LM=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.MM=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
NM=new $CLJS.r(null,"is-null","is-null",-356519403,null);OM=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);PM=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);QM=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Wja=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.RM=new $CLJS.M(null,"required","required",1807647006);Xja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
SM=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Yja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Zja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);TM=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);UM=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);VM=new $CLJS.M("string","contains","string/contains",1602423827);WM=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
XM=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);$ja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);YM=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);aka=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);bka=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
ZM=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);$M=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);aN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);bN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);cN=new $CLJS.r(null,"share","share",1051097594,null);
cka=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);dka=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);eka=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);dN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);fka=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
eN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);gka=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.fN=new $CLJS.M(null,"collection","collection",-683361892);gN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);hN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.iN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);hka=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);lN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);mN=new $CLJS.r(null,"metric","metric",2049329604,null);nN=new $CLJS.r(null,"concat","concat",-467652465,null);oN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.pN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
ika=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.qN=new $CLJS.M("date","relative","date/relative",25987732);rN=new $CLJS.M("location","city","location/city",-1746973325);jka=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);kka=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);sN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);tN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
uN=new $CLJS.M("number","between","number/between",97700581);lka=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);vN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);wN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);xN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);yN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.zN=new $CLJS.M(null,"semantic_type","semantic_type",272485089);mka=new $CLJS.M(null,"metric-id","metric-id",-686486942);AN=new $CLJS.r(null,"*","*",345799209,null);BN=new $CLJS.r(null,"+","+",-740910886,null);CN=new $CLJS.r(null,"-","-",-471816912,null);DN=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);EN=new $CLJS.M(null,"allowed-for","allowed-for",122724334);nka=new $CLJS.M(null,"question","question",-1411720117);FN=new $CLJS.r(null,"asc","asc",1997386096,null);
GN=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);oka=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);HN=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);IN=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);JN=new $CLJS.r(null,"\x3c","\x3c",993667236,null);KN=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);LN=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
MN=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.NN=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);ON=new $CLJS.M("string","ends-with","string/ends-with",302681156);pka=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);PN=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);qka=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
QN=new $CLJS.r(null,"and","and",668631710,null);RN=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);SN=new $CLJS.r(null,"round","round",-645002441,null);rka=new $CLJS.M(null,"to","to",192099007);$CLJS.TN=new $CLJS.M("date","single","date/single",1554682003);ska=new $CLJS.M(null,"action-id","action-id",-1727958578);tka=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);UN=new $CLJS.r(null,"exp","exp",1378825265,null);
VN=new $CLJS.r(null,"Filter","Filter",-424893332,null);WN=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);uka=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);XN=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);YN=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);ZN=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
$N=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.aO=new $CLJS.M(null,"source-table","source-table",-225307692);vka=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);wka=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);bO=new $CLJS.r(null,"floor","floor",-772394748,null);xka=new $CLJS.M(null,"middleware","middleware",1462115504);
yka=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);cO=new $CLJS.M(null,"requires-features","requires-features",-101116256);dO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);eO=new $CLJS.M(null,"clause-name","clause-name",-996419059);fO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);gO=new $CLJS.r(null,"now","now",-9994004,null);
zka=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);hO=new $CLJS.r(null,"not","not",1044554643,null);iO=new $CLJS.r(null,"avg","avg",1837937727,null);jO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);kO=new $CLJS.M(null,"max-results","max-results",-32858165);Aka=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);lO=new $CLJS.r(null,"case","case",-1510733573,null);
mO=new $CLJS.r(null,"distinct","distinct",-148347594,null);nO=new $CLJS.r(null,"get-second","get-second",-425414791,null);oO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.pO=new $CLJS.M(null,"join-alias","join-alias",1454206794);qO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Bka=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
rO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);sO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Cka=new $CLJS.M(null,"original","original",-445386197);tO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);uO=new $CLJS.r(null,"abs","abs",1394505050,null);vO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Dka=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
wO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Eka=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.xO=new $CLJS.M(null,"date","date",-1463434462);yO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Fka=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);zO=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
AO=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);BO=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);CO=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);DO=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);EO=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);FO=new $CLJS.r(null,"or","or",1876275696,null);
Gka=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Hka=new $CLJS.M(null,"constraints","constraints",422775616);Ika=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);GO=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Jka=new $CLJS.M(null,"csv-download","csv-download",2141432084);HO=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
IO=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Kka=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Lka=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);JO=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);KO=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
LO=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Mka=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);MO=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Nka=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);NO=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Oka=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Pka=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);OO=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Qka=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Rka=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Ska=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.PO=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.QO=new $CLJS.M(null,"database","database",1849087575);RO=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);SO=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Tka=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);TO=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
UO=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.VO=new $CLJS.M(null,"expressions","expressions",255689909);WO=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Uka=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);XO=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Vka=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);YO=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);ZO=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);$O=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Wka=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);Xka=new $CLJS.M(null,"action","action",-811238024);
aP=new $CLJS.r(null,"get-day","get-day",1768100384,null);bP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);cP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);dP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.eP=new $CLJS.M(null,"native","native",-613060878);Yka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);fP=new $CLJS.M(null,"page","page",849072397);
gP=new $CLJS.r(null,"length","length",-2065447907,null);Zka=new $CLJS.M(null,"dashboard","dashboard",-631747508);hP=new $CLJS.r(null,"get-week","get-week",752472178,null);iP=new $CLJS.r(null,"get-month","get-month",1271156796,null);jP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);kP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);lP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
mP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);nP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);oP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);$ka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);pP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);qP=new $CLJS.r(null,"substring","substring",-1513569493,null);
ala=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);rP=new $CLJS.M(null,"internal","internal",-854870097);bla=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);sP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);tP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
uP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);cla=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);vP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);wP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);dla=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
ela=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);xP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);yP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);zP=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
fla=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);gla=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);AP=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.BP=new $CLJS.M(null,"template-tags","template-tags",1853115685);hla=new $CLJS.M(null,"public-question","public-question",629369976);ila=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
jla=new $CLJS.M(null,"binary","binary",-1802232288);CP=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);kla=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.DP=new $CLJS.M(null,"source-query","source-query",198004422);EP=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);lla=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
mla=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);FP=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);GP=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);nla=new $CLJS.M(null,"executed-by","executed-by",-739811161);ola=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
pla=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);HP=new $CLJS.M(null,"amount","amount",364489504);IP=new $CLJS.r(null,"percentile","percentile",1039342775,null);JP=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);KP=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);LP=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
MP=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);NP=new $CLJS.r(null,"trim","trim",-1880116002,null);qla=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);OP=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);PP=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);rla=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
QP=new $CLJS.M("string","\x3d","string/\x3d",983744235);sla=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);tla=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);ula=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);RP=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);SP=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
TP=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);vla=new $CLJS.M(null,"lat-min","lat-min",1630784161);UP=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);VP=new $CLJS.r(null,"inside","inside",-681932758,null);wla=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);WP=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
xla=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);XP=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);yla=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);zla=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.YP=new $CLJS.M(null,"card-id","card-id",-1770060179);ZP=new $CLJS.M(null,"variadic","variadic",882626057);
$P=new $CLJS.r(null,"upper","upper",1886775433,null);aQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);bQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);cQ=new $CLJS.r(null,"optional","optional",-600484260,null);Ala=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);dQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
eQ=new $CLJS.M(null,"sugar","sugar",-73788488);Bla=new $CLJS.M(null,"lat-max","lat-max",841568226);fQ=new $CLJS.r(null,"power","power",702679448,null);gQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);hQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);iQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
jQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);kQ=new $CLJS.r(null,"median","median",-2084869638,null);lQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);mQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Cla=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);nQ=new $CLJS.M(null,"y","y",-1757859776);oQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.pQ=new $CLJS.M(null,"binning","binning",1709835866);qQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);rQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);sQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);tQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);uQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);vQ=new $CLJS.M(null,"b","b",1482224470);
wQ=new $CLJS.M(null,"a","a",-2123407586);xQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);yQ=new $CLJS.r(null,"replace","replace",853943757,null);zQ=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Dla=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);AQ=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
BQ=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);CQ=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);DQ=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);EQ=new $CLJS.r(null,"segment","segment",675610331,null);Ela=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);FQ=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
GQ=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);HQ=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);IQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Fla=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.JQ=new $CLJS.M(null,"order-by","order-by",1527318070);KQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Gla=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Hla=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.LQ=new $CLJS.M(null,"condition","condition",1668437652);MQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Ila=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);NQ=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Jla=new $CLJS.M(null,"card-name","card-name",-2035606807);OQ=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Kla=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Lla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);PQ=new $CLJS.r(null,"log","log",45015523,null);Mla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
QQ=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.RQ=new $CLJS.M(null,"database_type","database_type",-54700895);SQ=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);TQ=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.UQ=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.VQ=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.WQ=new $CLJS.M("date","all-options","date/all-options",549325958);
Nla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Ola=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);XQ=new $CLJS.M("location","state","location/state",-114378652);YQ=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Pla=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Qla=new $CLJS.M(null,"lon-field","lon-field",517872067);
ZQ=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);$Q=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);aR=new $CLJS.M(null,"numeric","numeric",-1495594714);bR=new $CLJS.r(null,"variable","variable",1359185035,null);cR=new $CLJS.r(null,"lower","lower",-1534114948,null);Rla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
dR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);eR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.fR=new $CLJS.M(null,"limit","limit",-1355822363);gR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);hR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Sla=new $CLJS.M(null,"pulse","pulse",-244494476);var iR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.hD],null),jR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.qD],null),kR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.dD],null),lR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.$C],null),mR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.lD],null),nR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.aK],null),Tla=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.NJ],null),Ula=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.GJ],null),oR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,
$CLJS.AH],null),pR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.EH],null),qR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.vH],null),rR=new $CLJS.Rg(null,new $CLJS.h(null,12,[$CLJS.ai,null,$CLJS.Ik,null,$CLJS.Ui,null,$CLJS.Oh,null,$CLJS.oi,null,$CLJS.$i,null,$CLJS.Ej,null,$CLJS.ij,null,$CLJS.Vh,null,$CLJS.gj,null,$CLJS.bi,null,$CLJS.ji,null],null),null),sR=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.Gk,null,$CLJS.MF,null,$CLJS.Oh,null,$CLJS.zk,null,$CLJS.PF,null,$CLJS.Qi,null,$CLJS.Ck,null],null),null),
tR=$CLJS.Ps.g(rR,sR),Vla=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"date bucketing unit"],null)],null),rR),Wla=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"time bucketing unit"],null)],null),sR),uR=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"datetime bucketing unit"],null)],null),tR),vR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.$H],null),Xla=new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,
1,[$CLJS.ns,"temporal extract unit"],null),$CLJS.QF,$CLJS.ji,$CLJS.Ej,$CLJS.QH,$CLJS.dI,$CLJS.eI,$CLJS.ij,$CLJS.oi,$CLJS.zk,$CLJS.Qi,$CLJS.NF],null),Yla=new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"datetime-diff unit"],null),$CLJS.MF,$CLJS.Ck,$CLJS.Gk,$CLJS.Ik,$CLJS.Ui,$CLJS.$i,$CLJS.ai,$CLJS.Vh],null),wR=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"temporal-extract week extraction mode"],null),$CLJS.KH,$CLJS.RH,$CLJS.VH],null),xR=new $CLJS.Q(null,
10,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"relative-datetime unit"],null),$CLJS.Oh,$CLJS.Ck,$CLJS.Gk,$CLJS.Ik,$CLJS.Ui,$CLJS.$i,$CLJS.ai,$CLJS.Vh],null),ER,$la,MR,NR,OR,PR,QR,RR,SR,ET,bma,FT,cma,dma,GT,ema,fma,gma;$CLJS.X(NL,XK($CLJS.RA,$CLJS.H(["n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.Iu],null),$CLJS.lr],null),"unit",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,xR],null)])));var yR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,NL],null);
$CLJS.X(tO,XK($CLJS.QA,$CLJS.H(["n",$CLJS.lr,"unit",xR])));var Zla=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,tO],null);
$CLJS.X(hM,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ns,"valid :absolute-datetime clause",$CLJS.wi,function(a){if($CLJS.Ta(WK($CLJS.OH,a)))a=gM;else{a=$CLJS.ed(a);var b=$CLJS.SK(oR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.xO:$CLJS.SH}return a}],null),new $CLJS.Q(null,2,5,$CLJS.R,[gM,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xO,XK($CLJS.OH,
$CLJS.H(["date",oR,"unit",Vla]))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SH,XK($CLJS.OH,$CLJS.H(["datetime",pR,"unit",uR]))],null)],null));var zR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,hM],null);$CLJS.X(wM,XK($CLJS.xx,$CLJS.H(["time",qR,"unit",Wla])));var AR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,wM],null),BR=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ns,"date or datetime literal"],null),zR,pR,oR],null);
$CLJS.X(hL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ns,"time literal"],null),AR,qR],null));$CLJS.X(ZQ,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ns,"temporal literal"],null),BR,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,hL],null)],null));var CR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,ZQ],null);
$CLJS.X(rla,YK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof zR?new $CLJS.Dc(function(){return zR},$CLJS.kd(Rja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,rP,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$O,"metabase/mbql/schema.cljc",69,$CLJS.OH,1,!0,168,168,$CLJS.Hc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(zR)?zR.H:null])):null));return $CLJS.n(a)?a:$O}(),zR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof yR?new $CLJS.Dc(function(){return yR},$CLJS.kd(Nla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,
$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",29,$CLJS.RA,1,131,131,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.RA,$CLJS.Hk,$CLJS.V($CLJS.qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.Iu],null),$CLJS.lr],null)),$CLJS.ci,$CLJS.V($CLJS.qj,$CLJS.V(cQ,PN))],null),$CLJS.Hc,"Schema for a valid relative-datetime clause.",
$CLJS.n(yR)?yR.H:null])):null));return $CLJS.n(a)?a:rO}(),yR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof AR?new $CLJS.Dc(function(){return AR},$CLJS.kd(Pka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,rP,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QQ,"metabase/mbql/schema.cljc",27,$CLJS.xx,1,!0,175,175,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.xx,$CLJS.xx,$CLJS.V($CLJS.qj,qla),$CLJS.ci,$CLJS.V($CLJS.qj,ika)],null),$CLJS.Hc,"Schema for a valid time clause.",$CLJS.n(AR)?AR.H:null])):null));return $CLJS.n(a)?a:QQ}(),AR],null)])));
$CLJS.X(OQ,XK($CLJS.ej,$CLJS.H(["value",$CLJS.Xh,"type-info",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RQ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,iR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UB,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,jR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zN,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.$q,kR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,uR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,iR],null)],null)],null)],null)])));var DR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,OQ],null);$CLJS.X(yN,XK($CLJS.gA,$CLJS.H(["expression-name",iR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,$CLJS.Bj],null)])));
ER=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,yN],null);
$la=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.sD);c=$CLJS.J.g(c,$CLJS.aD);return $CLJS.E.g(b,$CLJS.aD)?c:!0}],null)],null)}(function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.sD);c=$CLJS.J.g(c,$CLJS.rD);return $CLJS.E.g(b,$CLJS.rD)?c:!0}],null)],null)}(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,"binning options"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sD,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"binning strategy"],null),$CLJS.aD,$CLJS.rD,$CLJS.Oh],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),lR],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.rD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,$CLJS.Xk,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.rl)],null)],null)],null)],null)));$CLJS.FR=function FR(a){switch(arguments.length){case 1:return FR.h(arguments[0]);case 2:return FR.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.FR.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.jA);b=$CLJS.J.g(b,$CLJS.fF);return $CLJS.FR.g(a,b)};$CLJS.FR.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.tz(a,$CLJS.Ak)?rR:$CLJS.tz(a,$CLJS.Jk)?sR:$CLJS.tz(a,$CLJS.Dk)?tR:null:null;return $CLJS.n(a)?$CLJS.Dd(a,b):!0};$CLJS.FR.A=2;
$CLJS.X(dQ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,"field options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jA,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,jR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yM,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,nR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fF,new $CLJS.h(null,
1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,uR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pO,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,iR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pQ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$la],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.FR],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.sD)],null)],null));
$CLJS.X(FQ,function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.jA);return"string"===typeof c?b:!0}],null)],null)}(XK($CLJS.$E,$CLJS.H(["id-or-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,nR,iR],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dQ],null)],null)]))));var GR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,FQ],null);
$CLJS.X(GO,YK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof ER?new $CLJS.Dc(function(){return ER},$CLJS.kd(IM,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sM,"metabase/mbql/schema.cljc",60,$CLJS.gA,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gA,$CLJS.VQ,$CLJS.V($CLJS.qj,PL),$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,$CLJS.Bj))],null),$CLJS.Hc,"Schema for a valid expression clause.",$CLJS.n(ER)?ER.H:null])):null));return $CLJS.n(a)?a:sM}(),ER],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof GR?new $CLJS.Dc(function(){return GR},
$CLJS.kd($M,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.aq,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,$CLJS.di,$CLJS.Dj,$CLJS.Gj],["0.39.0",$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aM,"metabase/mbql/schema.cljc",51,$CLJS.$E,1,382,382,$CLJS.Hc,"Schema for a `:field` clause.",$CLJS.n(GR)?GR.H:null])):null));return $CLJS.n(a)?a:aM}(),GR],null)])));
$CLJS.HR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,GO],null);$CLJS.X(cL,XK($CLJS.PE,$CLJS.H(["aggregation-clause-index",$CLJS.lr,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,$CLJS.Bj],null)])));var IR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cL],null);
$CLJS.X(JL,YK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof IR?new $CLJS.Dc(function(){return IR},$CLJS.kd(ola,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oQ,"metabase/mbql/schema.cljc",23,$CLJS.PE,1,418,418,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.PE,Pla,$CLJS.V($CLJS.qj,$CLJS.lr),$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,$CLJS.Bj))],null),$CLJS.Hc,"Schema for a valid aggregation clause.",$CLJS.n(IR)?IR.H:null])):null));return $CLJS.n(a)?a:oQ}(),IR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof ER?new $CLJS.Dc(function(){return ER},
$CLJS.kd(IM,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sM,"metabase/mbql/schema.cljc",60,$CLJS.gA,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gA,$CLJS.VQ,$CLJS.V($CLJS.qj,PL),
$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,$CLJS.Bj))],null),$CLJS.Hc,"Schema for a valid expression clause.",$CLJS.n(ER)?ER.H:null])):null));return $CLJS.n(a)?a:sM}(),ER],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof GR?new $CLJS.Dc(function(){return GR},$CLJS.kd($M,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.aq,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,
$CLJS.di,$CLJS.Dj,$CLJS.Gj],["0.39.0",$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aM,"metabase/mbql/schema.cljc",51,$CLJS.$E,1,382,382,$CLJS.Hc,"Schema for a `:field` clause.",$CLJS.n(GR)?GR.H:null])):null));return $CLJS.n(a)?a:aM}(),GR],null)])));
var JR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,JL],null),KR=new $CLJS.Rg(null,new $CLJS.h(null,11,[$CLJS.ds,null,$CLJS.rF,null,$CLJS.rE,null,$CLJS.xF,null,$CLJS.yE,null,$CLJS.cF,null,$CLJS.IE,null,$CLJS.js,null,$CLJS.pF,null,$CLJS.bF,null,$CLJS.yF,null],null),null),LR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,GL],null);
$CLJS.X(jL,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.wi,function(a){return"string"===typeof a?$CLJS.wj:$CLJS.n(WK(KR,a))?CL:$CLJS.n(WK($CLJS.ej,a))?$CLJS.ej:$CLJS.fs}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,$CLJS.wj],null),new $CLJS.Q(null,2,5,$CLJS.R,[CL,LR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,DR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,$CLJS.HR],null)],null));MR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,jL],null);
NR=new $CLJS.Rg(null,new $CLJS.h(null,26,[$CLJS.uE,null,$CLJS.cI,null,$CLJS.Iq,null,$CLJS.UA,null,$CLJS.YH,null,$CLJS.NE,null,$CLJS.Tr,null,$CLJS.qE,null,$CLJS.aI,null,$CLJS.SA,null,$CLJS.cF,null,$CLJS.uF,null,$CLJS.ZH,null,$CLJS.eF,null,$CLJS.wE,null,$CLJS.MH,null,$CLJS.Hq,null,$CLJS.zE,null,$CLJS.IE,null,$CLJS.GH,null,$CLJS.Hw,null,$CLJS.NH,null,$CLJS.WH,null,$CLJS.PA,null,$CLJS.wF,null,$CLJS.YE,null],null),null);
OR=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.Eq,null,$CLJS.yq,null,$CLJS.ar,null,$CLJS.cr,null,$CLJS.Aq,null,$CLJS.qF,null,$CLJS.gr,null,$CLJS.Fq,null,$CLJS.Cq,null],null),null);PR=new $CLJS.Rg(null,new $CLJS.h(null,17,[$CLJS.en,null,$CLJS.oF,null,$CLJS.iF,null,$CLJS.aF,null,$CLJS.tF,null,$CLJS.vF,null,$CLJS.EE,null,$CLJS.LE,null,$CLJS.oL,null,$CLJS.FE,null,$CLJS.mF,null,$CLJS.gF,null,$CLJS.Cj,null,$CLJS.Jw,null,$CLJS.UE,null,$CLJS.VE,null,$CLJS.lF,null],null),null);
QR=new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.TH,null,$CLJS.IH,null,$CLJS.WA,null,$CLJS.Hq,null,$CLJS.TA,null],null),null);RR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,WP],null);SR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,uM],null);$CLJS.TR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,xP],null);$CLJS.UR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bQ],null);
$CLJS.X(iQ,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ns,"numeric expression argument",$CLJS.wi,function(a){return"number"===typeof a?$CLJS.Js:$CLJS.n(WK(NR,a))?$N:$CLJS.n(WK(PR,a))?$CLJS.PE:$CLJS.n(WK($CLJS.ej,a))?$CLJS.ej:$CLJS.$E}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.Xk],null),new $CLJS.Q(null,2,5,$CLJS.R,[$N,RR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PE,$CLJS.UR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,DR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$E,
$CLJS.HR],null)],null));var VR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,iQ],null);
$CLJS.X(sP,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ns,"datetime expression argument",$CLJS.wi,function(a){return $CLJS.n(WK(PR,a))?$CLJS.PE:$CLJS.n(WK($CLJS.ej,a))?$CLJS.ej:$CLJS.n(WK(QR,a))?BM:$CLJS.fs}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PE,$CLJS.UR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,DR],null),new $CLJS.Q(null,2,5,$CLJS.R,[BM,$CLJS.TR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,BR,$CLJS.HR],null)],null)],null));
var WR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,sP],null);
$CLJS.X(WO,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ns,"expression argument",$CLJS.wi,function(a){return"number"===typeof a?$CLJS.Js:$CLJS.yd(a)?$CLJS.qr:$CLJS.n(WK(OR,a))?kP:$CLJS.n(WK(NR,a))?$N:$CLJS.n(WK(QR,a))?BM:"string"===typeof a?$CLJS.wj:$CLJS.n(WK(KR,a))?CL:$CLJS.n(WK($CLJS.ej,a))?$CLJS.ej:$CLJS.fs}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.Xk],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qr,$CLJS.qr],null),new $CLJS.Q(null,2,5,$CLJS.R,[kP,SR],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$N,RR],null),new $CLJS.Q(null,2,5,$CLJS.R,[BM,$CLJS.TR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,$CLJS.wj],null),new $CLJS.Q(null,2,5,$CLJS.R,[CL,LR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,DR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,$CLJS.HR],null)],null));var YR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,WO],null);$CLJS.X(UO,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ns,"numeric expression arg or interval"],null),Zla,VR],null));
var ZR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,UO],null);$CLJS.X(uL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ns,"int greater than zero or numeric expression",$CLJS.wi,function(a){return"number"===typeof a?$CLJS.Js:$CLJS.fs}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,lR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,RR],null)],null));var ama=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,uL],null);
$CLJS.X(CM,XK($CLJS.cF,$CLJS.H(["a",YR,"b",YR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,YR],null)])));var $R=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,CM],null);$CLJS.X(gL,XK($CLJS.rE,$CLJS.H(["s",MR,"start",ama,"length",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,VR],null)])));var aS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,gL],null);$CLJS.X(lP,XK($CLJS.Hw,$CLJS.H(["s",MR])));var bS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,lP],null);$CLJS.X(IL,XK($CLJS.pF,$CLJS.H(["s",MR])));
var cS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,IL],null);$CLJS.X(ML,XK($CLJS.yF,$CLJS.H(["s",MR])));var dS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,ML],null);$CLJS.X(eM,XK($CLJS.rF,$CLJS.H(["s",MR])));var eS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eM],null);$CLJS.X(QM,XK($CLJS.ds,$CLJS.H(["s",MR])));var fS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,QM],null);$CLJS.X(zL,XK($CLJS.js,$CLJS.H(["s",MR])));var gS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,zL],null);
$CLJS.X(hQ,XK($CLJS.xF,$CLJS.H(["s",MR,"match",$CLJS.wj,"replacement",$CLJS.wj])));var hS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,hQ],null);$CLJS.X(dR,XK($CLJS.yE,$CLJS.H(["a",MR,"b",MR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,MR],null)])));var iS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dR],null);$CLJS.X(HN,XK($CLJS.bF,$CLJS.H(["s",MR,"pattern",$CLJS.wj])));var jS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,HN],null);
$CLJS.X(DQ,XK($CLJS.Hq,$CLJS.H(["x",ZR,"y",ZR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,ZR],null)])));var kS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,DQ],null);$CLJS.X(BQ,XK($CLJS.Tr,$CLJS.H(["x",VR,"y",ZR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,ZR],null)])));var lS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,BQ],null);$CLJS.X(AQ,XK($CLJS.uF,$CLJS.H(["x",VR,"y",VR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,VR],null)])));var mS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,AQ],null);
$CLJS.X(CQ,XK($CLJS.Iq,$CLJS.H(["x",VR,"y",VR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,VR],null)])));var nS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,CQ],null);$CLJS.X(TO,XK($CLJS.YE,$CLJS.H(["x",VR])));var oS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,TO],null);$CLJS.X(LP,XK($CLJS.uE,$CLJS.H(["x",VR])));var pS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,LP],null);$CLJS.X(gR,XK($CLJS.eF,$CLJS.H(["x",VR])));var qS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,gR],null);$CLJS.X(YN,XK($CLJS.zE,$CLJS.H(["x",VR])));
var rS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,YN],null);$CLJS.X(dO,XK($CLJS.wE,$CLJS.H(["x",VR,"y",VR])));var sS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dO],null);$CLJS.X(AO,XK($CLJS.NE,$CLJS.H(["x",VR])));var tS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,AO],null);$CLJS.X(vL,XK($CLJS.qE,$CLJS.H(["x",VR])));var uS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,vL],null);$CLJS.X(ZO,XK($CLJS.wF,$CLJS.H(["x",VR])));var vS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,ZO],null);
$CLJS.X(VL,XK($CLJS.PA,$CLJS.H(["datetime-x",WR,"datetime-y",WR,"unit",Yla])));var wS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,VL],null);$CLJS.X(mM,XK($CLJS.UA,$CLJS.H(["datetime",WR,"unit",Xla,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,wR],null)])));var xS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,mM],null);$CLJS.X(fO,XK($CLJS.NH,$CLJS.H(["date",WR])));var yS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,fO],null);$CLJS.X(HO,XK($CLJS.cI,$CLJS.H(["date",WR])));
var zS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,HO],null);$CLJS.X(iL,XK($CLJS.GH,$CLJS.H(["date",WR])));var AS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,iL],null);$CLJS.X(eR,XK($CLJS.SA,$CLJS.H(["date",WR,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,wR],null)])));var BS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eR],null);$CLJS.X(sN,XK($CLJS.WH,$CLJS.H(["date",WR])));var CS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,sN],null);$CLJS.X(BO,XK($CLJS.ZH,$CLJS.H(["date",WR])));
var DS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,BO],null);$CLJS.X(RL,XK($CLJS.aI,$CLJS.H(["datetime",WR])));var ES=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,RL],null);$CLJS.X(xN,XK($CLJS.YH,$CLJS.H(["datetime",WR])));var FS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,xN],null);$CLJS.X(KL,XK($CLJS.MH,$CLJS.H(["datetime",WR])));var GS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,KL],null);$CLJS.X(aN,XK($CLJS.IH,$CLJS.H(["datetime",WR,"to",vR,"from",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,vR],null)])));
var HS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,aN],null),IS=new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"datetime arithmetic unit"],null),$CLJS.PF,$CLJS.MF,$CLJS.Ck,$CLJS.Gk,$CLJS.Ik,$CLJS.Ui,$CLJS.$i,$CLJS.ai,$CLJS.Vh],null);$CLJS.X(fL,XK($CLJS.TA,$CLJS.H(["datetime",WR,"amount",VR,"unit",IS])));var JS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,fL],null);
$CLJS.X(DL,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return XK(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.TH));var KS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,DL],null);$CLJS.X(eL,XK($CLJS.WA,$CLJS.H(["datetime",WR,"amount",VR,"unit",IS])));var LS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eL],null);
$CLJS.X(xP,YK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof kS?new $CLJS.Dc(function(){return kS},$CLJS.kd(NO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BN,"metabase/mbql/schema.cljc",51,$CLJS.Hq,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Hq,$CLJS.pD,$CLJS.V($CLJS.qj,RO),nQ,$CLJS.V($CLJS.qj,RO),DM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,RO))],null),$CLJS.Hc,"Schema for a valid + clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:BN}(),kS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof JS?new $CLJS.Dc(function(){return JS},
$CLJS.kd(zka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[UM,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",67,$CLJS.TA,1,703,703,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.TA,$CLJS.SH,$CLJS.V($CLJS.qj,$Q),HP,$CLJS.V($CLJS.qj,
MP),$CLJS.ci,$CLJS.V($CLJS.qj,UP)],null),$CLJS.Hc,"Schema for a valid datetime-add clause.",$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:IO}(),JS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof LS?new $CLJS.Dc(function(){return LS},$CLJS.kd(sja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,
$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[UM,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",72,$CLJS.WA,1,710,710,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.WA,$CLJS.SH,$CLJS.V($CLJS.qj,$Q),HP,$CLJS.V($CLJS.qj,MP),$CLJS.ci,$CLJS.V($CLJS.qj,UP)],null),$CLJS.Hc,"Schema for a valid datetime-subtract clause.",$CLJS.n(LS)?LS.H:null])):null));
return $CLJS.n(a)?a:YQ}(),LS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof HS?new $CLJS.Dc(function(){return HS},$CLJS.kd(xla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.IH,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AM,"metabase/mbql/schema.cljc",71,$CLJS.IH,1,695,695,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.IH,$CLJS.SH,$CLJS.V($CLJS.qj,$Q),rka,$CLJS.V($CLJS.qj,qQ),wja,$CLJS.V($CLJS.qj,$CLJS.V(cQ,qQ))],null),$CLJS.Hc,"Schema for a valid convert-timezone clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:AM}(),HS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&
"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof KS?new $CLJS.Dc(function(){return KS},$CLJS.kd($ka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TH,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gO,"metabase/mbql/schema.cljc",
45,$CLJS.TH,1,708,708,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.TH],null),$CLJS.Hc,"Schema for a valid now clause.",$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:gO}(),KS],null)])));$CLJS.MS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,DO],null);$CLJS.X(kL,XK($CLJS.gr,$CLJS.H(["first-clause",$CLJS.MS,"second-clause",$CLJS.MS,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,$CLJS.MS],null)])));var NS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,kL],null);
$CLJS.X(XM,XK($CLJS.ar,$CLJS.H(["first-clause",$CLJS.MS,"second-clause",$CLJS.MS,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,$CLJS.MS],null)])));var OS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,XM],null);$CLJS.X(FL,XK($CLJS.cr,$CLJS.H(["clause",$CLJS.MS])));
var PS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,FL],null),QS=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,3,[$CLJS.ns,":field or :expression reference or :relative-datetime",$CLJS.ys,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.wi,function(a){return $CLJS.n(WK($CLJS.RA,a))?$CLJS.RA:$CLJS.fs}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RA,yR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,$CLJS.HR],null)],null);
$CLJS.X(uP,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$q,new $CLJS.h(null,1,[$CLJS.ns,"equality comparable"],null),new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.ar,$CLJS.qr,$CLJS.Xk,$CLJS.wj,CR,QS,YR,DR],null)],null));var RS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,uP],null);
$CLJS.X(MN,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ns,"order comparable",$CLJS.wi,function(a){return $CLJS.n(WK($CLJS.ej,a))?$CLJS.ej:$CLJS.fs}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,DR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.ar,$CLJS.Xk,$CLJS.wj,CR,YR,QS],null)],null)],null));var SS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,MN],null);
$CLJS.X(tQ,XK($CLJS.Fq,$CLJS.H(["field",RS,"value-or-field",RS,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,RS],null)])));var TS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,tQ],null);$CLJS.X(xQ,XK($CLJS.qF,$CLJS.H(["field",RS,"value-or-field",RS,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,RS],null)])));var US=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,xQ],null);$CLJS.X(sQ,XK($CLJS.Cq,$CLJS.H(["field",SS,"value-or-field",SS])));
var VS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,sQ],null);$CLJS.X(rQ,XK($CLJS.yq,$CLJS.H(["field",SS,"value-or-field",SS])));var WS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,rQ],null);$CLJS.X(fM,XK($CLJS.Eq,$CLJS.H(["field",SS,"value-or-field",SS])));var XS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,fM],null);$CLJS.X(lM,XK($CLJS.Aq,$CLJS.H(["field",SS,"value-or-field",SS])));var YS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,lM],null);$CLJS.X(cP,XK($CLJS.BE,$CLJS.H(["field",SS,"min",SS,"max",SS])));
var ZS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cP],null);$CLJS.X(EP,XK($CLJS.HE,$CLJS.H(["lat-field",SS,"lon-field",SS,"lat-max",SS,"lon-min",SS,"lat-min",SS,"lon-max",SS])));var $S=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,EP],null);$CLJS.X(PM,XK($CLJS.AE,$CLJS.H(["field",$CLJS.HR])));var aT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,PM],null);$CLJS.X(GN,XK($CLJS.ME,$CLJS.H(["field",$CLJS.HR])));var bT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,GN],null);$CLJS.X(gN,XK($CLJS.CE,$CLJS.H(["field",$CLJS.HR])));
var cT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,gN],null);$CLJS.X(nL,XK($CLJS.JE,$CLJS.H(["field",$CLJS.HR])));var dT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,nL],null),eT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hF,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null)],null);$CLJS.X(vN,XK($CLJS.RE,$CLJS.H(["field",MR,"string-or-field",MR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,eT],null)])));var fT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,vN],null);
$CLJS.X(oO,XK($CLJS.kF,$CLJS.H(["field",MR,"string-or-field",MR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,eT],null)])));var gT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,oO],null);$CLJS.X(eN,XK($CLJS.ZE,$CLJS.H(["field",MR,"string-or-field",MR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,eT],null)])));var hT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eN],null);$CLJS.X(XO,XK($CLJS.SE,$CLJS.H(["field",MR,"string-or-field",MR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,eT],null)])));
var iT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,XO],null);$CLJS.X(wL,XK($CLJS.VA,$CLJS.H(["field",$CLJS.HR,"n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.lr,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yq,$CLJS.Iu,$CLJS.Nw,$CLJS.GE],null)],null),"unit",xR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Fk,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null)],null)],null)])));var jT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,wL],null);
$CLJS.X(nP,XK($CLJS.xE,$CLJS.H(["segment-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.EJ],null),iR],null)])));var kT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,nP],null);
$CLJS.X(uM,YK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof NS?new $CLJS.Dc(function(){return NS},$CLJS.kd(gQ,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QN,"metabase/mbql/schema.cljc",15,$CLJS.gr,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.gr,EM,$CLJS.V($CLJS.qj,VN),yO,$CLJS.V($CLJS.qj,VN),OM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,VN))],null),$CLJS.Hc,"Schema for a valid and clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:QN}(),NS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof OS?new $CLJS.Dc(function(){return OS},
$CLJS.kd(CO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",14,$CLJS.ar,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ar,EM,$CLJS.V($CLJS.qj,VN),yO,$CLJS.V($CLJS.qj,VN),OM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,VN))],null),$CLJS.Hc,
"Schema for a valid or clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:FO}(),OS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof PS?new $CLJS.Dc(function(){return PS},$CLJS.kd(oP,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hO,"metabase/mbql/schema.cljc",15,$CLJS.cr,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cr,$CLJS.UQ,$CLJS.V($CLJS.qj,VN)],null),$CLJS.Hc,"Schema for a valid not clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:hO}(),PS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==
typeof VS?new $CLJS.Dc(function(){return VS},$CLJS.kd(LO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",13,$CLJS.Cq,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Cq,$CLJS.$E,$CLJS.V($CLJS.qj,GM),SP,$CLJS.V($CLJS.qj,GM)],
null),$CLJS.Hc,"Schema for a valid \x3c clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:JN}(),VS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof XS?new $CLJS.Dc(function(){return XS},$CLJS.kd(NQ,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",14,$CLJS.Eq,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Eq,$CLJS.$E,$CLJS.V($CLJS.qj,GM),SP,$CLJS.V($CLJS.qj,GM)],null),$CLJS.Hc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:IN}(),XS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&
"undefined"!==typeof bL&&"undefined"!==typeof WS?new $CLJS.Dc(function(){return WS},$CLJS.kd(JO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KN,"metabase/mbql/schema.cljc",13,$CLJS.yq,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.yq,$CLJS.$E,$CLJS.V($CLJS.qj,
GM),SP,$CLJS.V($CLJS.qj,GM)],null),$CLJS.Hc,"Schema for a valid \x3e clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:KN}(),WS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof YS?new $CLJS.Dc(function(){return YS},$CLJS.kd(GQ,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],
[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RN,"metabase/mbql/schema.cljc",14,$CLJS.Aq,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Aq,$CLJS.$E,$CLJS.V($CLJS.qj,GM),SP,$CLJS.V($CLJS.qj,GM)],null),$CLJS.Hc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:RN}(),YS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&
"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof TS?new $CLJS.Dc(function(){return TS},$CLJS.kd(KO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",13,$CLJS.Fq,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,
[$CLJS.Fq,$CLJS.$E,$CLJS.V($CLJS.qj,SQ),SP,$CLJS.V($CLJS.qj,SQ),vP,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,SQ))],null),$CLJS.Hc,"Schema for a valid \x3d clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:LN}(),TS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof US?new $CLJS.Dc(function(){return US},$CLJS.kd(WL,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,
$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rM,"metabase/mbql/schema.cljc",14,$CLJS.qF,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qF,$CLJS.$E,$CLJS.V($CLJS.qj,SQ),SP,$CLJS.V($CLJS.qj,SQ),vP,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,SQ))],null),$CLJS.Hc,"Schema for a valid !\x3d clause.",$CLJS.n(US)?US.H:null])):null));
return $CLJS.n(a)?a:rM}(),US],null)])));
$CLJS.X(DO,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ns,"valid filter expression",$CLJS.wi,function(a){return $CLJS.n(WK(QR,a))?$CLJS.SH:$CLJS.n(WK(NR,a))?aR:$CLJS.n(WK(KR,a))?$CLJS.wj:$CLJS.n(WK(OR,a))?$CLJS.qr:$CLJS.fs}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SH,$CLJS.TR],null),new $CLJS.Q(null,2,5,$CLJS.R,[aR,RR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,LR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qr,SR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,YK($CLJS.H([new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof NS?new $CLJS.Dc(function(){return NS},$CLJS.kd(gQ,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",
15,$CLJS.gr,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.gr,EM,$CLJS.V($CLJS.qj,VN),yO,$CLJS.V($CLJS.qj,VN),OM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,VN))],null),$CLJS.Hc,"Schema for a valid and clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:QN}(),NS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof OS?new $CLJS.Dc(function(){return OS},$CLJS.kd(CO,new $CLJS.h(null,1,[$CLJS.xj,
!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",14,$CLJS.ar,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ar,EM,$CLJS.V($CLJS.qj,VN),yO,$CLJS.V($CLJS.qj,VN),OM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,VN))],null),$CLJS.Hc,"Schema for a valid or clause.",$CLJS.n(OS)?
OS.H:null])):null));return $CLJS.n(a)?a:FO}(),OS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof PS?new $CLJS.Dc(function(){return PS},$CLJS.kd(oP,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hO,"metabase/mbql/schema.cljc",15,$CLJS.cr,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cr,$CLJS.UQ,$CLJS.V($CLJS.qj,VN)],null),$CLJS.Hc,"Schema for a valid not clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:hO}(),PS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof TS?new $CLJS.Dc(function(){return TS},$CLJS.kd(KO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,
$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",13,$CLJS.Fq,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Fq,$CLJS.$E,$CLJS.V($CLJS.qj,SQ),SP,$CLJS.V($CLJS.qj,SQ),vP,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,SQ))],null),$CLJS.Hc,"Schema for a valid \x3d clause.",$CLJS.n(TS)?TS.H:null])):
null));return $CLJS.n(a)?a:LN}(),TS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof US?new $CLJS.Dc(function(){return US},$CLJS.kd(WL,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rM,"metabase/mbql/schema.cljc",14,$CLJS.qF,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qF,$CLJS.$E,$CLJS.V($CLJS.qj,SQ),SP,$CLJS.V($CLJS.qj,SQ),vP,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,SQ))],null),$CLJS.Hc,"Schema for a valid !\x3d clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:rM}(),US],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof VS?new $CLJS.Dc(function(){return VS},
$CLJS.kd(LO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",13,$CLJS.Cq,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Cq,$CLJS.$E,$CLJS.V($CLJS.qj,GM),SP,$CLJS.V($CLJS.qj,GM)],null),$CLJS.Hc,"Schema for a valid \x3c clause.",
$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:JN}(),VS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof WS?new $CLJS.Dc(function(){return WS},$CLJS.kd(JO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KN,"metabase/mbql/schema.cljc",13,$CLJS.yq,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.yq,$CLJS.$E,$CLJS.V($CLJS.qj,GM),SP,$CLJS.V($CLJS.qj,GM)],null),$CLJS.Hc,"Schema for a valid \x3e clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:KN}(),WS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof XS?new $CLJS.Dc(function(){return XS},$CLJS.kd(NQ,new $CLJS.h(null,
1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",14,$CLJS.Eq,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Eq,$CLJS.$E,$CLJS.V($CLJS.qj,GM),SP,$CLJS.V($CLJS.qj,GM)],null),$CLJS.Hc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(XS)?XS.H:null])):
null));return $CLJS.n(a)?a:IN}(),XS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof YS?new $CLJS.Dc(function(){return YS},$CLJS.kd(GQ,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RN,"metabase/mbql/schema.cljc",14,$CLJS.Aq,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Aq,$CLJS.$E,$CLJS.V($CLJS.qj,GM),SP,$CLJS.V($CLJS.qj,GM)],null),$CLJS.Hc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:RN}(),YS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof ZS?new $CLJS.Dc(function(){return ZS},$CLJS.kd(Rka,new $CLJS.h(null,
1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YL,"metabase/mbql/schema.cljc",19,$CLJS.BE,1,804,804,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.BE,$CLJS.$E,$CLJS.V($CLJS.qj,GM),$CLJS.en,$CLJS.V($CLJS.qj,GM),$CLJS.Cj,$CLJS.V($CLJS.qj,GM)],null),$CLJS.Hc,"Schema for a valid between clause.",
$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:YL}(),ZS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof fT?new $CLJS.Dc(function(){return fT},$CLJS.kd(Tka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XP,"metabase/mbql/schema.cljc",23,$CLJS.RE,1,829,829,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.RE,$CLJS.$E,$CLJS.V($CLJS.qj,RP),qM,$CLJS.V($CLJS.qj,RP),$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,mP))],null),$CLJS.Hc,"Schema for a valid starts-with clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:XP}(),fT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof gT?new $CLJS.Dc(function(){return gT},
$CLJS.kd(Uja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vM,"metabase/mbql/schema.cljc",21,$CLJS.kF,1,830,830,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.kF,$CLJS.$E,$CLJS.V($CLJS.qj,RP),qM,$CLJS.V($CLJS.qj,RP),$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,mP))],null),
$CLJS.Hc,"Schema for a valid ends-with clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:vM}(),gT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof hT?new $CLJS.Dc(function(){return hT},$CLJS.kd(gka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HM,"metabase/mbql/schema.cljc",20,$CLJS.ZE,1,831,831,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ZE,$CLJS.$E,$CLJS.V($CLJS.qj,RP),qM,$CLJS.V($CLJS.qj,RP),$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,mP))],null),$CLJS.Hc,"Schema for a valid contains clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:HM}(),hT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==
typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof iT?new $CLJS.Dc(function(){return iT},$CLJS.kd(Lla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lL,"metabase/mbql/schema.cljc",36,$CLJS.SE,1,834,834,!0,new $CLJS.Q(null,
7,5,$CLJS.R,[$CLJS.SE,$CLJS.$E,$CLJS.V($CLJS.qj,RP),qM,$CLJS.V($CLJS.qj,RP),$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,mP))],null),$CLJS.Hc,"Schema for a valid does-not-contain clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:lL}(),iT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof $S?new $CLJS.Dc(function(){return $S},$CLJS.kd(ula,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),
$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",26,$CLJS.HE,1,807,807,!0,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.HE,Cja,$CLJS.V($CLJS.qj,GM),Qla,$CLJS.V($CLJS.qj,GM),Bla,$CLJS.V($CLJS.qj,GM),Fja,$CLJS.V($CLJS.qj,GM),vla,$CLJS.V($CLJS.qj,GM),Bja,$CLJS.V($CLJS.qj,
GM)],null),$CLJS.Hc,"Schema for a valid inside clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:VP}(),$S],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof cT?new $CLJS.Dc(function(){return cT},$CLJS.kd(hka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",28,$CLJS.CE,1,821,821,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CE,$CLJS.$E,$CLJS.V($CLJS.qj,zM)],null),$CLJS.Hc,"Schema for a valid is-empty clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:qO}(),cT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&
"undefined"!==typeof dT?new $CLJS.Dc(function(){return dT},$CLJS.kd(fla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",29,$CLJS.JE,1,822,822,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JE,$CLJS.$E,$CLJS.V($CLJS.qj,zM)],null),
$CLJS.Hc,"Schema for a valid not-empty clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:dP}(),dT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof aT?new $CLJS.Dc(function(){return aT},$CLJS.kd(Gla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NM,"metabase/mbql/schema.cljc",27,$CLJS.AE,1,816,816,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AE,$CLJS.$E,$CLJS.V($CLJS.qj,zM)],null),$CLJS.Hc,"Schema for a valid is-null clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:NM}(),aT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&
"undefined"!==typeof bT?new $CLJS.Dc(function(){return bT},$CLJS.kd(tka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",28,$CLJS.ME,1,817,817,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ME,$CLJS.$E,$CLJS.V($CLJS.qj,zM)],null),
$CLJS.Hc,"Schema for a valid not-null clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:cM}(),bT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof jT?new $CLJS.Dc(function(){return jT},$CLJS.kd(tja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",33,$CLJS.VA,1,856,856,!0,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.VA,$CLJS.$E,$CLJS.V($CLJS.qj,zM),$CLJS.Hk,$CLJS.V($CLJS.qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.lr,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yq,$CLJS.Iu,$CLJS.Nw,$CLJS.GE],null)],null)),$CLJS.ci,$CLJS.V($CLJS.qj,PN),$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,Wka))],null),$CLJS.Hc,"Schema for a valid time-interval clause.",
$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:CP}(),jT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof kT?new $CLJS.Dc(function(){return kT},$CLJS.kd(Bka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EQ,"metabase/mbql/schema.cljc",27,$CLJS.xE,1,872,872,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xE,Jja,$CLJS.V($CLJS.qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,lla,PL],null))],null),$CLJS.Hc,"Schema for a valid segment clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:EQ}(),kT],null)]))],null)],null));
$CLJS.X(pP,XK($CLJS.IE,$CLJS.H(["clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gi,new $CLJS.h(null,1,[$CLJS.ns,":case subclause"],null),$CLJS.MS,YR],null)],null)],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,":case options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.ur,!0],null),YR],null)],null)],null)])));
var lT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,pP],null);
$CLJS.X(WP,YK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof kS?new $CLJS.Dc(function(){return kS},$CLJS.kd(NO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BN,"metabase/mbql/schema.cljc",51,$CLJS.Hq,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Hq,$CLJS.pD,$CLJS.V($CLJS.qj,RO),nQ,$CLJS.V($CLJS.qj,RO),DM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,RO))],null),$CLJS.Hc,"Schema for a valid + clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:BN}(),kS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof lS?new $CLJS.Dc(function(){return lS},
$CLJS.kd(Nka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CN,"metabase/mbql/schema.cljc",51,$CLJS.Tr,1,615,615,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Tr,$CLJS.pD,$CLJS.V($CLJS.qj,MP),
nQ,$CLJS.V($CLJS.qj,RO),DM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,RO))],null),$CLJS.Hc,"Schema for a valid - clause.",$CLJS.n(lS)?lS.H:null])):null));return $CLJS.n(a)?a:CN}(),lS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL?new $CLJS.Dc(function(){return mS},$CLJS.kd(Mka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,eO,$CLJS.Ai,$CLJS.Ti,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],
[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Uy,"metabase/mbql/schema.cljc",$CLJS.uF,1,618,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.uF,$CLJS.pD,$CLJS.V($CLJS.qj,MP),nQ,$CLJS.V($CLJS.qj,MP),DM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,MP))],null),$CLJS.Hc,"Schema for a valid / clause.",$CLJS.n(mS)?mS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.Uy}(),mS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof nS?new $CLJS.Dc(function(){return nS},$CLJS.kd(Oka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AN,"metabase/mbql/schema.cljc",51,$CLJS.Iq,1,620,620,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Iq,$CLJS.pD,$CLJS.V($CLJS.qj,MP),nQ,$CLJS.V($CLJS.qj,MP),DM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,MP))],null),$CLJS.Hc,"Schema for a valid * clause.",$CLJS.n(nS)?nS.H:null])):null));return $CLJS.n(a)?a:AN}(),nS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof $R?new $CLJS.Dc(function(){return $R},
$CLJS.kd(zP,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",58,$CLJS.cF,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.cF,wQ,$CLJS.V($CLJS.qj,qL),vQ,$CLJS.V($CLJS.qj,
qL),DM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,qL))],null),$CLJS.Hc,"Schema for a valid coalesce clause.",$CLJS.n($R)?$R.H:null])):null));return $CLJS.n(a)?a:OP}(),$R],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof bS?new $CLJS.Dc(function(){return bS},$CLJS.kd(ela,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,
$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",56,$CLJS.Hw,1,585,585,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Hw,$CLJS.dx,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Hc,"Schema for a valid length clause.",$CLJS.n(bS)?bS.H:null])):null));return $CLJS.n(a)?a:gP}(),bS],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof oS?new $CLJS.Dc(function(){return oS},$CLJS.kd(Vja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bO,"metabase/mbql/schema.cljc",55,$CLJS.YE,1,622,622,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YE,$CLJS.pD,$CLJS.V($CLJS.qj,MP)],null),$CLJS.Hc,"Schema for a valid floor clause.",$CLJS.n(oS)?oS.H:null])):null));return $CLJS.n(a)?a:bO}(),oS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof pS?new $CLJS.Dc(function(){return pS},$CLJS.kd(Pja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),
$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OL,"metabase/mbql/schema.cljc",54,$CLJS.uE,1,625,625,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uE,$CLJS.pD,$CLJS.V($CLJS.qj,MP)],null),$CLJS.Hc,"Schema for a valid ceil clause.",$CLJS.n(pS)?
pS.H:null])):null));return $CLJS.n(a)?a:OL}(),pS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof qS?new $CLJS.Dc(function(){return qS},$CLJS.kd(dka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",55,$CLJS.eF,1,628,628,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eF,$CLJS.pD,$CLJS.V($CLJS.qj,MP)],null),$CLJS.Hc,"Schema for a valid round clause.",$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?a:SN}(),qS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&
"undefined"!==typeof rS?new $CLJS.Dc(function(){return rS},$CLJS.kd(Mla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uO,"metabase/mbql/schema.cljc",53,$CLJS.zE,1,631,631,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.zE,$CLJS.pD,$CLJS.V($CLJS.qj,MP)],null),$CLJS.Hc,"Schema for a valid abs clause.",$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?a:uO}(),rS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof sS?new $CLJS.Dc(function(){return sS},$CLJS.kd(zla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,
ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[KQ,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",69,$CLJS.wE,1,634,634,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.wE,$CLJS.pD,$CLJS.V($CLJS.qj,MP),nQ,$CLJS.V($CLJS.qj,MP)],null),$CLJS.Hc,"Schema for a valid power clause.",$CLJS.n(sS)?sS.H:null])):null));return $CLJS.n(a)?a:fQ}(),sS],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof tS?new $CLJS.Dc(function(){return tS},$CLJS.kd(wka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[KQ,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wN,"metabase/mbql/schema.cljc",68,$CLJS.NE,1,637,637,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NE,$CLJS.pD,$CLJS.V($CLJS.qj,MP)],null),$CLJS.Hc,"Schema for a valid sqrt clause.",$CLJS.n(tS)?tS.H:null])):null));return $CLJS.n(a)?a:wN}(),tS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof uS?new $CLJS.Dc(function(){return uS},$CLJS.kd(Ala,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),
$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[KQ,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",67,$CLJS.qE,1,640,640,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qE,$CLJS.pD,$CLJS.V($CLJS.qj,MP)],null),$CLJS.Hc,"Schema for a valid exp clause.",$CLJS.n(uS)?
uS.H:null])):null));return $CLJS.n(a)?a:UN}(),uS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof vS?new $CLJS.Dc(function(){return vS},$CLJS.kd(Ija,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[KQ,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",67,$CLJS.wF,1,643,643,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wF,$CLJS.pD,$CLJS.V($CLJS.qj,MP)],null),$CLJS.Hc,"Schema for a valid log clause.",$CLJS.n(vS)?vS.H:null])):null));return $CLJS.n(a)?a:PQ}(),vS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&
"undefined"!==typeof lT?new $CLJS.Dc(function(){return lT},$CLJS.kd(BL,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",61,$CLJS.IE,1,909,909,new $CLJS.Q(null,
5,5,$CLJS.R,[$CLJS.IE,$CLJS.Uv,$CLJS.V($CLJS.qj,TM),$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,EO))],null),$CLJS.Hc,"Schema for a valid case clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:lO}(),lT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof wS?new $CLJS.Dc(function(){return wS},$CLJS.kd(Lja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,
$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.PA,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vO,"metabase/mbql/schema.cljc",65,$CLJS.PA,1,656,656,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.PA,Yja,$CLJS.V($CLJS.qj,$Q),Zja,$CLJS.V($CLJS.qj,$Q),$CLJS.ci,$CLJS.V($CLJS.qj,Eka)],null),$CLJS.Hc,"Schema for a valid datetime-diff clause.",
$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:vO}(),wS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof xS?new $CLJS.Dc(function(){return xS},$CLJS.kd(ila,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(KP,
new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",71,$CLJS.UA,1,661,661,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.UA,$CLJS.SH,$CLJS.V($CLJS.qj,$Q),$CLJS.ci,$CLJS.V($CLJS.qj,Fla),$CLJS.hi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,hR))],null),$CLJS.Hc,"Schema for a valid temporal-extract clause.",$CLJS.n(xS)?xS.H:null])):null));return $CLJS.n(a)?a:OO}(),xS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof yS?new $CLJS.Dc(function(){return yS},$CLJS.kd(pka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kM,"metabase/mbql/schema.cljc",71,$CLJS.NH,1,667,667,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NH,$CLJS.xO,$CLJS.V($CLJS.qj,$Q)],null),$CLJS.Hc,"Schema for a valid get-year clause.",$CLJS.n(yS)?yS.H:null])):null));return $CLJS.n(a)?a:kM}(),yS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof zS?new $CLJS.Dc(function(){return zS},$CLJS.kd(eka,new $CLJS.h(null,1,[$CLJS.xj,!0],
null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",74,$CLJS.cI,1,670,670,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cI,$CLJS.xO,$CLJS.V($CLJS.qj,$Q)],null),$CLJS.Hc,"Schema for a valid get-quarter clause.",
$CLJS.n(zS)?zS.H:null])):null));return $CLJS.n(a)?a:MO}(),zS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof AS?new $CLJS.Dc(function(){return AS},$CLJS.kd(Lka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),
$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",72,$CLJS.GH,1,673,673,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GH,$CLJS.xO,$CLJS.V($CLJS.qj,$Q)],null),$CLJS.Hc,"Schema for a valid get-month clause.",$CLJS.n(AS)?AS.H:null])):null));return $CLJS.n(a)?a:iP}(),AS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==
typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof BS?new $CLJS.Dc(function(){return BS},$CLJS.kd(Ela,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",
71,$CLJS.SA,1,676,676,!0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.SA,$CLJS.xO,$CLJS.V($CLJS.qj,$Q),$CLJS.hi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,hR))],null),$CLJS.Hc,"Schema for a valid get-week clause.",$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:hP}(),BS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof CS?new $CLJS.Dc(function(){return CS},$CLJS.kd(zja,new $CLJS.h(null,1,[$CLJS.xj,
!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",70,$CLJS.WH,1,680,680,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WH,$CLJS.xO,$CLJS.V($CLJS.qj,$Q)],null),$CLJS.Hc,"Schema for a valid get-day clause.",
$CLJS.n(CS)?CS.H:null])):null));return $CLJS.n(a)?a:aP}(),CS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof DS?new $CLJS.Dc(function(){return DS},$CLJS.kd(Fka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),
$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",78,$CLJS.ZH,1,683,683,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZH,$CLJS.xO,$CLJS.V($CLJS.qj,$Q)],null),$CLJS.Hc,"Schema for a valid get-day-of-week clause.",$CLJS.n(DS)?DS.H:null])):null));return $CLJS.n(a)?a:SO}(),DS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==
typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof ES?new $CLJS.Dc(function(){return ES},$CLJS.kd(Dka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rL,"metabase/mbql/schema.cljc",
71,$CLJS.aI,1,686,686,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aI,$CLJS.SH,$CLJS.V($CLJS.qj,$Q)],null),$CLJS.Hc,"Schema for a valid get-hour clause.",$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:rL}(),ES],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof FS?new $CLJS.Dc(function(){return FS},$CLJS.kd(mla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,
$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",73,$CLJS.YH,1,689,689,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,$CLJS.SH,$CLJS.V($CLJS.qj,$Q)],null),$CLJS.Hc,"Schema for a valid get-minute clause.",$CLJS.n(FS)?FS.H:null])):null));
return $CLJS.n(a)?a:TQ}(),FS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof GS?new $CLJS.Dc(function(){return GS},$CLJS.kd(Aka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,
[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",73,$CLJS.MH,1,692,692,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MH,$CLJS.SH,$CLJS.V($CLJS.qj,$Q)],null),$CLJS.Hc,"Schema for a valid get-second clause.",$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:nO}(),GS],null)])));
$CLJS.X(GL,YK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof aS?new $CLJS.Dc(function(){return aS},$CLJS.kd(cla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qP,"metabase/mbql/schema.cljc",59,$CLJS.rE,1,582,582,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.rE,$CLJS.dx,$CLJS.V($CLJS.qj,RP),$CLJS.nu,$CLJS.V($CLJS.qj,aka),$CLJS.Hw,$CLJS.V($CLJS.qj,$CLJS.V(cQ,MP))],null),$CLJS.Hc,"Schema for a valid substring clause.",$CLJS.n(aS)?aS.H:null])):null));return $CLJS.n(a)?a:qP}(),aS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof cS?new $CLJS.Dc(function(){return cS},
$CLJS.kd(Cla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NP,"metabase/mbql/schema.cljc",54,$CLJS.pF,1,588,588,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pF,$CLJS.dx,$CLJS.V($CLJS.qj,RP)],
null),$CLJS.Hc,"Schema for a valid trim clause.",$CLJS.n(cS)?cS.H:null])):null));return $CLJS.n(a)?a:NP}(),cS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof eS?new $CLJS.Dc(function(){return eS},$CLJS.kd(Gka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,
1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",55,$CLJS.rF,1,594,594,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rF,$CLJS.dx,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Hc,"Schema for a valid ltrim clause.",$CLJS.n(eS)?eS.H:null])):null));return $CLJS.n(a)?a:wP}(),eS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&
"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof dS?new $CLJS.Dc(function(){return dS},$CLJS.kd(dla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",
55,$CLJS.yF,1,591,591,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yF,$CLJS.dx,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Hc,"Schema for a valid rtrim clause.",$CLJS.n(dS)?dS.H:null])):null));return $CLJS.n(a)?a:zO}(),dS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof hS?new $CLJS.Dc(function(){return hS},$CLJS.kd(uja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,
$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yQ,"metabase/mbql/schema.cljc",57,$CLJS.xF,1,603,603,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.xF,$CLJS.dx,$CLJS.V($CLJS.qj,RP),Gja,$CLJS.V($CLJS.qj,$CLJS.wj),$CLJS.az,$CLJS.V($CLJS.qj,$CLJS.wj)],null),$CLJS.Hc,"Schema for a valid replace clause.",
$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:yQ}(),hS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof gS?new $CLJS.Dc(function(){return gS},$CLJS.kd(gla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,
new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cR,"metabase/mbql/schema.cljc",55,$CLJS.js,1,600,600,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.js,$CLJS.dx,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Hc,"Schema for a valid lower clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:cR}(),gS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==
typeof bL&&"undefined"!==typeof fS?new $CLJS.Dc(function(){return fS},$CLJS.kd(Dla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$P,"metabase/mbql/schema.cljc",55,$CLJS.ds,1,597,597,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ds,$CLJS.dx,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Hc,"Schema for a valid upper clause.",$CLJS.n(fS)?fS.H:null])):null));return $CLJS.n(a)?a:$P}(),fS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof iS?new $CLJS.Dc(function(){return iS},$CLJS.kd(Vka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,
$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nN,"metabase/mbql/schema.cljc",56,$CLJS.yE,1,606,606,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.yE,wQ,$CLJS.V($CLJS.qj,RP),vQ,$CLJS.V($CLJS.qj,RP),DM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,RP))],null),$CLJS.Hc,"Schema for a valid concat clause.",
$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:nN}(),iS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof jS?new $CLJS.Dc(function(){return jS},$CLJS.kd(bla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.VO,"null",$CLJS.kj,"null"],
null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iM,"metabase/mbql/schema.cljc",74,$CLJS.bF,1,609,609,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bF,$CLJS.dx,$CLJS.V($CLJS.qj,RP),$CLJS.$y,$CLJS.V($CLJS.qj,$CLJS.wj)],null),$CLJS.Hc,"Schema for a valid regex-match-first clause.",$CLJS.n(jS)?jS.H:null])):null));return $CLJS.n(a)?a:iM}(),jS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==
typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof $R?new $CLJS.Dc(function(){return $R},$CLJS.kd(zP,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
OP,"metabase/mbql/schema.cljc",58,$CLJS.cF,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.cF,wQ,$CLJS.V($CLJS.qj,qL),vQ,$CLJS.V($CLJS.qj,qL),DM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,qL))],null),$CLJS.Hc,"Schema for a valid coalesce clause.",$CLJS.n($R)?$R.H:null])):null));return $CLJS.n(a)?a:OP}(),$R],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof lT?new $CLJS.Dc(function(){return lT},
$CLJS.kd(BL,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",61,$CLJS.IE,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.IE,$CLJS.Uv,$CLJS.V($CLJS.qj,TM),
$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,EO))],null),$CLJS.Hc,"Schema for a valid case clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:lO}(),lT],null)])));
$CLJS.mT=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ns,":field or :expression reference or expression",$CLJS.wi,function(a){return $CLJS.n(WK(NR,a))?aR:$CLJS.n(WK(KR,a))?$CLJS.wj:$CLJS.n(WK(OR,a))?$CLJS.qr:$CLJS.n(WK(QR,a))?$CLJS.SH:$CLJS.n(WK($CLJS.IE,a))?$CLJS.IE:$CLJS.fs}],null),new $CLJS.Q(null,2,5,$CLJS.R,[aR,RR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,LR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qr,SR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SH,$CLJS.TR],null),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IE,lT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,$CLJS.HR],null)],null);$CLJS.X(HQ,XK($CLJS.Jw,$CLJS.H(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,$CLJS.HR],null)])));var nT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,HQ],null);$CLJS.X(wO,XK($CLJS.aF,$CLJS.H(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ur,$CLJS.HR],null)])));var oT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,wO],null);$CLJS.X(EL,XK($CLJS.UE,$CLJS.H(["field-or-expression",$CLJS.mT])));
var pT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,EL],null);$CLJS.X(zQ,XK($CLJS.vF,$CLJS.H(["field-or-expression",$CLJS.mT])));var qT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,zQ],null);$CLJS.X(dN,XK($CLJS.EE,$CLJS.H(["field-or-expression",$CLJS.mT])));var rT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dN],null);$CLJS.X(PP,XK($CLJS.lF,$CLJS.H(["field-or-expression",$CLJS.mT])));var sT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,PP],null);$CLJS.X(ZM,XK($CLJS.en,$CLJS.H(["field-or-expression",$CLJS.mT])));
var tT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,ZM],null);$CLJS.X(LM,XK($CLJS.Cj,$CLJS.H(["field-or-expression",$CLJS.mT])));var uT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,LM],null);$CLJS.X(FM,XK($CLJS.tF,$CLJS.H(["field-or-expression",$CLJS.mT,"pred",$CLJS.MS])));var vT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,FM],null);$CLJS.X(WM,XK($CLJS.iF,$CLJS.H(["pred",$CLJS.MS])));var wT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,WM],null);$CLJS.X(GP,XK($CLJS.gF,$CLJS.H(["pred",$CLJS.MS])));
var xT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,GP],null);$CLJS.X(bM,XK($CLJS.oF,$CLJS.H(["field-or-expression",$CLJS.mT])));var yT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bM],null);$CLJS.X(dL,XK($CLJS.FE,$CLJS.H(["field-or-expression",$CLJS.mT])));var zT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dL],null);$CLJS.X(hN,XK($CLJS.mF,$CLJS.H(["field-or-expression",$CLJS.mT])));var AT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,hN],null);$CLJS.X(XN,XK($CLJS.LE,$CLJS.H(["field-or-expression",$CLJS.mT,"percentile",VR])));
var BT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,XN],null);$CLJS.X(JP,XK($CLJS.VE,$CLJS.H(["metric-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.CJ],null),iR],null)])));var CT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,JP],null);
$CLJS.X(FP,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ns,"unnamed aggregation clause or numeric expression",$CLJS.wi,function(a){return $CLJS.n(WK(NR,a))?$N:$CLJS.fs}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$N,RR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,YK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof pT?new $CLJS.Dc(function(){return pT},$CLJS.kd(ala,
new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iO,"metabase/mbql/schema.cljc",60,$CLJS.UE,1,957,957,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UE,aQ,$CLJS.V($CLJS.qj,TP)],null),$CLJS.Hc,"Schema for a valid avg clause.",
$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:iO}(),pT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof qT?new $CLJS.Dc(function(){return qT},$CLJS.kd(lka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,
new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",64,$CLJS.vF,1,958,958,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vF,aQ,$CLJS.V($CLJS.qj,TP)],null),$CLJS.Hc,"Schema for a valid cum-sum clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:mQ}(),qT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==
typeof bL&&"undefined"!==typeof rT?new $CLJS.Dc(function(){return rT},$CLJS.kd(cka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mO,"metabase/mbql/schema.cljc",65,$CLJS.EE,1,959,959,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EE,aQ,$CLJS.V($CLJS.qj,TP)],null),$CLJS.Hc,"Schema for a valid distinct clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:mO}(),rT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof yT?new $CLJS.Dc(function(){return yT},$CLJS.kd(wla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,
$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.jF,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yL,"metabase/mbql/schema.cljc",76,$CLJS.oF,1,973,973,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oF,aQ,$CLJS.V($CLJS.qj,TP)],null),$CLJS.Hc,"Schema for a valid stddev clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:yL}(),yT],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof sT?new $CLJS.Dc(function(){return sT},$CLJS.kd(Qka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UL,"metabase/mbql/schema.cljc",60,$CLJS.lF,1,960,960,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lF,aQ,$CLJS.V($CLJS.qj,TP)],null),$CLJS.Hc,"Schema for a valid sum clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:UL}(),sT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof tT?new $CLJS.Dc(function(){return tT},$CLJS.kd(Dja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,
$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Gr,"metabase/mbql/schema.cljc",60,$CLJS.en,1,961,961,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.en,aQ,$CLJS.V($CLJS.qj,TP)],null),$CLJS.Hc,"Schema for a valid min clause.",$CLJS.n(tT)?tT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Gr}(),tT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof uT?new $CLJS.Dc(function(){return uT},$CLJS.kd(yja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Kr,"metabase/mbql/schema.cljc",60,$CLJS.Cj,1,962,962,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Cj,aQ,$CLJS.V($CLJS.qj,TP)],null),$CLJS.Hc,"Schema for a valid max clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:$CLJS.Kr}(),uT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&
"undefined"!==typeof CT?new $CLJS.Dc(function(){return CT},$CLJS.kd($ja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mN,"metabase/mbql/schema.cljc",18,$CLJS.VE,1,994,994,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VE,mka,$CLJS.V($CLJS.qj,new $CLJS.Q(null,3,
5,$CLJS.R,[$CLJS.ar,qka,PL],null))],null),$CLJS.Hc,"Schema for a valid metric clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:mN}(),CT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof xT?new $CLJS.Dc(function(){return xT},$CLJS.kd(yka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,
$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cN,"metabase/mbql/schema.cljc",62,$CLJS.gF,1,970,970,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gF,$CLJS.tn,$CLJS.V($CLJS.qj,VN)],null),$CLJS.Hc,"Schema for a valid share clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:cN}(),xT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof wT?new $CLJS.Dc(function(){return wT},$CLJS.kd(Eja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QL,"metabase/mbql/schema.cljc",68,$CLJS.iF,1,967,967,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iF,$CLJS.tn,$CLJS.V($CLJS.qj,VN)],null),$CLJS.Hc,"Schema for a valid count-where clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:QL}(),wT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof vT?new $CLJS.Dc(function(){return vT},$CLJS.kd(Kla,new $CLJS.h(null,1,[$CLJS.xj,!0],
null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jO,"metabase/mbql/schema.cljc",66,$CLJS.tF,1,964,964,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.tF,aQ,$CLJS.V($CLJS.qj,TP),$CLJS.tn,$CLJS.V($CLJS.qj,VN)],null),$CLJS.Hc,"Schema for a valid sum-where clause.",
$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:jO}(),vT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof lT?new $CLJS.Dc(function(){return lT},$CLJS.kd(BL,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,
new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",61,$CLJS.IE,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.IE,$CLJS.Uv,$CLJS.V($CLJS.qj,TM),$CLJS.Mi,$CLJS.V($CLJS.qj,$CLJS.V(cQ,EO))],null),$CLJS.Hc,"Schema for a valid case clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:lO}(),lT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&
"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof AT?new $CLJS.Dc(function(){return AT},$CLJS.kd(pla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.WE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",
68,$CLJS.mF,1,979,979,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mF,aQ,$CLJS.V($CLJS.qj,TP)],null),$CLJS.Hc,"Schema for a valid median clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:kQ}(),AT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof BT?new $CLJS.Dc(function(){return BT},$CLJS.kd(Kka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,
eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.WE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",72,$CLJS.LE,1,982,982,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.LE,aQ,$CLJS.V($CLJS.qj,TP),$CLJS.LE,$CLJS.V($CLJS.qj,MP)],null),$CLJS.Hc,"Schema for a valid percentile clause.",$CLJS.n(BT)?BT.H:null])):
null));return $CLJS.n(a)?a:IP}(),BT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof zT?new $CLJS.Dc(function(){return zT},$CLJS.kd(Oja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tL,"metabase/mbql/schema.cljc",77,$CLJS.FE,1,976,976,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FE,aQ,$CLJS.V($CLJS.qj,TP)],null),$CLJS.Hc,"Schema for a valid var clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:tL}(),zT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof oT?new $CLJS.Dc(function(){return oT},$CLJS.kd(Aja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,
$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WN,"metabase/mbql/schema.cljc",74,$CLJS.aF,1,947,947,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,$CLJS.$E,$CLJS.V($CLJS.qj,$CLJS.V(cQ,zM))],null),$CLJS.Hc,"Schema for a valid cum-count clause.",
$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:WN}(),oT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof nT?new $CLJS.Dc(function(){return nT},$CLJS.kd(bka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([cO,$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,eQ,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tE,"null"],null),null),
$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.fi,"metabase/mbql/schema.cljc",70,$CLJS.Jw,1,946,946,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Jw,$CLJS.$E,$CLJS.V($CLJS.qj,$CLJS.V(cQ,zM))],null),$CLJS.Hc,"Schema for a valid count clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:$CLJS.fi}(),nT],null)]))],null)],null));
$CLJS.X(YO,XK($CLJS.oL,$CLJS.H(["aggregation",FP,"options",new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.ns,":aggregation-options options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.ur,!0],null),iR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),iR],null)],null)])));
$CLJS.X(bQ,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ns,"aggregation clause or numeric expression",$CLJS.wi,function(a){return $CLJS.n(WK($CLJS.oL,a))?$CLJS.oL:SM}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,YO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[SM,FP],null)],null));$CLJS.X(xL,XK($CLJS.XE,$CLJS.H(["field",JR])));var DT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,xL],null);$CLJS.X(ZN,XK($CLJS.QE,$CLJS.H(["field",JR])));
ET=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,ZN],null);
bma=YK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof DT?new $CLJS.Dc(function(){return DT},$CLJS.kd(tla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
FN,"metabase/mbql/schema.cljc",15,$CLJS.XE,1,1049,1049,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XE,$CLJS.$E,$CLJS.V($CLJS.qj,uQ)],null),$CLJS.Hc,"Schema for a valid asc clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:FN}(),DT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof ET?new $CLJS.Dc(function(){return ET},$CLJS.kd(kka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,
$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",16,$CLJS.QE,1,1050,1050,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QE,$CLJS.$E,$CLJS.V($CLJS.qj,uQ)],null),$CLJS.Hc,"Schema for a valid desc clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:AP}(),ET],null)]));
FT=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Yq,$CLJS.AL,$CLJS.pM,$CLJS.sE,$CLJS.Js,$CLJS.nA,$CLJS.xO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,iR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cD,iR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Gi,new $CLJS.h(null,1,[$CLJS.ur,!0],null),iR],null)],null);
cma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,FT,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.AL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mL,iR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PO,lR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QO,new $CLJS.h(null,1,[$CLJS.ur,!0],null),lR],null)],null)],null);
dma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,FT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.pM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YP,lR],null)],null)],null);
GT=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,FT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.Xh],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RM,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null)],null)],null);ema=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,lQ],null);
fma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,GT,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.sE],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sE,GR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,TL],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Mi,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,$CLJS.Ei,$CLJS.Xh],
null)],null)],null)],null)],null);$CLJS.HT=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.xO,null,$CLJS.Js,null,$CLJS.qr,null,$CLJS.nA,null],null),null);gma=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Yq],null),$CLJS.HT);
$CLJS.X(tP,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.wi,$CLJS.Si],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sE,fma],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AL,cma],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pM,dma],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Un,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,GT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,gma],null)],null)],null)],null)],null));
var IT=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BP,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,iR,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,tP],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.U.h(b))},a)}],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,iR],null)],null)],null),hma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,IT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vE,$CLJS.Xh],null)],null)],null),JT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bP],null),KT=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.wi,function(a){return $CLJS.n($CLJS.WC($CLJS.td,
$CLJS.eP)(a))?$CLJS.eP:$CLJS.bD}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eP,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,IT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eP,$CLJS.Xh],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bD,JT],null)],null),LT=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,iR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UB,jR],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ska,iR],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.zN,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,kR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MM,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.Bj],null)],null)],null),MT=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,Ula,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ns,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),ima=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Yq],null),
new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.XD,null,$CLJS.GD,null,$CLJS.QD,null,$CLJS.UD,null],null),null)),NT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,jQ],null);
$CLJS.X(bN,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aO,new $CLJS.h(null,1,[$CLJS.ur,!0],null),MT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DP,new $CLJS.h(null,1,[$CLJS.ur,!0],null),KT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.LQ,$CLJS.MS],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),ima],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ns,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yq,$CLJS.ox,$CLJS.Vy],null),NT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),iR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,nR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NN,new $CLJS.h(null,
1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,LT],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.WC($CLJS.pz($CLJS.aO,$CLJS.DP),$CLJS.Oe($CLJS.WC($CLJS.aO,$CLJS.DP)))],null)],null));var jma=$CLJS.R,OT;var PT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bN],null)],null);
if($CLJS.sd(PT)&&$CLJS.E.g($CLJS.z(PT),$CLJS.bj)){var QT=$CLJS.y(PT);$CLJS.z(QT);var RT=$CLJS.B(QT),kma=$CLJS.td($CLJS.z(RT))?RT:$CLJS.be(null,RT),ST=$CLJS.y(kma),lma=$CLJS.z(ST),mma=$CLJS.B(ST);OT=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,$CLJS.T.j(lma,$CLJS.en,1)],null),mma)}else OT=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,PT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(dM,new $CLJS.Q(null,3,5,jma,[$CLJS.gr,OT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"All join aliases must be unique."],null),function(a){return $CLJS.IK($CLJS.ff($CLJS.Ua,$CLJS.cf.g($CLJS.LD,a)))}],null)],null));var nma=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dM],null);
$CLJS.X(jQ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Nk,new $CLJS.h(null,1,[$CLJS.ns,"Distinct, non-empty sequence of Field clauses"],null),ZK(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.en,1],null),$CLJS.HR],null))],null));
$CLJS.X(bP,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DP,new $CLJS.h(null,1,[$CLJS.ur,!0],null),KT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aO,new $CLJS.h(null,1,[$CLJS.ur,!0],null),MT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PE,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.en,1],null),$CLJS.UR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OE,new $CLJS.h(null,1,
[$CLJS.ur,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.en,1],null),$CLJS.HR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VO,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,iR,$CLJS.mT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HD,new $CLJS.h(null,1,[$CLJS.ur,!0],null),NT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DE,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.MS],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fR,new $CLJS.h(null,
1,[$CLJS.ur,!0],null),mR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JQ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),ZK(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.en,1],null),bma],null))],null),new $CLJS.Q(null,3,5,$CLJS.R,[fP,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[fP,lR],null),new $CLJS.Q(null,2,5,$CLJS.R,[Tja,lR],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xM,new $CLJS.h(null,1,[$CLJS.ur,!0],null),nma],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NN,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,LT],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.Al(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.DP,$CLJS.aO],null))))}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,
new $CLJS.h(null,1,[$CLJS.ns,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.OE);b=$CLJS.J.g(b,$CLJS.HD);return $CLJS.pd($CLJS.Ws.g($CLJS.Wg(a),$CLJS.Wg(b)))}],null)],null));
$CLJS.TT=$CLJS.zg([$CLJS.sF,$CLJS.xO,YM,JM,ON,XQ,uN,$CLJS.WQ,$CLJS.Js,tN,HL,QP,MQ,$CLJS.XL,IQ,VM,$CLJS.TN,rN,$CLJS.Gi,$CLJS.qN,pL,$CLJS.tM,$CLJS.pN,sO,$CLJS.qr,$CLJS.nA,lN],[new $CLJS.h(null,1,[EN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.sF,null,$CLJS.xO,null,$CLJS.Js,null,$CLJS.qr,null,$CLJS.nA,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.sF,null,$CLJS.xO,null,$CLJS.WQ,null,$CLJS.TN,null,$CLJS.Gi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Si,$CLJS.wj,$CLJS.kD,ZP,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[YM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,aR,$CLJS.kD,ZP,EN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.sF,null,JM,null,$CLJS.Js,null,$CLJS.Gi,null,pL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,$CLJS.wj,$CLJS.kD,LL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[ON,null],null),null)],null),new $CLJS.h(null,1,[EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[XQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,
aR,$CLJS.kD,jla,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[uN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.WQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,aR,EN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.sF,null,JM,null,$CLJS.Js,null,$CLJS.Gi,null,pL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,aR,$CLJS.kD,LL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[tN,null],null),null)],null),new $CLJS.h(null,1,[EN,new $CLJS.Rg(null,
new $CLJS.h(null,1,[HL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,$CLJS.wj,$CLJS.kD,ZP,EN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.sF,null,XQ,null,HL,null,QP,null,rN,null,$CLJS.Gi,null,pL,null,$CLJS.nA,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,$CLJS.wj,$CLJS.kD,LL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[MQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.WQ,null,$CLJS.XL,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Si,$CLJS.wj,$CLJS.kD,LL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[IQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,$CLJS.wj,$CLJS.kD,LL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[VM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.sF,null,$CLJS.xO,null,$CLJS.WQ,null,$CLJS.TN,null,$CLJS.Gi,null],null),null)],null),new $CLJS.h(null,1,[EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[rN,null],null),null)],null),new $CLJS.h(null,1,[EN,
new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.Gi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.WQ,null,$CLJS.qN,null],null),null)],null),new $CLJS.h(null,1,[EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[pL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.WQ,null,$CLJS.tM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.WQ,
null,$CLJS.pN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,aR,$CLJS.kD,ZP,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[sO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.qr,EN,new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.sF,null,$CLJS.Gi,null,$CLJS.qr,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.wj,EN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.sF,null,XQ,null,HL,null,QP,null,rN,null,$CLJS.Gi,null,pL,null,$CLJS.nA,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,
aR,$CLJS.kD,LL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[lN,null],null),null)],null)]);$CLJS.X(lQ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"valid parameter type"],null)],null),$CLJS.Ng($CLJS.TT)));$CLJS.X(TL,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ns,"valid template tag widget type"],null),$CLJS.Vy],null),$CLJS.Ng($CLJS.TT)));
$CLJS.X(yP,XK(Kja,$CLJS.H(["tag-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,iR,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gi,iR],null)],null)],null)])));var UT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,yP],null);$CLJS.X(sL,XK($CLJS.sE,$CLJS.H(["target",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.HR,UT],null)])));var VT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,sL],null);$CLJS.X(nM,XK(oN,$CLJS.H(["target",UT])));
var WT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,nM],null),oma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.HR,YK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof VT?new $CLJS.Dc(function(){return VT},$CLJS.kd(Ika,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",21,$CLJS.sE,1,1612,1612,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sE,$CLJS.fI,$CLJS.V($CLJS.qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,zM,DN],null))],null),$CLJS.Hc,"Schema for a valid dimension clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:jP}(),VT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.$K&&
"undefined"!==typeof aL&&"undefined"!==typeof bL&&"undefined"!==typeof WT?new $CLJS.Dc(function(){return WT},$CLJS.kd(Yka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.U,$CLJS.Hj,$CLJS.ei,eO,$CLJS.Ai,$CLJS.Ti,$CLJS.Mj,ZL,$CLJS.di,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bR,"metabase/mbql/schema.cljc",20,oN,1,1615,1615,new $CLJS.Q(null,3,5,$CLJS.R,[oN,
$CLJS.fI,$CLJS.V($CLJS.qj,DN)],null),$CLJS.Hc,"Schema for a valid variable clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:bR}(),WT],null)]))],null),TK=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$L],null);
$CLJS.X($L,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gr,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.WC($CLJS.pz($CLJS.eP,$CLJS.vE),$CLJS.Oe($CLJS.WC($CLJS.eP,$CLJS.vE)))],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.eP);var d=$CLJS.J.g(c,$CLJS.vE);c=$CLJS.J.g(c,$CLJS.Si);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QO,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ns,"valid Database ID"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.WJ],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.W,$CLJS.OJ],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yq,$CLJS.vE,$CLJS.eP],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eP,new $CLJS.h(null,1,[$CLJS.ur,!0],null),hma],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vE,new $CLJS.h(null,1,[$CLJS.ur,!0],null),JT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oM,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,new $CLJS.Q(null,
9,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,ema],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Gi,new $CLJS.h(null,1,[$CLJS.ur,!0],null),iR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fI,new $CLJS.h(null,1,[$CLJS.ur,!0],null),oma],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.Xh],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.ur,!0],null),iR],null),new $CLJS.Q(null,3,5,$CLJS.R,[Qja,new $CLJS.h(null,1,[$CLJS.ur,!0],null),
iR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.Xh],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RM,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.Xh],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[Ila,new $CLJS.h(null,1,[$CLJS.ur,!0],null),vR],null)],null)],null)],null),new $CLJS.Q(null,
3,5,$CLJS.R,[Hka,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[kO,new $CLJS.h(null,1,[$CLJS.ur,!0],null),mR],null),new $CLJS.Q(null,3,5,$CLJS.R,[SL,new $CLJS.h(null,1,[$CLJS.ur,!0],null),mR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.J.g(b,kO);b=$CLJS.J.g(b,SL);return $CLJS.Ta($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[xka,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[sla,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null),new $CLJS.Q(null,3,5,$CLJS.R,[Mja,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null),new $CLJS.Q(null,3,5,$CLJS.R,[vja,new $CLJS.h(null,1,[$CLJS.ur,
!0],null),$CLJS.qr],null),new $CLJS.Q(null,3,5,$CLJS.R,[Sja,new $CLJS.h(null,1,[$CLJS.ur,!0],null),$CLJS.qr],null),new $CLJS.Q(null,3,5,$CLJS.R,[kla,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.qr],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[xja,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.qr],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[yla,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.qr],
null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yx,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jM,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,18,5,$CLJS.R,[$CLJS.Yq,Xka,Uka,$CLJS.fN,Wja,Sla,Zka,nka,Jka,fka,Nja,jka,hla,vka,Hja,Xja,Rla,Ola],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[nla,new $CLJS.h(null,1,[$CLJS.ur,
!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,lR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[ska,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,lR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YP,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,Tla],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Jla,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,iR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[uka,new $CLJS.h(null,
1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,lR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Hla,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,$CLJS.Xh,$CLJS.Xh],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[oka,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,lR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iN,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.$q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,$CLJS.Xh,$CLJS.Xh],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AJ,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$q,$CLJS.Xh],null)],null)],null)],null)],null)],null)),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Oe($CLJS.NN)],
null)],null));var XT=$CLJS.SK(TK);(function(){var a=qja();return function(b){if($CLJS.n(XT.h?XT.h(b):XT.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.PK(b);throw $CLJS.Th($CLJS.YC("Invalid query: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.ux,c,Cka,b],null));}})();