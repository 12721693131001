var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.util.internal.time.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.js");
'use strict';var dpa,epa,fpa,U_,V_,W_,gpa,hpa,ipa,Y_,Z_,jpa;$CLJS.T_=function(a){var b=$CLJS.ed($CLJS.dh($CLJS.fh(["(.*?)",$CLJS.eH($CLJS.H([$CLJS.kH])),"$"].join("")),a));return $CLJS.n(b)?b:a};dpa=function(a,b){return a.isSame(b,"day")};epa=function(a,b){return a.isSame(b,"month")};fpa=function(a,b){return a.isSame(b,"year")};
U_=function(a){return $CLJS.Ne(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.kj);d=$CLJS.J.g(d,$CLJS.Q_);return $CLJS.n($CLJS.dh(b,a))?(b=$CLJS.FH.parseZone(a,d,!0),$CLJS.n(b.isValid())?new $CLJS.Q(null,2,5,$CLJS.R,[b,c],null):null):null},$CLJS.cpa)};V_=function(a){return a instanceof Date?$CLJS.FH.utc(a):a};W_=function(a,b,c){b="string"===typeof b?$CLJS.z(U_(b)):V_(b);return("string"===typeof c?$CLJS.z(U_(c)):V_(c)).diff(b,$CLJS.Xg(a))};
gpa=function(a,b){return W_.j?W_.j($CLJS.Ik,a,b):W_.call(null,$CLJS.Ik,a,b)};hpa=function(a,b){if("string"===typeof a)return a;a=V_(a);var c=function(){if($CLJS.tz(b,$CLJS.Ek))return $CLJS.N_;if($CLJS.tz(b,$CLJS.Jk))return $CLJS.M_;if($CLJS.tz(b,$CLJS.Bk))return $CLJS.R_;if($CLJS.tz(b,$CLJS.Dk))return $CLJS.O_;if($CLJS.tz(b,$CLJS.Ak))return $CLJS.P_;throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}();return $CLJS.S_.h(new $CLJS.Q(null,2,5,$CLJS.R,[a,c],null))};
$CLJS.X_=function(a){if($CLJS.n($CLJS.FH.isMoment(a)))return a;if("string"===typeof a)return(0,$CLJS.FH)($CLJS.T_(a),ipa);throw $CLJS.Th("Unknown input to coerce-to-time; expecting a string",new $CLJS.h(null,1,[$CLJS.ej,a],null));};ipa=["HH:mm:ss.SSS[Z]","HH:mm:ss.SSS","HH:mm:ss","HH:mm"];Y_=function Y_(a,b){if("string"===typeof a){var d=U_(a);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);b=Y_.g?Y_.g(a,b):Y_.call(null,a,b);return $CLJS.S_.h(new $CLJS.Q(null,2,5,$CLJS.R,[b,d],null))}a=V_(a);return a.startOf($CLJS.Xg(b))};
Z_=function Z_(a,b,c){if("string"===typeof a){var e=U_(a);a=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);b=Z_.j?Z_.j(a,b,c):Z_.call(null,a,b,c);return $CLJS.S_.h(new $CLJS.Q(null,2,5,$CLJS.R,[b,e],null))}a=V_(a);return a.add(c,$CLJS.Xg(b))};jpa=new $CLJS.M(null,"local","local",-1497766724);$CLJS.$_=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g($CLJS.fH,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.a0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g($CLJS.L_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.kpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(dpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.lpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(epa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.mpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(fpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.npa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(gpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.opa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(W_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.b0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(Y_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.ppa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(Z_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.qpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(hpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.c0=function c0(a){switch(arguments.length){case 1:return c0.h(arguments[0]);case 2:return c0.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.shared.util.time.coerce_to_timestamp",$CLJS.c0);$CLJS.c0.h=function(a){return $CLJS.c0.g(a,$CLJS.N)};
$CLJS.c0.g=function(a,b){b=$CLJS.uk.l($CLJS.H([$CLJS.N,$CLJS.Wz(b)]));if(!$CLJS.n($CLJS.K_(a))){var c=(c="string"===typeof a)?$CLJS.dh(/.*(Z|[+-]\d\d:?\d\d)$/,a):c;a=$CLJS.n(c)?$CLJS.FH.parseZone(a):"string"===typeof a?$CLJS.gH.g(a,b):$CLJS.hH.g(a,b)}return $CLJS.n(jpa.h(b))?a.local():a};$CLJS.c0.A=2;module.exports={coerce_to_timestamp:$CLJS.c0,coerce_to_time:$CLJS.X_};