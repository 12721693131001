var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var l0,zpa,Apa,m0,n0;$CLJS.j0=function(a,b){return $CLJS.ec($CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.dc(c,d):c},$CLJS.bc($CLJS.xf),b))};l0=function(a){return $CLJS.n(k0)?["(",$CLJS.p.h(a),")"].join(""):a};zpa=function(a,b,c){var d=$CLJS.zV(a,b);a=$CLJS.J_.j(a,b,d);var e=$CLJS.Rz(c);return $CLJS.Ne(function(f){return $CLJS.E.g($CLJS.Rz($CLJS.U.h(f)),e)},a)};
Apa=function(a,b){var c=$CLJS.fk.v(a,$CLJS.VO,$CLJS.GC($CLJS.Zd,$CLJS.xf),b);return $CLJS.vd($CLJS.HD.h(a))?$CLJS.fk.v(c,$CLJS.HD,$CLJS.Zd,$CLJS.jV(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gA,$CLJS.N,$CLJS.sV(b)],null))):c};m0=function(a,b,c){var d=$CLJS.sV(c);return $CLJS.T.l($CLJS.VZ.j(a,b,c),$CLJS.DJ,$CLJS.wY,$CLJS.H([$CLJS.U,d,$CLJS.cD,d]))};n0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.o0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var p0=function(){function a(d,e,f){var k=$CLJS.zV(d,e);k=$CLJS.jG($CLJS.ck.g($CLJS.Tg([f]),$CLJS.sV),$CLJS.VO.h(k));if($CLJS.n(k))return k;throw $CLJS.Th($CLJS.YC("No expression named {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.VQ,f,$CLJS.vE,d,$CLJS.yK,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.C_.m(null,$CLJS.gA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=p0.j(a,b,c);return $CLJS.D_.j(a,b,c)});$CLJS.E_.m(null,$CLJS.gA,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.fj,$CLJS.BJ,$CLJS.o0,$CLJS.oD.h(d),$CLJS.U,e,$CLJS.gD,e,$CLJS.cD,$CLJS.XZ.j(a,b,c),$CLJS.jA,$CLJS.D_.j(a,b,c),$CLJS.DJ,$CLJS.wY],null)});$CLJS.y_.m(null,$CLJS.bA,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.y_.m(null,$CLJS.$z,function(a,b,c){return $CLJS.p.h(c)});$CLJS.y_.m(null,$CLJS.aA,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.y_.m(null,$CLJS.pA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.y_.m(null,$CLJS.gA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.z_.m(null,$CLJS.gA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var k0=!1,Bpa=new $CLJS.h(null,4,[$CLJS.Hq,"+",$CLJS.Tr,"-",$CLJS.Iq,"×",$CLJS.uF,"÷"],null),q0=$CLJS.y(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Hq,$CLJS.Tr,$CLJS.uF,$CLJS.Iq],null)),r0=null,s0=0,t0=0;;)if(t0<s0){var Cpa=r0.X(null,t0);$CLJS.fE(Cpa,n0);t0+=1}else{var u0=$CLJS.y(q0);if(u0){var v0=u0;if($CLJS.wd(v0)){var w0=$CLJS.jc(v0),Dpa=$CLJS.kc(v0),Epa=w0,Fpa=$CLJS.D(w0);q0=Dpa;r0=Epa;s0=Fpa}else{var Gpa=$CLJS.z(v0);$CLJS.fE(Gpa,n0);q0=$CLJS.B(v0);r0=null;s0=0}t0=0}else break}
$CLJS.y_.m(null,n0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(Bpa,c);a:{var e=k0;k0=!0;try{var f=$CLJS.bs([" ",$CLJS.Xg(c)," "].join(""),$CLJS.cf.g($CLJS.Qs($CLJS.XZ,a,b),d));break a}finally{k0=e}f=void 0}return l0(f)});$CLJS.z_.m(null,n0,function(){return"expression"});
$CLJS.C_.m(null,$CLJS.qG,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Nd($CLJS.hE,function(){return function k(f){return new $CLJS.je(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.jc(l),t=$CLJS.D(m),u=$CLJS.me(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=$CLJS.D_.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.pe($CLJS.re(u),k($CLJS.kc(l))):$CLJS.pe($CLJS.re(u),null)}u=$CLJS.z(l);return $CLJS.be($CLJS.D_.j(a,
b,u),k($CLJS.Ic(l)))}return null}},null,null)}(d)}())});$CLJS.y_.m(null,$CLJS.TA,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.XZ.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.d0.g(f,c).toLowerCase();0<f?f=$CLJS.ia.j?$CLJS.ia.j("+ %d %s",f,c):$CLJS.ia.call(null,"+ %d %s",f,c):(f=$CLJS.oz(f),f=$CLJS.ia.j?$CLJS.ia.j("- %d %s",f,c):$CLJS.ia.call(null,"- %d %s",f,c));f=l0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.z_.m(null,$CLJS.TA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.A_.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.d0.g(e,c).toLowerCase();0<e?e=$CLJS.ia.j?$CLJS.ia.j("plus_%s_%s",e,c):$CLJS.ia.call(null,"plus_%s_%s",e,c):(e=$CLJS.oz(e),e=$CLJS.ia.j?$CLJS.ia.j("minus_%d_%s",e,c):$CLJS.ia.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.y_.m(null,$CLJS.cF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.XZ.v(a,b,e,d)});$CLJS.z_.m(null,$CLJS.cF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.A_.j(a,b,d)});
$CLJS.Hpa=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(zpa(d,e,f)))throw $CLJS.Th("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.VQ,f],null));return $CLJS.LV.l(d,e,Apa,$CLJS.H([$CLJS.tV($CLJS.lV.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Ipa=function(){function a(d,e){var f=$CLJS.Ce($CLJS.VO.h($CLJS.zV(d,e)));return null==f?null:$CLJS.ek.g($CLJS.Qs(m0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.x0=function(){function a(d,e){return $CLJS.Ce($CLJS.VO.h($CLJS.zV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.iV.m(null,$CLJS.gA,function(a){return a});
$CLJS.Jpa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.zl(function(m,t){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sV(t),m],null)},$CLJS.x0.g(d,e))),l=$CLJS.zV(d,e);d=$CLJS.J_.j(d,e,l);return $CLJS.Ce($CLJS.j0(function(m){return $CLJS.Ta(f)||$CLJS.bk.g($CLJS.DJ.h(m),$CLJS.wY)||$CLJS.J.g(k,$CLJS.U.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Kpa=function(){function a(d,e,f){return $CLJS.kV(m0(d,e,p0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.y0=$CLJS.cE.h($CLJS.ND);