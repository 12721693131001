var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var HH,JH,LH,UH,XH,bI;$CLJS.GH=new $CLJS.M(null,"get-month","get-month",-369374731);HH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.IH=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);JH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.KH=new $CLJS.M(null,"iso","iso",-1366207543);LH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.MH=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.NH=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.OH=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.PH=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.QH=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.RH=new $CLJS.M(null,"us","us",746429226);$CLJS.SH=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.TH=new $CLJS.M(null,"now","now",-1650525531);
UH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.VH=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.WH=new $CLJS.M(null,"get-day","get-day",127568857);XH=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.YH=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.ZH=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.$H=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.aI=new $CLJS.M(null,"get-hour","get-hour",622714059);bI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.cI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.dI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.eI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.fI=new $CLJS.M(null,"target","target",253001721);$CLJS.mE($CLJS.QA,$CLJS.H([$CLJS.Tr,$CLJS.SB,$CLJS.lr,$CLJS.UC]));$CLJS.BD.m(null,$CLJS.PH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.CD(a);return $CLJS.rd(a)?(a=$CLJS.Ws.g(a,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Ak,null,$CLJS.Dk,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.fE($CLJS.PH,$CLJS.SD);
for(var gI=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TA,$CLJS.WA],null)),hI=null,iI=0,jI=0;;)if(jI<iI){var kI=hI.X(null,jI);$CLJS.mE(kI,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null),$CLJS.lr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UC],null)]));$CLJS.fE(kI,$CLJS.PH);jI+=1}else{var lI=$CLJS.y(gI);if(lI){var mI=lI;if($CLJS.wd(mI)){var nI=$CLJS.jc(mI),yia=$CLJS.kc(mI),zia=nI,Aia=$CLJS.D(nI);gI=yia;hI=zia;iI=Aia}else{var oI=$CLJS.z(mI);$CLJS.mE(oI,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.W,$CLJS.KD],null),$CLJS.lr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UC],null)]));$CLJS.fE(oI,$CLJS.PH);gI=$CLJS.B(mI);hI=null;iI=0}jI=0}else break}
for(var pI=$CLJS.y(new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.NH,$CLJS.GH,$CLJS.WH,$CLJS.aI,$CLJS.YH,$CLJS.MH,$CLJS.cI],null)),qI=null,rI=0,sI=0;;)if(sI<rI){var Bia=qI.X(null,sI);$CLJS.mE(Bia,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null)]));sI+=1}else{var tI=$CLJS.y(pI);if(tI){var uI=tI;if($CLJS.wd(uI)){var vI=$CLJS.jc(uI),Cia=$CLJS.kc(uI),Dia=vI,Eia=$CLJS.D(vI);pI=Cia;qI=Dia;rI=Eia}else{var Fia=$CLJS.z(uI);$CLJS.mE(Fia,$CLJS.H([$CLJS.Tr,
$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null)]));pI=$CLJS.B(uI);qI=null;rI=0}sI=0}else break}$CLJS.mE($CLJS.PA,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.RF],null)]));
for(var wI=$CLJS.y(new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.cI,null,$CLJS.YH,null,$CLJS.aI,null,$CLJS.ZH,null,$CLJS.MH,null,$CLJS.GH,null,$CLJS.NH,null,$CLJS.WH,null],null),null)),xI=null,yI=0,zI=0;;)if(zI<yI){var Gia=xI.X(null,zI);$CLJS.mE(Gia,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null)]));zI+=1}else{var AI=$CLJS.y(wI);if(AI){var BI=AI;if($CLJS.wd(BI)){var CI=$CLJS.jc(BI),Hia=$CLJS.kc(BI),Iia=CI,Jia=$CLJS.D(CI);
wI=Hia;xI=Iia;yI=Jia}else{var Kia=$CLJS.z(BI);$CLJS.mE(Kia,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null)]));wI=$CLJS.B(BI);xI=null;yI=0}zI=0}else break}$CLJS.X(bI,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yq,$CLJS.KH,$CLJS.RH,$CLJS.VH],null));
$CLJS.oE($CLJS.SA,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bI],null)],null)],null)],null)]));
$CLJS.X($CLJS.$H,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,$CLJS.hD,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,2,[$CLJS.ns,"valid timezone ID",$CLJS.ys,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.ej);return["invalid timezone ID: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.sz($CLJS.Ed,$CLJS.FH.tz.names())),$CLJS.wH],null)],null));
$CLJS.oE($CLJS.IH,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fI,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.$H],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qy,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.$H],null)],null)],null)],
null)]));$CLJS.fE($CLJS.IH,$CLJS.PH);$CLJS.mE($CLJS.TH,$CLJS.H([$CLJS.Tr,$CLJS.Bk]));$CLJS.X(LH,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.qD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ns,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Ne(function(b){return $CLJS.tz(a,b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ak,$CLJS.Dk],null))}],null)],null));
$CLJS.X(HH,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ms,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.mD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jA,new $CLJS.h(null,1,[$CLJS.ur,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,LH],null)],null)],null)],null));
$CLJS.zF.g($CLJS.OH,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.hj,new $CLJS.h(null,1,[$CLJS.ns,"valid :absolute-datetime clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.OH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,HH],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Sq,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,new $CLJS.h(null,1,[$CLJS.ns,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.AH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.CH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.uH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.Oh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.SF],null)],null)],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hj,new $CLJS.h(null,1,[$CLJS.ns,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.Iu],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.EH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.Oh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.OF],null)],null)],null)],null)],null)],null));
$CLJS.BD.m(null,$CLJS.OH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Iu)?$CLJS.E.g(a,$CLJS.Oh)?$CLJS.Dk:$CLJS.n($CLJS.eE($CLJS.SF,a))?$CLJS.Ak:$CLJS.Dk:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.dh($CLJS.qH,b))?$CLJS.Ak:$CLJS.n($CLJS.dh($CLJS.rH,b))?$CLJS.Ak:null:null;if($CLJS.n(a))return a;b=$CLJS.CD(b);b=$CLJS.rd(b)?$CLJS.Wf.j($CLJS.Sg,$CLJS.ef(function(c){return $CLJS.tz(c,$CLJS.uj)}),b):b;return $CLJS.rd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.X(XH,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,$CLJS.Iu],null),$CLJS.lr],null));
$CLJS.oE($CLJS.RA,$CLJS.H([$CLJS.Tr,$CLJS.Dk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,XH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ci,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UC],null)],null)],null)],null)]));
$CLJS.mE($CLJS.xx,$CLJS.H([$CLJS.Tr,$CLJS.Jk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.WD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.TC],null)]));$CLJS.X(JH,new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.Yq,$CLJS.QF,$CLJS.ji,$CLJS.Ej,$CLJS.QH,$CLJS.dI,$CLJS.eI,$CLJS.ij,$CLJS.oi,$CLJS.zk,$CLJS.Qi,$CLJS.NF],null));$CLJS.X(UH,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yq,$CLJS.KH,$CLJS.RH,$CLJS.VH],null));
$CLJS.oE($CLJS.UA,$CLJS.H([$CLJS.Tr,$CLJS.Zi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ci,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,JH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,UH],null)],null)],
null)],null)]));