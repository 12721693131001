var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var z3,B3,D3,E3,F3,Hra;z3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=z3[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=z3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.A3=function(a,b){return z3($CLJS.QZ(a),b)};
B3=function(a,b,c){var d=$CLJS.zV(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.PE);d=$CLJS.jG($CLJS.ck.j($CLJS.Tg([c]),$CLJS.oD,$CLJS.ed),d);if(!$CLJS.n(d))throw $CLJS.Th($CLJS.YC("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.pr,c,$CLJS.vE,a,$CLJS.yK,b],null));return d};$CLJS.C3=function(a){return $CLJS.s1($CLJS.lF,new $CLJS.Q(null,1,5,$CLJS.R,[a],null))};D3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);
E3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);F3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Hra=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.B_.m(null,$CLJS.PE,function(a,b){var c=$CLJS.Ce($CLJS.PE.h($CLJS.zV(a,b)));return $CLJS.n(c)?$CLJS.v1($CLJS.zD("and"),function(){return function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.XZ.v(a,b,v,$CLJS.YZ);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),null)}t=$CLJS.z(k);return $CLJS.be($CLJS.XZ.v(a,
b,t,$CLJS.YZ),f($CLJS.Ic(k)))}return null}},null,null)}(c)}()):null});$CLJS.E_.m(null,$CLJS.PE,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.jA);e=$CLJS.J.g(e,$CLJS.ni);c=$CLJS.I(c,2,null);c=B3(a,b,c);return $CLJS.uk.l($CLJS.H([$CLJS.VZ.j(a,b,c),new $CLJS.h(null,2,[$CLJS.DJ,$CLJS.dK,$CLJS.o0,$CLJS.oD.h($CLJS.ed(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.jA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.ni,e],null):null]))});
$CLJS.y_.m(null,$CLJS.PE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.XZ.v(a,b,B3(a,b,c),d)});$CLJS.fE(E3,F3);
for(var G3=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jw,$CLJS.aF],null)),H3=null,I3=0,J3=0;;)if(J3<I3){var Ira=H3.X(null,J3);$CLJS.fE(Ira,E3);J3+=1}else{var K3=$CLJS.y(G3);if(K3){var L3=K3;if($CLJS.wd(L3)){var M3=$CLJS.jc(L3),Jra=$CLJS.kc(L3),Kra=M3,Lra=$CLJS.D(M3);G3=Jra;H3=Kra;I3=Lra}else{var Mra=$CLJS.z(L3);$CLJS.fE(Mra,E3);G3=$CLJS.B(L3);H3=null;I3=0}J3=0}else break}
$CLJS.y_.m(null,E3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.XZ.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.YC("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.YC("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.zD("Count");case "cum-count":return $CLJS.zD("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.z_.m(null,E3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.E_.m(null,E3,function(a,b,c){var d=$CLJS.T.j,e=$CLJS.cH($CLJS.E_,F3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.T,a,$CLJS.tj,$CLJS.JB)});$CLJS.y_.m(null,$CLJS.IE,function(){return $CLJS.zD("Case")});
$CLJS.z_.m(null,$CLJS.IE,function(){return"case"});$CLJS.fE(D3,F3);for(var N3=$CLJS.y(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.UE,$CLJS.vF,$CLJS.EE,$CLJS.Cj,$CLJS.mF,$CLJS.en,$CLJS.oF,$CLJS.lF,$CLJS.FE],null)),O3=null,P3=0,Q3=0;;)if(Q3<P3){var Nra=O3.X(null,Q3);$CLJS.fE(Nra,D3);Q3+=1}else{var R3=$CLJS.y(N3);if(R3){var S3=R3;if($CLJS.wd(S3)){var T3=$CLJS.jc(S3),Ora=$CLJS.kc(S3),Pra=T3,Qra=$CLJS.D(T3);N3=Ora;O3=Pra;P3=Qra}else{var Rra=$CLJS.z(S3);$CLJS.fE(Rra,D3);N3=$CLJS.B(S3);O3=null;P3=0}Q3=0}else break}
$CLJS.z_.m(null,D3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.y_.m(null,D3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.XZ.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.YC("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.YC("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.YC("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.YC("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.YC("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.YC("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.YC("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.YC("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.YC("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.y_.m(null,$CLJS.LE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.YC("{0}th percentile of {1}",$CLJS.H([c,$CLJS.XZ.v(a,b,e,d)]))});$CLJS.z_.m(null,$CLJS.LE,function(){return"percentile"});
$CLJS.fE($CLJS.LE,F3);$CLJS.y_.m(null,$CLJS.tF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.YC("Sum of {0} matching condition",$CLJS.H([$CLJS.XZ.v(a,b,e,d)]))});$CLJS.z_.m(null,$CLJS.tF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.A_.j(a,b,d))].join("")});$CLJS.fE($CLJS.tF,F3);$CLJS.y_.m(null,$CLJS.gF,function(){return $CLJS.zD("Share of rows matching condition")});
$CLJS.z_.m(null,$CLJS.gF,function(){return"share"});$CLJS.fE($CLJS.gF,F3);$CLJS.y_.m(null,$CLJS.iF,function(){return $CLJS.zD("Count of rows matching condition")});$CLJS.z_.m(null,$CLJS.iF,function(){return"count-where"});$CLJS.fE($CLJS.iF,F3);
$CLJS.E_.m(null,F3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.uk.l;d=$CLJS.n(d)?$CLJS.Al($CLJS.VZ.j(a,b,d),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.UJ],null)):null;var f=$CLJS.cH($CLJS.E_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.uk,$CLJS.H([d,a]))});
$CLJS.Sra=function(){function a(d){return $CLJS.s1($CLJS.Jw,new $CLJS.Q(null,1,5,$CLJS.R,[d],null))}function b(){return $CLJS.s1($CLJS.Jw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.iV.m(null,$CLJS.PE,function(a){return a});
$CLJS.U3=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.OA(f),$CLJS.HJ))f=$CLJS.kV(f);else return $CLJS.x1(d,e,$CLJS.PE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.V3=function(){function a(d,e){return $CLJS.Ce($CLJS.PE.h($CLJS.zV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.W3=function(){function a(d,e){var f=$CLJS.Ce($CLJS.PE.h($CLJS.zV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.VZ.j(d,e,k),m=$CLJS.T.l,t=$CLJS.jA.h(l);return m.call($CLJS.T,$CLJS.r1(l,$CLJS.ni,$CLJS.n(t)?t:$CLJS.cj),$CLJS.DJ,$CLJS.dK,$CLJS.H([$CLJS.o0,$CLJS.oD.h($CLJS.ed(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.y_.m(null,$CLJS.KF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.GF);return $CLJS.cD.h(a.o?a.o():a.call(null))});$CLJS.F_.m(null,$CLJS.KF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.TE);b=$CLJS.J.g(d,$CLJS.GF);c=$CLJS.J.g(d,$CLJS.FF);d=$CLJS.J.g(d,$CLJS.DY);a=$CLJS.T.l(b.o?b.o():b.call(null),$CLJS.w_,$CLJS.Qz(a),$CLJS.H([$CLJS.u_,c]));return null!=d?$CLJS.T.j(a,$CLJS.e_,d):a});
$CLJS.Tra=function(){function a(d,e){var f=function(){var m=$CLJS.Wy.h($CLJS.K0(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.zV(d,e),l=$CLJS.J_.j(d,e,k);return $CLJS.Ce($CLJS.Wf.j($CLJS.xf,$CLJS.ck.j($CLJS.ef(function(m){m=$CLJS.IF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.sl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.FF),u=$CLJS.J.g(m,$CLJS.AF);if($CLJS.Ta(t))return m;if($CLJS.E.g(u,$CLJS.Xh))return $CLJS.T.j(m,$CLJS.wV,l);t=$CLJS.Ce($CLJS.j0(function(v){return $CLJS.qZ(u,
v)},l));return $CLJS.n(t)?$CLJS.T.j(m,$CLJS.wV,t):null}),$CLJS.cf.h(function(m){return $CLJS.T.j(m,$CLJS.fj,$CLJS.KF)})),$CLJS.LF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Ura=function(){function a(d,e){return $CLJS.jV(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TE.h(d),$CLJS.N,$CLJS.lV.h(e)],null))}function b(d){if($CLJS.Ta($CLJS.FF.h(d)))return $CLJS.jV(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TE.h(d),$CLJS.N],null));var e=$CLJS.TE.h(d);e=$CLJS.ia.g?$CLJS.ia.g("aggregation operator %s requires an argument",e):$CLJS.ia.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Th(e,new $CLJS.h(null,1,[Hra,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();